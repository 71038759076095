import fetchServer from "../helpers/api/fetchServer";

export const createAccount = async ({
  advertiserId,
  name,
  platformId,
  username,
  postBoostContractStartDate,
  postBoostContractEndDate,
  useBrand,
}) => {
  const method = "POST";
  const urlAPI = "/api/accounts";
  const body = {
    advertiserId,
    name,
    platformId,
    username,
    postBoostContractStartDate,
    postBoostContractEndDate,
    useBrand,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getAccounts = async () => {
  const method = "GET";
  const urlAPI = "/api/accounts";
  return await fetchServer({ method, urlAPI });
};

export const getAccountsByAdvertiser = async (advertiserId) => {
  const method = "GET";
  const urlAPI = `/api/accounts?advertiser=${advertiserId}`;
  return await fetchServer({ method, urlAPI });
};

export const getAccountsByPlatform = async (platformId) => {
  const method = "GET";
  const urlAPI = `/api/accounts?platform=${platformId}`;
  return await fetchServer({ method, urlAPI });
};

export const getAccount = async (id) => {
  const method = "GET";
  const urlAPI = `/api/accounts/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const getAccountFollowersDailyGrowth = async (id) => {
  const method = "GET";
  const urlAPI = `/api/accounts/${id}/followersdailygrowth`;
  return await fetchServer({ method, urlAPI });
};

export const getAccountReporting = async ({ id, startDate, endDate }) => {
  const method = "GET";
  let urlAPI = `/api/accounts/${id}/reporting?`;

  if (startDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${startDate}`;
  }
  if (endDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${endDate}`;
  }

  return await fetchServer({ method, urlAPI });
};

export const getAccountPresentationCountPostsByFormat = async ({
  id,
  startDate,
  endDate,
}) => {
  const method = "GET";
  let urlAPI = `/api/accounts/${id}/presentation/count-posts-by-format?`;

  if (startDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${startDate}`;
  }
  if (endDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${endDate}`;
  }

  return await fetchServer({ method, urlAPI });
};

export const getAccountPresentationEngagementTotal = async ({
  id,
  startDate,
  endDate,
}) => {
  const method = "GET";
  let urlAPI = `/api/accounts/${id}/presentation/engagement-total?`;

  if (startDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${startDate}`;
  }
  if (endDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${endDate}`;
  }

  return await fetchServer({ method, urlAPI });
};

export const getAccountPresentationEngagementByFormat = async ({
  id,
  startDate,
  endDate,
}) => {
  const method = "GET";
  let urlAPI = `/api/accounts/${id}/presentation/engagement-by-format?`;

  if (startDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${startDate}`;
  }
  if (endDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${endDate}`;
  }

  return await fetchServer({ method, urlAPI });
};

export const getAccountPresentationEngagementTopPosts = async ({
  id,
  startDate,
  endDate,
}) => {
  const method = "GET";
  let urlAPI = `/api/accounts/${id}/presentation/engagement-top-posts-likes?`;

  if (startDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${startDate}`;
  }
  if (endDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${endDate}`;
  }

  return await fetchServer({ method, urlAPI });
};

export const getAccountPresentationVideoTotal = async ({
  id,
  startDate,
  endDate,
}) => {
  const method = "GET";
  let urlAPI = `/api/accounts/${id}/presentation/video-total?`;

  if (startDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${startDate}`;
  }
  if (endDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${endDate}`;
  }

  return await fetchServer({ method, urlAPI });
};

export const getAccountPresentationVideoTopPosts = async ({
  id,
  startDate,
  endDate,
}) => {
  const method = "GET";
  let urlAPI = `/api/accounts/${id}/presentation/video-top-posts?`;

  if (startDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${startDate}`;
  }
  if (endDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${endDate}`;
  }

  return await fetchServer({ method, urlAPI });
};

export const getAccountPresentationEngagementByBrand = async ({
  id,
  startDate,
  endDate,
}) => {
  const method = "GET";
  let urlAPI = `/api/accounts/${id}/presentation/brands/count-posts-by-brand?`;

  if (startDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${startDate}`;
  }
  if (endDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${endDate}`;
  }

  return await fetchServer({ method, urlAPI });
};

export const getAccountPresentationCountPostsByProblemType = async ({
  id,
  startDate,
  endDate,
}) => {
  const method = "GET";
  let urlAPI = `/api/accounts/${id}/presentation/problems/count-posts-by-problem-type?`;

  if (startDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${startDate}`;
  }
  if (endDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${endDate}`;
  }

  return await fetchServer({ method, urlAPI });
};


export const getAccountFollowersGrowth = async ({ id, startDate, endDate }) => {
  const method = "GET";
  let urlAPI = `/api/accounts/${id}/followersgrowth?`;

  if (startDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${startDate}`;
  }
  if (endDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${endDate}`;
  }

  return await fetchServer({ method, urlAPI });
};

export const getAccountPresentationSpentTotal = async ({
  id,
  startDate,
  endDate,
}) => {
  const method = "GET";
  let urlAPI = `/api/accounts/${id}/presentation/spent-total?`;

  if (startDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${startDate}`;
  }
  if (endDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${endDate}`;
  }

  return await fetchServer({ method, urlAPI });
};

export const updateAccount = async ({
  id,
  advertiserId,
  name,
  platformId,
  username,
  postBoostContractStartDate,
  postBoostContractEndDate,
  useBrand,
}) => {
  const method = "PUT";
  const urlAPI = `/api/accounts/${id}`;
  const body = {
    advertiserId,
    name,
    platformId,
    username,
    postBoostContractStartDate,
    postBoostContractEndDate,
    useBrand,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateAccountBasicInfos = async ({
  id,
  advertiserId,
  name,
  platformId,
  username,
}) => {
  const method = "PUT";
  const urlAPI = `/api/accounts/${id}/basic-infos`;
  const body = {
    advertiserId,
    name,
    platformId,
    username,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateAccountPostBoostContract = async ({
  id,
  postBoostContractStartDate,
  postBoostContractEndDate,
}) => {
  const method = "PUT";
  const urlAPI = `/api/accounts/${id}/post-boost-contract`;
  const body = {
    postBoostContractStartDate,
    postBoostContractEndDate,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateAccountUseBrand = async ({ id, useBrand }) => {
  const method = "PUT";
  const urlAPI = `/api/accounts/${id}/use-brand`;
  const body = {
    useBrand,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const deleteAccount = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/accounts/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreAccount = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/accounts/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteAccounts = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/accounts/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreAccounts = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/accounts/restore-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};
