import * as api from "../apis/accountsApi";
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getAccountsQueryOptions = () =>
  queryOptions({
    queryKey: ["accounts"],
    queryFn: api.getAccounts,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getAccountsByAdvertiserQueryOptions = (
  advertiserId,
  queryClient
) =>
  queryOptions({
    queryKey: ["advertiser.accounts", advertiserId],
    queryFn: () => api.getAccountsByAdvertiser(advertiserId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["accounts"])
        ?.filter((account) => account.advertiser.id === advertiserId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["accounts"])?.dataUpdatedAt,
  });

export const getAccountsByPlatformQueryOptions = (platformId, queryClient) =>
  queryOptions({
    queryKey: ["platform.accounts", platformId],
    queryFn: () => api.getAccountsByPlatform(platformId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["accounts"])
        ?.filter((account) => account.platform.id === platformId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["accounts"])?.dataUpdatedAt,
  });

export const getAccountQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["account", id],
    queryFn: () => api.getAccount(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // if "accounts" query exist, we use it to init data
      if (queryClient.getQueryState(["accounts"])) {
        queryKey = ["accounts"];
        return queryClient
          .getQueryData(["accounts"])
          ?.find((account) => account.id === id);
      }

      // otherwise, we try to find the account in one of the "advertiser.accounts" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["advertiser.accounts"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((account) => {
            if (account.id === id) {
              found = account;
              queryKey = query[0];
            }
          });
        });

      // otherwise, we try to find the account in one of the "platform.accounts" queries
      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["platform.accounts"] })
          .forEach((query) => {
            const data = query[1];
            data.forEach((account) => {
              if (account.id === id) {
                found = account;
                queryKey = query[0];
              }
            });
          });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

const getAccountFollowersDailyGrowthQueryOptions = (accountId) =>
  queryOptions({
    queryKey: ["account.followersDailyGrowth", accountId],
    queryFn: () => api.getAccountFollowersDailyGrowth(accountId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getAccountFollowersGrowthQueryOptions = ({ id, startDate, endDate }) =>
  queryOptions({
    queryKey: ["account.followersDailyGrowth", id, startDate, endDate],
    queryFn: () => api.getAccountFollowersGrowth({ id, startDate, endDate }),
    refetchOnWindowFocus: "always",
    placeholderData: keepPreviousData,
    staleTime: STALE_TIME,
  });

const getAccountReportingQueryOptions = ({ id, startDate, endDate }) =>
  queryOptions({
    queryKey: ["account.reporting", id, startDate, endDate],
    queryFn: () => api.getAccountReporting({ id, startDate, endDate }),
    refetchOnWindowFocus: "always",
    placeholderData: keepPreviousData,
    staleTime: STALE_TIME,
  });

const getAccountPresentationCountPostsByFormatQueryOptions = ({
  id,
  startDate,
  endDate,
}) =>
  queryOptions({
    queryKey: [
      "account.presentation.engagement.countPostsByFormat",
      id,
      startDate,
      endDate,
    ],
    queryFn: () =>
      api.getAccountPresentationCountPostsByFormat({ id, startDate, endDate }),
    refetchOnWindowFocus: "always",
    placeholderData: keepPreviousData,
    staleTime: STALE_TIME,
  });

const getAccountPresentationEngagementTotalQueryOptions = ({
  id,
  startDate,
  endDate,
}) =>
  queryOptions({
    queryKey: ["account.presentation.engagement.total", id, startDate, endDate],
    queryFn: () =>
      api.getAccountPresentationEngagementTotal({ id, startDate, endDate }),
    refetchOnWindowFocus: "always",
    placeholderData: keepPreviousData,
    staleTime: STALE_TIME,
  });

const getAccountPresentationSpentTotalQueryOptions = ({
  id,
  startDate,
  endDate,
}) =>
  queryOptions({
    queryKey: ["account.presentation.spent.total", id, startDate, endDate],
    queryFn: () =>
      api.getAccountPresentationSpentTotal({ id, startDate, endDate }),
    refetchOnWindowFocus: "always",
    placeholderData: keepPreviousData,
    staleTime: STALE_TIME,
  });

const getAccountPresentationEngagementByFormatQueryOptions = ({
  id,
  startDate,
  endDate,
}) =>
  queryOptions({
    queryKey: [
      "account.presentation.engagement.byFormat",
      id,
      startDate,
      endDate,
    ],
    queryFn: () =>
      api.getAccountPresentationEngagementByFormat({ id, startDate, endDate }),
    refetchOnWindowFocus: "always",
    placeholderData: keepPreviousData,
    staleTime: STALE_TIME,
  });

const getAccountPresentationEngagementTopPostsQueryOptions = ({
  id,
  startDate,
  endDate,
}) =>
  queryOptions({
    queryKey: [
      "account.presentation.engagement.TopPosts",
      id,
      startDate,
      endDate,
    ],
    queryFn: () =>
      api.getAccountPresentationEngagementTopPosts({ id, startDate, endDate }),
    refetchOnWindowFocus: "always",
    placeholderData: keepPreviousData,
    staleTime: STALE_TIME,
  });

const getAccountPresentationVideoTotalQueryOptions = ({
  id,
  startDate,
  endDate,
}) =>
  queryOptions({
    queryKey: ["account.presentation.video.total", id, startDate, endDate],
    queryFn: () =>
      api.getAccountPresentationVideoTotal({ id, startDate, endDate }),
    refetchOnWindowFocus: "always",
    placeholderData: keepPreviousData,
    staleTime: STALE_TIME,
  });

const getAccountPresentationVideoTopPostsQueryOptions = ({
  id,
  startDate,
  endDate,
}) =>
  queryOptions({
    queryKey: ["account.presentation.video.TopPosts", id, startDate, endDate],
    queryFn: () =>
      api.getAccountPresentationVideoTopPosts({ id, startDate, endDate }),
    refetchOnWindowFocus: "always",
    placeholderData: keepPreviousData,
    staleTime: STALE_TIME,
  });

const getAccountPresentationCountPostsByBrandQueryOptions = ({
  id,
  startDate,
  endDate,
}) =>
  queryOptions({
    queryKey: [
      "account.presentation.brand.countPostsbyBrand",
      id,
      startDate,
      endDate,
    ],
    queryFn: () =>
      api.getAccountPresentationEngagementByBrand({ id, startDate, endDate }),
    refetchOnWindowFocus: "always",
    placeholderData: keepPreviousData,
    staleTime: STALE_TIME,
  });

const getAccountPresentationCountPostsByProblemTypeQueryOptions = ({
  id,
  startDate,
  endDate,
}) =>
  queryOptions({
    queryKey: [
      "account.presentation.problem.countPostsbyProblemType",
      id,
      startDate,
      endDate,
    ],
    queryFn: () =>
      api.getAccountPresentationCountPostsByProblemType({
        id,
        startDate,
        endDate,
      }),
    refetchOnWindowFocus: "always",
    placeholderData: keepPreviousData,
    staleTime: STALE_TIME,
  });

// useQuery

export const useGetAccounts = () => useQuery(getAccountsQueryOptions());

export const useGetAccountsByAdvertiser = (advertiserId) => {
  const queryClient = useQueryClient();
  return useQuery(
    getAccountsByAdvertiserQueryOptions(advertiserId, queryClient)
  );
};

export const useGetAccountsByPlatform = (platformId) => {
  const queryClient = useQueryClient();
  return useQuery(getAccountsByPlatformQueryOptions(platformId, queryClient));
};

export const useGetAccount = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getAccountQueryOptions(id, queryClient));
};

export const useGetAccountFollowersDailyGrowth = (accountId) =>
  useQuery(getAccountFollowersDailyGrowthQueryOptions(accountId));

export const useGetAccountFollowersGrowth = ({ id, startDate, endDate }) =>
  useQuery(getAccountFollowersGrowthQueryOptions({ id, startDate, endDate }));

export const useGetAccountReporting = ({ id, startDate, endDate }) =>
  useQuery(getAccountReportingQueryOptions({ id, startDate, endDate }));

export const useGetAccountPresentationCountPostsByFormat = ({
  id,
  startDate,
  endDate,
}) =>
  useQuery(
    getAccountPresentationCountPostsByFormatQueryOptions({
      id,
      startDate,
      endDate,
    })
  );

export const useGetAccountPresentationEngagementTotal = ({
  id,
  startDate,
  endDate,
}) =>
  useQuery(
    getAccountPresentationEngagementTotalQueryOptions({
      id,
      startDate,
      endDate,
    })
  );

export const useGetAccountPresentationSpentTotal = ({
  id,
  startDate,
  endDate,
}) =>
  useQuery(
    getAccountPresentationSpentTotalQueryOptions({
      id,
      startDate,
      endDate,
    })
  );

export const useGetAccountPresentationEngagementByFormat = ({
  id,
  startDate,
  endDate,
}) =>
  useQuery(
    getAccountPresentationEngagementByFormatQueryOptions({
      id,
      startDate,
      endDate,
    })
  );

export const useGetAccountPresentationEngagementTopPosts = ({
  id,
  startDate,
  endDate,
}) =>
  useQuery(
    getAccountPresentationEngagementTopPostsQueryOptions({
      id,
      startDate,
      endDate,
    })
  );

export const useGetAccountPresentationVideoTotal = ({
  id,
  startDate,
  endDate,
}) =>
  useQuery(
    getAccountPresentationVideoTotalQueryOptions({
      id,
      startDate,
      endDate,
    })
  );

export const useGetAccountPresentationVideoTopPosts = ({
  id,
  startDate,
  endDate,
}) =>
  useQuery(
    getAccountPresentationVideoTopPostsQueryOptions({
      id,
      startDate,
      endDate,
    })
  );

export const useGetAccountPresentationCountPostsByBrand = ({
  id,
  startDate,
  endDate,
}) =>
  useQuery(
    getAccountPresentationCountPostsByBrandQueryOptions({
      id,
      startDate,
      endDate,
    })
  );

export const useGetAccountPresentationCountPostsByProblemType = ({
  id,
  startDate,
  endDate,
}) =>
  useQuery(
    getAccountPresentationCountPostsByProblemTypeQueryOptions({
      id,
      startDate,
      endDate,
    })
  );

// useSuspenseQuery

export const useSuspenseGetAccounts = () =>
  useSuspenseQuery(getAccountsQueryOptions());

export const useSuspenseGetAccountsByAdvertiser = (advertiserId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getAccountsByAdvertiserQueryOptions(advertiserId, queryClient)
  );
};

export const useSuspenseGetAccountsByPlatform = (platformId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getAccountsByPlatformQueryOptions(platformId, queryClient)
  );
};

export const useSuspenseGetAccount = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getAccountQueryOptions(id, queryClient));
};

export const useSuspenseGetAccountDailyGrowth = (accountId) =>
  useSuspenseQuery(getAccountFollowersDailyGrowthQueryOptions(accountId));
