import { useLocation } from "@tanstack/react-router";
import { Box } from "@material-ui/core";
import Loader from "@material-ui/core/CircularProgress";
import PageWrapper from "../../../components/layouts/app/PageWrapper";

const EntityCreateLoading = ({ entity }) => {

  // Hooks
  const location = useLocation();

  // Header content
  const hasCancelButtonUrl = location.pathname.substring(
    0,
    location.pathname.search(entity.type.plural) +
    entity.type.plural.length
  );

  // Rendering
  return (
    <PageWrapper
      title={`Create new ${entity.label.singular}`}
      hasCancelButton={hasCancelButtonUrl}
      browserPageTitle={`Create new ${entity.label.singular}`}
    >
      <Box sx={{ mt: 3 }}>
        <Loader color="primary" />
      </Box>
    </PageWrapper>
  );
};

export default EntityCreateLoading;
