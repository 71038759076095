import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "name-asc",
};

// INITIAL STATE
const initialState = {
  metrics: initialFilters,
  platforms: [],
};

// const mockState = {
//   metrics: initialFilters,
//   advertisers: [
//     {
//       id: "123",
//       filters: initialFilters,
//     },
//     {
//       id: "456",
//       filters: initialFilters,
//     },
//   ]
// };

const metricFiltersSlice = createSlice({
  name: "metricFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "metrics":
          state.metrics.tab = action.payload.tab;
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                tab: action.payload.tab,
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "metrics":
          state.metrics.text = action.payload.text;
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                text: action.payload.text,
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByNameAsc: (state, action) => {
      switch (action.payload.module) {
        case "metrics":
          state.metrics.sortBy = "name-asc";
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "name-asc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByNameDesc: (state, action) => {
      switch (action.payload.module) {
        case "metrics":
          state.metrics.sortBy = "name-desc";
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "name-desc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByPlatformAsc: (state, action) => {
      switch (action.payload.module) {
        case "metrics":
          state.metrics.sortBy = "platform-asc";
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "platform-asc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByPlatformDesc: (state, action) => {
      switch (action.payload.module) {
        case "metrics":
          state.metrics.sortBy = "platform-desc";
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "platform-desc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "metrics":
          state.metrics = initialFilters;
          break;
        case "platforms":
          if (
            !state.platforms.find(
              (platform) => platform.id === action.payload.id
            )
          ) {
            state.platforms.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  sortByNameAsc,
  sortByNameDesc,
  sortByPlatformAsc,
  sortByPlatformDesc,
  initFilterSet,
} = metricFiltersSlice.actions;
export const metricFiltersReducer = metricFiltersSlice.reducer;
