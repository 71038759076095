import { createSlice } from "@reduxjs/toolkit";

// Initial State

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  currentUser: null,
};


// Slice

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsInitialized: (state) => {
      state.isInitialized = true;
    },
    setLoggedIn: (state, action) => {
      state.isAuthenticated = true;
      state.currentUser = action.payload;
    },
    setLoggedOut: (state) => {
      state.isAuthenticated = false;
      state.currentUser = undefined;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  }
});

// helper functions

let refreshTokenTimeout;

export const startRefreshTokenTimer = (expiresIn, callback) => {
  const timeout = expiresIn - 60 * 1000;
  refreshTokenTimeout = setTimeout(() => {
    callback();
  }, timeout);
};

export const stopRefreshTokenTimer = () => {
  clearTimeout(refreshTokenTimeout);
};

export const { clearState, setIsInitialized, setCurrentUser, setLoggedIn, setLoggedOut  } = authSlice.actions;
export const authReducer = authSlice.reducer;
