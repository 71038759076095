import * as api from "../apis/advertisersApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getAdvertisersQueryOptions = () =>
  queryOptions({
    queryKey: ["advertisers"],
    queryFn: api.getAdvertisers,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getAdvertiserQueryOptions = (id, queryClient) =>
  queryOptions({
    queryKey: ["advertiser", id],
    queryFn: () => api.getAdvertiser(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["advertisers"])
        ?.find((advertiser) => advertiser.id === id),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["advertisers"])?.dataUpdatedAt,
  });

// useQuery

export const useGetAdvertisers = () => useQuery(getAdvertisersQueryOptions());

export const useGetAdvertiser = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getAdvertiserQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetAdvertisers = () =>
  useSuspenseQuery(getAdvertisersQueryOptions());

export const useSuspenseGetAdvertiser = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getAdvertiserQueryOptions(id, queryClient));
};
