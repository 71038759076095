import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetPost } from "../queries/posts";
import { ENTITY } from "../constants";

export const PostBreadcrumb = ({ match }) => {
    // Entity
    const { POST } = ENTITY;

    const {
      data: post,
      isLoading,
      isError,
      error,
    } = useGetPost(match.params.postId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (post) {
      return <span>{trimString(post[POST.nameField], 20)}</span>;
    }
  
    return null;
  };