import { createSlice } from "@reduxjs/toolkit";

// INITIAL STATE
const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "startDate-desc",
  // This boolean allows the component to know that initFilterSet has run
  // This is useful for paginated entities
  isInitFiltersFinished: true
};

// INITIAL STATE
const initialState = {
  ETLRuns: initialFilters,
};

const ETLRunFiltersSlice = createSlice({
  name: "ETLRunFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      state.ETLRuns.tab = action.payload.tab;
    },
    setTextFilter: (state, action) => {
      state.ETLRuns.text = action.payload.text;
    },
    sortByNameAsc: (state, action) => {
      state.ETLRuns.sortBy = "name-asc";
    },
    sortByNameDesc: (state, action) => {
      state.ETLRuns.sortBy = "name-desc";
    },
    sortByStartDateAsc: (state, action) => {
      state.ETLRuns.sortBy = "startDate-asc";
    },
    sortByStartDateDesc: (state, action) => {
      state.ETLRuns.sortBy = "startDate-desc";
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  sortByNameAsc,
  sortByNameDesc,
  sortByStartDateAsc,
  sortByStartDateDesc,
} = ETLRunFiltersSlice.actions;
export const ETLRunFiltersReducer = ETLRunFiltersSlice.reducer;
