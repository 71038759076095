import { getSpecTemplateQueryOptions } from "../../queries/specTemplates";

// specTemplate is pre-fetched when a user hover a 'view specTemplate' link
export const specTemplateViewLoader = ({
  params,
  context: { queryClient },
}) => {
  const { specTemplateId } = params;
  queryClient.ensureQueryData(
    getSpecTemplateQueryOptions(specTemplateId, queryClient)
  );
};
