import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import PageWrapper from "../../components/layouts/app/PageWrapper";
import Spinner from "../../components/general/Spinner";

const campaignAnalyticsRoute = createRoute({
  getParentRoute: () => appRoute,
  path: "campaign-analytics",
  beforeLoad: () =>
    checkPermissionsAndRedirect([
      { feature: "campaignAnalytics", action: "READ" },
    ]),
  staticData: {
    breadcrumb: "Analytics",
  },
  pendingComponent: () => (
    <PageWrapper title={"Analytics"} browserPageTitle={"Analytics"}>
      <Spinner />
    </PageWrapper>
  ),
}).lazy(() =>
  import("./campaignAnalytics.lazy").then((d) => d.CampaignAnalyticsRoute)
);

export default campaignAnalyticsRoute;
