import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetSpecTemplate } from "../queries/specTemplates";
import { ENTITY } from "../constants";

export const SpecTemplateBreadcrumb = ({ match }) => {
    // Entity
    const { SPECTEMPLATE } = ENTITY;

    const {
      data: specTemplate,
      isLoading,
      isError,
      error,
    } = useGetSpecTemplate(match.params.specTemplateId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (specTemplate) {
      return <span>{trimString(specTemplate[SPECTEMPLATE.nameField], 20)}</span>;
    }
  
    return null;
  };