import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { userViewLoader, userPermissionsLoader } from "../loaders/user";
import { UserBreadcrumb } from "../../breadcrumbs/user";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { USER } = ENTITY;

export const userRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const userRoute = createRoute({
    getParentRoute: () => parent,
    path: USER.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: USER.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: USER.label.upFirstPlural,
    },
  });

  // List users

  const userListRoute = createRoute({
    getParentRoute: () => userRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={USER} />,
  }).lazy(() => import("./user.lazy").then((d) => d.UserListRoute));

  // Create user

  const userCreateRoute = createRoute({
    getParentRoute: () => userRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: USER.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    pendingComponent: () => <EntityCreateLoading entity={USER} />,
  }).lazy(() => import("./user.lazy").then((d) => d.UserCreateRoute));

  // View user

  const userViewRootRoute = createRoute({
    getParentRoute: () => userRoute,
    path: "$userId",
    staticData: {
      breadcrumb: UserBreadcrumb,
    },
    loader: userViewLoader,
  });

  // Edit user

  const userEditRoute = createRoute({
    getParentRoute: () => userViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    pendingComponent: () => <EntityEditLoading entity={USER} />,
  }).lazy(() => import("./user.lazy").then((d) => d.UserEditRoute));

  // View user Layout

  const userViewLayoutRoute = createRoute({
    getParentRoute: () => userViewRootRoute,
    id: "user-view-layout",
    pendingComponent: () => <EntityViewLoading entity={USER} />,
  }).lazy(() => import("./user.lazy").then((d) => d.UserViewLayoutRoute));

  // View user - Profile Tab

  const UserViewLayoutProfileTabRoute = createRoute({
    getParentRoute: () => userViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./user.lazy").then((d) => d.UserViewLayoutProfileTabRoute)
  );

  // View user - Permissions Tab

  const userViewLayoutPermissionsTabRoute = createRoute({
    getParentRoute: () => userViewLayoutRoute,
    path: "permissions",
    staticData: {
      breadcrumb: "Permissions",
    },
    loader: userPermissionsLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./user.lazy").then((d) => d.UserViewLayoutPermissionsTabRoute)
  );

  // View user - About Tab

  const userViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => userViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./user.lazy").then((d) => d.UserViewLayoutAboutTabRoute)
  );

  // Route tree
  userRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [userListRoute]),
    userCreateRoute,
    userViewRootRoute.addChildren([
      userEditRoute,
      userViewLayoutRoute.addChildren([
        UserViewLayoutProfileTabRoute,
        userViewLayoutPermissionsTabRoute,
        userViewLayoutAboutTabRoute,
      ]),
    ]),
  ]);

  return userRoute;
};

const userRoute = userRouteFactory();

export default userRoute;
