import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import Spinner from "../../components/general/Spinner";

// Routes

const meRoute = createRoute({
  getParentRoute: () => appRoute,
  path: "me",
  staticData: {
    breadcrumb: "My settings",
  },
  pendingComponent: Spinner,
}).lazy(() => import("./me.lazy").then((d) => d.SettingsRoute));

// Profile Tab

const settingsProfileTabRoute = createRoute({
  getParentRoute: () => meRoute,
  path: "/",
  pendingComponent: Spinner,
}).lazy(() => import("./me.lazy").then((d) => d.SettingsProfileTabRoute));

// Security Tab

const settingsSecurityTabRoute = createRoute({
  getParentRoute: () => meRoute,
  path: "security",
  staticData: {
    breadcrumb: "Security",
  },
  pendingComponent: Spinner,
}).lazy(() => import("./me.lazy").then((d) => d.SettingsSecurityTabRoute));

// Notifications Tab

const settingsNotificationsTabRoute = createRoute({
  getParentRoute: () => meRoute,
  path: "notifications",
  staticData: {
    breadcrumb: "Notifications",
  },
  pendingComponent: Spinner,
}).lazy(() => import("./me.lazy").then((d) => d.SettingsNotificationsTabRoute));

// Theme Tab

const settingsThemeTabRoute = createRoute({
  getParentRoute: () => meRoute,
  path: "theme",
  staticData: {
    breadcrumb: "Theme",
  },
  pendingComponent: Spinner,
}).lazy(() => import("./me.lazy").then((d) => d.SettingsThemeTabRoute));

// Route tree
meRoute.addChildren([
  settingsProfileTabRoute,
  settingsSecurityTabRoute,
  settingsNotificationsTabRoute,
  settingsThemeTabRoute,
]);

export default meRoute;
