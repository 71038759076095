import handleServerResponse from "./handleServerResponse"
import generateImageHeader from "./generateImageHeader";

const fetchServerUpdateImage = async ({urlAPI, formData}) => {

  const requestOptions = {
    method: "PUT",
    headers: generateImageHeader(),
    body: formData,
    mode: "cors",
  };

  const response = await fetch(urlAPI, requestOptions);

  return handleServerResponse(response);

};

export default fetchServerUpdateImage;
