import fetchServer from "../helpers/api/fetchServer";

export const createLineItem = async ({
  campaignId,
  offerId,
  name,
  clientBudget,
  realBudget,
  startDate,
  endDate,
  shouldSendEmailAtStart,
  hasPrimaryGoal,
  primaryGoalMetricId,
  primaryGoalRealPrice,
  hasSecondaryGoal,
  secondaryGoalMetricId,
  secondaryGoalRealPrice,
}) => {
  const method = "POST";
  const urlAPI = "/api/lineItems";
  const body = {
    campaignId,
    offerId,
    name,
    clientBudget,
    realBudget,
    startDate,
    endDate,
    shouldSendEmailAtStart,
    hasPrimaryGoal,
    primaryGoalMetricId,
    primaryGoalRealPrice,
    hasSecondaryGoal,
    secondaryGoalMetricId,
    secondaryGoalRealPrice,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getPaginatedLineItems = async ({
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = "/api/lineItems?";
  // Filters
  if (filterText) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `text=${filterText}`;
  }
  if (filterStartDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${filterStartDate}`;
  }
  if (filterEndDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${filterEndDate}`;
  }
  if (filterDeleted) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `deleted=${filterDeleted}`;
  }
  // Sort by
  if (urlAPI.slice(-1) !== "?") {
    urlAPI += "&";
  }

  urlAPI += `sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getPaginatedLineItemsByCampaign = async ({
  campaignId,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = `/api/lineItems?campaign=${campaignId}`;
  // Filters
  if (filterText) {
    urlAPI += `&text=${filterText}`;
  }
  if (filterStartDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${filterStartDate}`;
  }
  if (filterEndDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${filterEndDate}`;
  }
  if (filterDeleted) {
    urlAPI += `&deleted=${filterDeleted}`;
  }
  // Sort by
  urlAPI += `&sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getPaginatedLineItemsByOffer = async ({
  offerId,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = `/api/lineItems?offer=${offerId}`;
  // Filters
  if (filterText) {
    urlAPI += `&text=${filterText}`;
  }
  if (filterStartDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${filterStartDate}`;
  }
  if (filterEndDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${filterEndDate}`;
  }
  if (filterDeleted) {
    urlAPI += `&deleted=${filterDeleted}`;
  }
  // Sort by
  urlAPI += `&sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getLineItem = async (id) => {
  const method = "GET";
  const urlAPI = `/api/lineItems/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const getLineItemStatsByDay = async (id) => {
  const method = "GET";
  const urlAPI = `/api/lineItems/${id}/statsByDay`;
  return await fetchServer({ method, urlAPI });
};

export const getLineItemStatsByAdSet = async (id) => {
  const method = "GET";
  const urlAPI = `/api/lineItems/${id}/statsByAdSet`;
  return await fetchServer({ method, urlAPI });
};

export const getLineItemStatsByAd = async (id) => {
  const method = "GET";
  const urlAPI = `/api/lineItems/${id}/statsByAd`;
  return await fetchServer({ method, urlAPI });
};

export const getLineItemStatsByDevice = async (id) => {
  const method = "GET";
  const urlAPI = `/api/lineItems/${id}/statsByDevice`;
  return await fetchServer({ method, urlAPI });
};

export const getLineItemStatsByDevicePlatform = async (id) => {
  const method = "GET";
  const urlAPI = `/api/lineItems/${id}/statsByDevicePlatform`;
  return await fetchServer({ method, urlAPI });
};

export const getLineItemStatsByPlatform = async (id) => {
  const method = "GET";
  const urlAPI = `/api/lineItems/${id}/statsByPlatform`;
  return await fetchServer({ method, urlAPI });
};

export const getLineItemStatsByPlacement = async (id) => {
  const method = "GET";
  const urlAPI = `/api/lineItems/${id}/statsByPlacement`;
  return await fetchServer({ method, urlAPI });
};

export const getLineItemStatsByAge = async (id) => {
  const method = "GET";
  const urlAPI = `/api/lineItems/${id}/statsByAge`;
  return await fetchServer({ method, urlAPI });
};

export const getLineItemStatsByGender = async (id) => {
  const method = "GET";
  const urlAPI = `/api/lineItems/${id}/statsByGender`;
  return await fetchServer({ method, urlAPI });
};

export const getLineItemDataCheck = async (id) => {
  const method = "GET";
  const urlAPI = `/api/lineItems/${id}/dataCheck`;
  return await fetchServer({ method, urlAPI });
};

export const updateLineItem = async ({
  id,
  campaignId,
  offerId,
  name,
  clientBudget,
  realBudget,
  startDate,
  endDate,
  shouldSendEmailAtStart,
  hasPrimaryGoal,
  primaryGoalMetricId,
  primaryGoalRealPrice,
  hasSecondaryGoal,
  secondaryGoalMetricId,
  secondaryGoalRealPrice,
}) => {
  const method = "PUT";
  const urlAPI = `/api/lineItems/${id}`;
  const body = {
    campaignId,
    offerId,
    name,
    clientBudget,
    realBudget,
    startDate,
    endDate,
    shouldSendEmailAtStart,
    hasPrimaryGoal,
    primaryGoalMetricId,
    primaryGoalRealPrice,
    hasSecondaryGoal,
    secondaryGoalMetricId,
    secondaryGoalRealPrice,
  };

  return await fetchServer({ method, urlAPI, body });
};

export const updateLineItemAssets = async ({
  id,
  assetsType,
  assetsUrl,
  videoDuration,
  scheduledPostDate,
  postUrl,
  creator,
  creatorAdCode,
  wordings,
  clickUrl,
  assetsComment,
  areAssetsValid,
}) => {
  const method = "PUT";
  const urlAPI = `/api/lineItems/${id}/assets`;
  const body = {
    assetsType,
    assetsUrl,
    videoDuration,
    scheduledPostDate,
    postUrl,
    creator,
    creatorAdCode,
    wordings,
    clickUrl,
    assetsComment,
    areAssetsValid,
  };

  return await fetchServer({ method, urlAPI, body });
};

export const updateLineItemWorkflow = async ({
  id,
  shouldSendEmailAtStart,
  isStartLineItemEmailSent,
}) => {
  const method = "PUT";
  const urlAPI = `/api/lineItems/${id}/workflow`;
  const body = {
    shouldSendEmailAtStart,
    isStartLineItemEmailSent,
  };

  return await fetchServer({ method, urlAPI, body });
};

export const updateLineItemValidStats = async ({
  id,
  hasValidStats,
  validStatsComment
}) => {
  const method = "PUT";
  const urlAPI = `/api/lineItems/${id}/valid-stats`;
  const body = {
    hasValidStats,
    validStatsComment
  };

  return await fetchServer({ method, urlAPI, body });
};

export const deleteLineItem = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/lineItems/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreLineItem = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/lineItems/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteLineItems = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/lineItems/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreLineItems = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/lineItems/restore-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};
