import { useLocation, Link as RouterLink } from "@tanstack/react-router";
import { Button } from "@material-ui/core";
import ArrowLeftIcon from "../../icons/ArrowLeft";

const BackButton = ({ label = "Back" }) => {
  const { pathname } = useLocation();
  const pathnameSegments = pathname.split("/").filter(Boolean);
  const backButtonPath = "/" + pathnameSegments.slice(0, -1).join("/");
  return (
    <Button
      color="primary"
      component={RouterLink}
      startIcon={<ArrowLeftIcon fontSize="small" />}
      sx={{ m: 1 }}
      to={backButtonPath}
      variant="outlined"
    >
      {label}
    </Button>
  );
};

export default BackButton;
