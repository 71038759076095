import { getUserQueryOptions, getUserPermissionsQueryOptions } from "../../queries/users";

// user is pre-fetched when a user hover a 'view user' link
export const userViewLoader = ({ params, context: { queryClient } }) => {
  const { userId } = params;
  queryClient.ensureQueryData(getUserQueryOptions(userId, queryClient));
};

// user permissions are pre-fetched when a user hover a 'permissions' link
export const userPermissionsLoader = ({ params, context: { queryClient } }) => {
  const { userId } = params;
  queryClient.ensureQueryData(getUserPermissionsQueryOptions(userId));
};
