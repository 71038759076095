import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetOffer } from "../queries/offers";
import { ENTITY } from "../constants";

export const OfferBreadcrumb = ({ match }) => {
  // Entity
  const { OFFER } = ENTITY;

  const {
    data: offer,
    isLoading,
    isError,
    error,
  } = useGetOffer(match.params.offerId);

  if (isLoading) {
    //return <span>Loading...</span>;
    return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
  }

  if (isError && error instanceof NotFoundException) {
    return <span>Not found</span>;
  }

  if (offer) {
    return <span>{trimString(offer[OFFER.nameField], 20)}</span>;
  }

  return null;
};
