import { Toaster } from "react-hot-toast";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import "./translations/i18n";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import { createCustomTheme } from "./theme";
import { Outlet } from "@tanstack/react-router";

const App = () => {
  // Hooks

  const { settings } = useSettings();
  useScrollReset();

  // Handle Theme

  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    roundedCorners: settings.roundedCorners,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  // Rendering

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster position="top-center" />
        <Outlet/>
      </ThemeProvider>
    </>
  );
};

export default App;
