import * as api from "../apis/problemTypesApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getProblemTypesQueryOptions = () =>
  queryOptions({
    queryKey: ["problemTypes"],
    queryFn: api.getProblemTypes,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getProblemTypesByPlatformQueryOptions = (platformId, queryClient) =>
  queryOptions({
    queryKey: ["platform.problemTypes", platformId],
    queryFn: () => api.getProblemTypesByPlatform(platformId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["problemTypes"])
        ?.filter((problemType) => problemType.platform.id === platformId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["problemTypes"])?.dataUpdatedAt,
  });

export const getProblemTypeQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["problemType", id],
    queryFn: () => api.getProblemType(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // if "problemTypes" query exist, we use it to init data
      if (queryClient.getQueryState(["problemTypes"])) {
        queryKey = ["problemTypes"];
        return queryClient
          .getQueryData(["problemTypes"])
          ?.find((problemType) => problemType.id === id);
      }

      // otherwise, we try to find the problemType in one of the "platform.problemTypes" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["platform.problemTypes"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((problemType) => {
            if (problemType.id === id) {
              found = problemType;
              queryKey = query[0];
            }
          });
        });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

// useQuery

export const useGetProblemTypes = () => useQuery(getProblemTypesQueryOptions());

export const useGetProblemTypesByPlatform = (platformId) => {
  const queryClient = useQueryClient();
  return useQuery(getProblemTypesByPlatformQueryOptions(platformId, queryClient));
};

export const useGetProblemType = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getProblemTypeQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetProblemTypes = () => {
  return useSuspenseQuery(getProblemTypesQueryOptions());
};

export const useSuspenseGetProblemType = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getProblemTypeQueryOptions(id, queryClient));
};

export const useSuspenseGetProblemTypesByPlatform = (platformId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getProblemTypesByPlatformQueryOptions(platformId, queryClient));
};
