import generateJSONHeaderNoXsrf from "./generateJSONHeaderNoXsrf";
import generateJSONHeader from "./generateJSONHeader";
import handleServerResponse from "./handleServerResponse";

const fetchServer = async ({ method, urlAPI, body, noXsrfHeaders, source }) => {
  const requestOptions = body
    ? {
        method,
        headers: noXsrfHeaders
          ? generateJSONHeaderNoXsrf()
          : generateJSONHeader(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    : {
        method,
        headers: noXsrfHeaders
          ? generateJSONHeaderNoXsrf()
          : generateJSONHeader(),
        mode: "cors",
      };

  const response = await fetch(urlAPI, requestOptions);

  return handleServerResponse(response, source);
};

export default fetchServer;
