import fetchServer from "../helpers/api/fetchServer";

export const getDataOrigins = async () => {
  const method = "GET";
  const urlAPI = "/api/dataOrigins";
  return await fetchServer({ method, urlAPI });
};

export const getDataOriginsByAccount = async (accountId) => {
  const method = "GET";
  const urlAPI = `/api/dataOrigins?account=${accountId}`;
  return await fetchServer({ method, urlAPI });
};

export const getDataOrigin = async (id) => {
  const method = "GET";
  const urlAPI = `/api/dataOrigins/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updateDataOrigin = async ({ id, accountId }) => {
  const method = "PUT";
  const urlAPI = `/api/dataOrigins/${id}`;
  const body = { accountId };
  return await fetchServer({ method, urlAPI, body });
};

