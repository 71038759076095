import fetchServer from "../helpers/api/fetchServer";

export const createProblemType = async ({ name, platformId }) => {
  const method = "POST";
  const urlAPI = "/api/problemTypes";
  const body = {
    name,
    platformId,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getProblemTypes = async () => {
  const method = "GET";
  const urlAPI = "/api/problemTypes";
  return await fetchServer({ method, urlAPI });
};

export const getProblemTypesByPlatform = async (platformId) => {
  const method = "GET";
  const urlAPI = `/api/problemTypes?platform=${platformId}`;
  return await fetchServer({ method, urlAPI });
};

export const getProblemType = async (id) => {
  const method = "GET";
  const urlAPI = `/api/problemTypes/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updateProblemType = async ({ id, name, platformId }) => {
  const method = "PUT";
  const urlAPI = `/api/problemTypes/${id}`;
  const body = {
    name,
    platformId,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const deleteProblemType = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/problemTypes/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreProblemType = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/problemTypes/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteProblemTypes = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/problemTypes/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreProblemTypes = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/problemTypes/restore-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};
