import * as api from "../apis/dataOriginsApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getDataOriginsQueryOptions = () => {
  return queryOptions({
    queryKey: ["dataOrigins"],
    queryFn: api.getDataOrigins,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });
};

export const getDataOriginsByAccountQueryOptions = (accountId, queryClient) => {
  return queryOptions({
    queryKey: ["account.dataOrigins", accountId],
    queryFn: () => api.getDataOriginsByAccount(accountId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["dataOrigins"])
        ?.filter((dataOrigin) => dataOrigin.account?.id === accountId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["dataOrigins"])?.dataUpdatedAt,
  });
};

export const getDataOriginQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["dataOrigin", id],
    queryFn: () => api.getDataOrigin(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // if "dataOrigins" query exist, we use it to init data
      if (queryClient.getQueryState(["dataOrigins"])) {
        queryKey = ["dataOrigins"];
        return queryClient
          .getQueryData(["dataOrigins"])
          ?.find((dataOrigin) => dataOrigin.id === id);
      }

      // otherwise, we try to find the dataOrigin in one of the "account.dataOrigins" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["account.dataOrigins"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((dataOrigin) => {
            if (dataOrigin.id === id) {
              found = dataOrigin;
              queryKey = query[0];
            }
          });
        });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

// useQuery

export const useGetDataOrigins = () => useQuery(getDataOriginsQueryOptions());

export const useGetDataOrigin = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getDataOriginQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetDataOrigins = () =>
  useSuspenseQuery(getDataOriginsQueryOptions());

export const useSuspenseGetDataOriginsByAccount = (accountId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getDataOriginsByAccountQueryOptions(accountId, queryClient)
  );
};

export const useSuspenseGetDataOrigin = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getDataOriginQueryOptions(id, queryClient));
};
