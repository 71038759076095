import fetchServer from "../helpers/api/fetchServer";

export const getPaginatedPostBoostCampaigns = async ({
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = "/api/postBoostCampaigns?";
  // Filters
  if (filterText) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `text=${filterText}`;
  }
  if (filterDeleted) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `deleted=${filterDeleted}`;
  }
  // Sort by
  if (urlAPI.slice(-1) !== "?") {
    urlAPI += "&";
  }

  urlAPI += `sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getPaginatedPostBoostCampaignsByPost = async ({
  postId,
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = `/api/postBoostCampaigns?post=${postId}`;
  // Filters
  if (filterText) {
    urlAPI += `&text=${filterText}`;
  }
  if (filterDeleted) {
    urlAPI += `&deleted=${filterDeleted}`;
  }
  // Sort by
  urlAPI += `&sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getPostBoostCampaign = async (id) => {
  const method = "GET";
  const urlAPI = `/api/postBoostCampaigns/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const getPostBoostCampaignStatsByDay = async (id) => {
  const method = "GET";
  const urlAPI = `/api/postBoostCampaigns/${id}/statsByDay`;
  return await fetchServer({ method, urlAPI });
};

export const updatePostBoostCampaignWait24HoursForNamingConvention =
  async ({ id }) => {
    const method = "PUT";
    const urlAPI = `/api/postBoostCampaigns/${id}/wait-24-hours-for-naming-convention`;
    const body = {};

    return await fetchServer({ method, urlAPI, body });
  };
