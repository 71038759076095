import * as api from "../apis/brandsApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getBrandsQueryOptions = () =>
  queryOptions({
    queryKey: ["brands"],
    queryFn: api.getBrands,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getBrandsByAdvertiserQueryOptions = (advertiserId, queryClient) =>
  queryOptions({
    queryKey: ["advertiser.brands", advertiserId],
    queryFn: () => api.getBrandsByAdvertiser(advertiserId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["brands"])
        ?.filter((brand) => brand.advertiser.id === advertiserId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["brands"])?.dataUpdatedAt,
  });

export const getBrandQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["brand", id],
    queryFn: () => api.getBrand(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // if "brands" query exist, we use it to init data
      if (queryClient.getQueryState(["brands"])) {
        queryKey = ["brands"];
        return queryClient
          .getQueryData(["brands"])
          ?.find((brand) => brand.id === id);
      }

      // otherwise, we try to find the brand in one of the "advertiser.brands" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["advertiser.brands"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((brand) => {
            if (brand.id === id) {
              found = brand;
              queryKey = query[0];
            }
          });
        });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

// useQuery

export const useGetBrands = () => useQuery(getBrandsQueryOptions());

export const useGetBrandsByAdvertiser = (advertiserId) => {
  const queryClient = useQueryClient();
  return useQuery(getBrandsByAdvertiserQueryOptions(advertiserId, queryClient));
};

export const useGetBrand = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getBrandQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetBrands = () => {
  return useSuspenseQuery(getBrandsQueryOptions());
};

export const useSuspenseGetBrand = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getBrandQueryOptions(id, queryClient));
};

export const useSuspenseGetBrandsByAdvertiser = (advertiserId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getBrandsByAdvertiserQueryOptions(advertiserId, queryClient));
};
