import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "date-desc",
  startDate: null,
  endDate: null,
  // This boolean allows the component to know that initFilterSet has run
  // This is useful for paginated entities
  isInitFiltersFinished: true,
};

// INITIAL STATE
const initialState = {
  posts: initialFilters,
  accounts: [],
  brands: [],
  dataOrigins: [],
  problemTypes: [],
};

// const mockState = {
//   posts: initialFilters,
//   accounts: [
//     {
//       id: "123",
//       filters: initialFilters,
//     },
//     {
//       id: "456",
//       filters: initialFilters,
//     },
//   ]
// };

const postFiltersSlice = createSlice({
  name: "postFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts.tab = action.payload.tab;
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                tab: action.payload.tab,
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                tab: action.payload.tab,
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "dataOrigins":
          state.dataOrigins = state.dataOrigins.map((dataOrigin) => {
            if (dataOrigin.id === action.payload.id) {
              const updatedFilters = {
                ...dataOrigin.filters,
                tab: action.payload.tab,
              };
              return { ...dataOrigin, filters: updatedFilters };
            } else {
              return dataOrigin;
            }
          });
          break;
        case "problemTypes":
          state.problemTypes = state.problemTypes.map((problemType) => {
            if (problemType.id === action.payload.id) {
              const updatedFilters = {
                ...problemType.filters,
                tab: action.payload.tab,
              };
              return { ...problemType, filters: updatedFilters };
            } else {
              return problemType;
            }
          });
          break;
        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts.text = action.payload.text;
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                text: action.payload.text,
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                text: action.payload.text,
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "dataOrigins":
          state.dataOrigins = state.dataOrigins.map((dataOrigin) => {
            if (dataOrigin.id === action.payload.id) {
              const updatedFilters = {
                ...dataOrigin.filters,
                text: action.payload.text,
              };
              return { ...dataOrigin, filters: updatedFilters };
            } else {
              return dataOrigin;
            }
          });
          break;
        case "problemTypes":
          state.problemTypes = state.problemTypes.map((problemType) => {
            if (problemType.id === action.payload.id) {
              const updatedFilters = {
                ...problemType.filters,
                text: action.payload.text,
              };
              return { ...problemType, filters: updatedFilters };
            } else {
              return problemType;
            }
          });
          break;
        default:
          break;
      }
    },
    setStartDate: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts.startDate = action.payload.startDate;
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                startDate: action.payload.startDate,
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                startDate: action.payload.startDate,
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "dataOrigins":
          state.dataOrigins = state.dataOrigins.map((dataOrigin) => {
            if (dataOrigin.id === action.payload.id) {
              const updatedFilters = {
                ...dataOrigin.filters,
                startDate: action.payload.startDate,
              };
              return { ...dataOrigin, filters: updatedFilters };
            } else {
              return dataOrigin;
            }
          });
          break;
        case "problemTypes":
          state.problemTypes = state.problemTypes.map((problemType) => {
            if (problemType.id === action.payload.id) {
              const updatedFilters = {
                ...problemType.filters,
                startDate: action.payload.startDate,
              };
              return { ...problemType, filters: updatedFilters };
            } else {
              return problemType;
            }
          });
          break;
        default:
          break;
      }
    },
    setEndDate: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts.endDate = action.payload.endDate;
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                endDate: action.payload.endDate,
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                endDate: action.payload.endDate,
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;

        case "dataOrigins":
          state.dataOrigins = state.dataOrigins.map((dataOrigin) => {
            if (dataOrigin.id === action.payload.id) {
              const updatedFilters = {
                ...dataOrigin.filters,
                endDate: action.payload.endDate,
              };
              return { ...dataOrigin, filters: updatedFilters };
            } else {
              return dataOrigin;
            }
          });
          break;
        case "problemTypes":
          state.problemTypes = state.problemTypes.map((problemType) => {
            if (problemType.id === action.payload.id) {
              const updatedFilters = {
                ...problemType.filters,
                endDate: action.payload.endDate,
              };
              return { ...problemType, filters: updatedFilters };
            } else {
              return problemType;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByDateAsc: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts.sortBy = "date-asc";
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                sortBy: "date-asc",
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "date-asc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "dataOrigins":
          state.dataOrigins = state.dataOrigins.map((dataOrigin) => {
            if (dataOrigin.id === action.payload.id) {
              const updatedFilters = {
                ...dataOrigin.filters,
                sortBy: "date-asc",
              };
              return { ...dataOrigin, filters: updatedFilters };
            } else {
              return dataOrigin;
            }
          });
          break;
        case "problemTypes":
          state.problemTypes = state.problemTypes.map((problemType) => {
            if (problemType.id === action.payload.id) {
              const updatedFilters = {
                ...problemType.filters,
                sortBy: "date-asc",
              };
              return { ...problemType, filters: updatedFilters };
            } else {
              return problemType;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByDateDesc: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts.sortBy = "date-desc";
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                sortBy: "date-desc",
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "date-desc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "dataOrigins":
          state.dataOrigins = state.dataOrigins.map((dataOrigin) => {
            if (dataOrigin.id === action.payload.id) {
              const updatedFilters = {
                ...dataOrigin.filters,
                sortBy: "date-desc",
              };
              return { ...dataOrigin, filters: updatedFilters };
            } else {
              return dataOrigin;
            }
          });
          break;
        case "problemTypes":
          state.problemTypes = state.problemTypes.map((problemType) => {
            if (problemType.id === action.payload.id) {
              const updatedFilters = {
                ...problemType.filters,
                sortBy: "date-desc",
              };
              return { ...problemType, filters: updatedFilters };
            } else {
              return problemType;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByImpressionsAsc: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts.sortBy = "impressions-asc";
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                sortBy: "impressions-asc",
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "impressions-asc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "dataOrigins":
          state.dataOrigins = state.dataOrigins.map((dataOrigin) => {
            if (dataOrigin.id === action.payload.id) {
              const updatedFilters = {
                ...dataOrigin.filters,
                sortBy: "impressions-asc",
              };
              return { ...dataOrigin, filters: updatedFilters };
            } else {
              return dataOrigin;
            }
          });
          break;
        case "problemTypes":
          state.problemTypes = state.problemTypes.map((problemType) => {
            if (problemType.id === action.payload.id) {
              const updatedFilters = {
                ...problemType.filters,
                sortBy: "impressions-asc",
              };
              return { ...problemType, filters: updatedFilters };
            } else {
              return problemType;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByImpressionsDesc: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts.sortBy = "impressions-desc";
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                sortBy: "impressions-desc",
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "impressions-desc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "dataOrigins":
          state.dataOrigins = state.dataOrigins.map((dataOrigin) => {
            if (dataOrigin.id === action.payload.id) {
              const updatedFilters = {
                ...dataOrigin.filters,
                sortBy: "impressions-desc",
              };
              return { ...dataOrigin, filters: updatedFilters };
            } else {
              return dataOrigin;
            }
          });
          break;
        case "problemTypes":
          state.problemTypes = state.problemTypes.map((problemType) => {
            if (problemType.id === action.payload.id) {
              const updatedFilters = {
                ...problemType.filters,
                sortBy: "impressions-desc",
              };
              return { ...problemType, filters: updatedFilters };
            } else {
              return problemType;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByAdSpentAsc: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts.sortBy = "adSpent-asc";
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                sortBy: "adSpent-asc",
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "adSpent-asc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "dataOrigins":
          state.dataOrigins = state.dataOrigins.map((dataOrigin) => {
            if (dataOrigin.id === action.payload.id) {
              const updatedFilters = {
                ...dataOrigin.filters,
                sortBy: "adSpent-asc",
              };
              return { ...dataOrigin, filters: updatedFilters };
            } else {
              return dataOrigin;
            }
          });
          break;
        case "problemTypes":
          state.problemTypes = state.problemTypes.map((problemType) => {
            if (problemType.id === action.payload.id) {
              const updatedFilters = {
                ...problemType.filters,
                sortBy: "adSpent-asc",
              };
              return { ...problemType, filters: updatedFilters };
            } else {
              return problemType;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByAdSpentDesc: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts.sortBy = "adSpent-desc";
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                sortBy: "adSpent-desc",
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "adSpent-desc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "dataOrigins":
          state.dataOrigins = state.dataOrigins.map((dataOrigin) => {
            if (dataOrigin.id === action.payload.id) {
              const updatedFilters = {
                ...dataOrigin.filters,
                sortBy: "adSpent-desc",
              };
              return { ...dataOrigin, filters: updatedFilters };
            } else {
              return dataOrigin;
            }
          });
          break;
        case "problemTypes":
          state.problemTypes = state.problemTypes.map((problemType) => {
            if (problemType.id === action.payload.id) {
              const updatedFilters = {
                ...problemType.filters,
                sortBy: "adSpent-desc",
              };
              return { ...problemType, filters: updatedFilters };
            } else {
              return problemType;
            }
          });
          break;
        default:
          break;
      }
    },
    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts = initialFilters;
          break;
        case "accounts":
          if (
            !state.accounts.find((account) => account.id === action.payload.id)
          ) {
            state.accounts.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        case "brands":
          if (!state.brands.find((brand) => brand.id === action.payload.id)) {
            state.brands.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        case "dataOrigins":
          if (
            !state.dataOrigins.find(
              (dataOrigin) => dataOrigin.id === action.payload.id
            )
          ) {
            state.dataOrigins.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        case "problemTypes":
          if (
            !state.problemTypes.find(
              (problemType) => problemType.id === action.payload.id
            )
          ) {
            state.problemTypes.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  setStartDate,
  setEndDate,
  sortByDateAsc,
  sortByDateDesc,
  sortByImpressionsAsc,
  sortByImpressionsDesc,
  sortByAdSpentAsc,
  sortByAdSpentDesc,
  initFilterSet,
} = postFiltersSlice.actions;
export const postFiltersReducer = postFiltersSlice.reducer;
