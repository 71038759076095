import * as api from "../apis/adPlatformsApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getAdPlatformsQueryOptions = () =>
  queryOptions({
    queryKey: ["adPlatforms"],
    queryFn: api.getAdPlatforms,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getAdPlatformQueryOptions = (id, queryClient) =>
  queryOptions({
    queryKey: ["adPlatform", id],
    queryFn: () => api.getAdPlatform(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["adPlatforms"])
        ?.find((adPlatform) => adPlatform.id === id),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["adPlatforms"])?.dataUpdatedAt,
  });

// useQuery

export const useGetAdPlatforms = () => useQuery(getAdPlatformsQueryOptions());

export const useGetAdPlatform = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getAdPlatformQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetAdPlatforms = () =>
  useSuspenseQuery(getAdPlatformsQueryOptions());

export const useSuspenseGetAdPlatform = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getAdPlatformQueryOptions(id, queryClient));
};
