import fetchServer from "../helpers/api/fetchServer";
import fetchServerUpdateImage from "../helpers/api/fetchServerUpdateImage";

export const createAdFormat = async ({ name, url }) => {
  const method = "POST";
  const urlAPI = "/api/adFormats";
  const body = {
    name, url
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getAdFormats = async () => {
  const method = "GET";
  const urlAPI = "/api/adFormats";
  return await fetchServer({ method, urlAPI });
};

export const getAdFormat = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adFormats/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updateAdFormat = async ({ id, name, url }) => {
  const method = "PUT";
  const urlAPI = `/api/adFormats/${id}`;
  const body = { name, url };
  return await fetchServer({ method, urlAPI, body });
};

export const updateAdFormatAvatar = async ({ id, formData }) => {
  const urlAPI = `/api/adFormats/${id}/avatar`;
  return await fetchServerUpdateImage({ urlAPI, formData });
};

export const deleteAdFormatAvatar = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/adFormats/${id}/avatar`;
  return await fetchServer({ method, urlAPI });
};

export const deleteAdFormat = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/adFormats/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreAdFormat = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/adFormats/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteAdFormats = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/adFormats/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreAdFormats = async (arrayIds) => {
  const method = "POST";
  const urlAPI = '/api/adFormats/restore-many';
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};