import {
  getDataOriginQueryOptions,
  getDataOriginsByAccountQueryOptions,
} from "../../queries/dataOrigins";
import { getAdvertisersQueryOptions } from "../../queries/advertisers";
import { getAccountsQueryOptions } from "../../queries/accounts";

// necessary entities are pre-fetched when a user hover a 'edit dataOrigin' link
export const dataOriginFormLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getAdvertisersQueryOptions());
  queryClient.ensureQueryData(getAccountsQueryOptions());
};

// dataOrigins are pre-fetched when a user hover a 'dataOrigins' link within a account
export const dataOriginListByAccountLoader = ({
  params,
  context: { queryClient },
}) => {
  const { accountId } = params;
  queryClient.ensureQueryData(
    getDataOriginsByAccountQueryOptions(accountId, queryClient)
  );
};

// dataOrigin is pre-fetched when a user hover a 'view dataOrigin' link
export const dataOriginViewLoader = ({ params, context: { queryClient } }) => {
  const { dataOriginId } = params;
  queryClient.ensureQueryData(
    getDataOriginQueryOptions(dataOriginId, queryClient)
  );
};
