import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetAdOpsObjective } from "../queries/adOpsObjectives";
import { ENTITY } from "../constants";

export const AdOpsObjectiveBreadcrumb = ({ match }) => {
  // Entity
  const { ADOPSOBJECTIVE } = ENTITY;

  const {
    data: adOpsObjective,
    isLoading,
    isError,
    error,
  } = useGetAdOpsObjective(match.params.adOpsObjectiveId);

  if (isLoading) {
    //return <span>Loading...</span>;
    return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
  }

  if (isError && error instanceof NotFoundException) {
    return <span>Not found</span>;
  }

  if (adOpsObjective) {
    return <span>{trimString(adOpsObjective[ADOPSOBJECTIVE.nameField], 20)}</span>;
  }

  return null;
};
