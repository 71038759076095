import * as api from "../apis/adOpsObjectivesApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getAdOpsObjectivesQueryOptions = () =>
  queryOptions({
    queryKey: ["adOpsObjectives"],
    queryFn: api.getAdOpsObjectives,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getAdOpsObjectiveQueryOptions = (id, queryClient) =>
  queryOptions({
    queryKey: ["adOpsObjective", id],
    queryFn: () => api.getAdOpsObjective(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["adOpsObjectives"])
        ?.find((adOpsObjective) => adOpsObjective.id === id),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["adOpsObjectives"])?.dataUpdatedAt,
  });

// useQuery

export const useGetAdOpsObjectives = () => useQuery(getAdOpsObjectivesQueryOptions());

export const useGetAdOpsObjective = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getAdOpsObjectiveQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetAdOpsObjectives = () =>
  useSuspenseQuery(getAdOpsObjectivesQueryOptions());

export const useSuspenseGetAdOpsObjective = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getAdOpsObjectiveQueryOptions(id, queryClient));
};
