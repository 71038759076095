import { createSlice } from "@reduxjs/toolkit";

const initialPagination = {
  page: 1,
  nbDocsPerPage: 25,
};

// INITIAL STATE
const initialState = {
  posts: initialPagination,
  accounts: [],
  brands: [],
  dataOrigins: [],
  problemTypes: [],
};

const postPaginationSlice = createSlice({
  name: "postPagination",
  initialState,
  reducers: {
    setPage: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts.page = action.payload.page;
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedPagination = {
                ...account.pagination,
                page: action.payload.page,
              };
              return { ...account, pagination: updatedPagination };
            } else {
              return account;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedPagination = {
                ...brand.pagination,
                page: action.payload.page,
              };
              return { ...brand, pagination: updatedPagination };
            } else {
              return brand;
            }
          });
          break;
        case "dataOrigins":
          state.dataOrigins = state.dataOrigins.map((dataOrigin) => {
            if (dataOrigin.id === action.payload.id) {
              const updatedPagination = {
                ...dataOrigin.pagination,
                page: action.payload.page,
              };
              return { ...dataOrigin, pagination: updatedPagination };
            } else {
              return dataOrigin;
            }
          });
          break;
        case "problemTypes":
          state.problemTypes = state.problemTypes.map((problemType) => {
            if (problemType.id === action.payload.id) {
              const updatedPagination = {
                ...problemType.pagination,
                page: action.payload.page,
              };
              return { ...problemType, pagination: updatedPagination };
            } else {
              return problemType;
            }
          });
          break;
        default:
          break;
      }
    },
    setNbDocsPerPage: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts.nbDocsPerPage = action.payload.nbDocsPerPage;
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedPagination = {
                ...account.pagination,
                nbDocsPerPage: action.payload.nbDocsPerPage,
              };
              return { ...account, pagination: updatedPagination };
            } else {
              return account;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedPagination = {
                ...brand.pagination,
                nbDocsPerPage: action.payload.nbDocsPerPage,
              };
              return { ...brand, pagination: updatedPagination };
            } else {
              return brand;
            }
          });
          break;
        case "dataOrigins":
          state.dataOrigins = state.dataOrigins.map((dataOrigin) => {
            if (dataOrigin.id === action.payload.id) {
              const updatedPagination = {
                ...dataOrigin.pagination,
                nbDocsPerPage: action.payload.nbDocsPerPage,
              };
              return { ...dataOrigin, pagination: updatedPagination };
            } else {
              return dataOrigin;
            }
          });
          break;
        case "problemTypes":
          state.problemTypes = state.problemTypes.map((problemType) => {
            if (problemType.id === action.payload.id) {
              const updatedPagination = {
                ...problemType.pagination,
                nbDocsPerPage: action.payload.nbDocsPerPage,
              };
              return { ...problemType, pagination: updatedPagination };
            } else {
              return problemType;
            }
          });
          break;
        default:
          break;
      }
    },
    initPaginationSet: (state, action) => {
      switch (action.payload.module) {
        case "posts":
          state.posts = initialPagination;
          break;
        case "accounts":
          if (
            !state.accounts.find((account) => account.id === action.payload.id)
          ) {
            state.accounts.push({
              id: action.payload.id,
              pagination: initialPagination,
            });
          }
          break;
        case "brands":
          if (!state.brands.find((brand) => brand.id === action.payload.id)) {
            state.brands.push({
              id: action.payload.id,
              pagination: initialPagination,
            });
          }
          break;
        case "dataOrigins":
          if (
            !state.dataOrigins.find(
              (dataOrigin) => dataOrigin.id === action.payload.id
            )
          ) {
            state.dataOrigins.push({
              id: action.payload.id,
              pagination: initialPagination,
            });
          }
          break;
        case "problemTypes":
          if (
            !state.problemTypes.find(
              (problemType) => problemType.id === action.payload.id
            )
          ) {
            state.problemTypes.push({
              id: action.payload.id,
              pagination: initialPagination,
            });
          }
          break;
        default:
          break;
      }
    },
  },
});

export const { setPage, setNbDocsPerPage, initPaginationSet } =
  postPaginationSlice.actions;
export const postPaginationReducer = postPaginationSlice.reducer;
