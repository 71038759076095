import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetAccount } from "../queries/accounts";
import { ENTITY } from "../constants";

export const AccountBreadcrumb = ({ match }) => {
  // Entity
  const { ACCOUNT } = ENTITY;

    const {
      data: account,
      isLoading,
      isError,
      error,
    } = useGetAccount(match.params.accountId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (account) {
      account.url = `${account.platform.url}/${account.username}`;
      return <span>{trimString(account[ACCOUNT.nameField], 20)}</span>;
    }
  
    return null;
  };