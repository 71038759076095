import {
  getAccountsQueryOptions,
  getAccountQueryOptions,
  getAccountsByAdvertiserQueryOptions,
  getAccountsByPlatformQueryOptions
} from "../../queries/accounts";
import { getAdvertisersQueryOptions } from "../../queries/advertisers";
import { getPlatformsQueryOptions } from "../../queries/platforms";

// necessary entities are pre-fetched when a user hover a 'create account' or a 'edit account' link
export const accountFormLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getAdvertisersQueryOptions());
  queryClient.ensureQueryData(getPlatformsQueryOptions());
};

// accounts are pre-fetched when a user hover a 'accounts' link
export const accountListLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getAccountsQueryOptions());
};

// accounts are pre-fetched when a user hover a 'accounts' link within a advertiser
export const accountListByAdvertiserLoader = ({
  params,
  context: { queryClient },
}) => {
  const { advertiserId } = params;
  queryClient.ensureQueryData(
    getAccountsByAdvertiserQueryOptions(advertiserId, queryClient)
  );
};

// accounts are pre-fetched when a user hover a 'accounts' link within a platform
export const accountListByPlatformLoader = ({
  params,
  context: { queryClient },
}) => {
  const { platformId } = params;
  queryClient.ensureQueryData(getAccountsByPlatformQueryOptions(platformId, queryClient));
};

// account is pre-fetched when a user hover a 'view account' link
export const accountViewLoader = ({ params, context: { queryClient } }) => {
  const { accountId } = params;
  queryClient.ensureQueryData(
    getAccountQueryOptions(accountId, queryClient)
  );
};