import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetETLTask } from "../queries/ETLTasks";
import { ENTITY } from "../constants";

export const ETLTaskBreadcrumb = ({ match }) => {
  // Entity
  const { ETLTASK } = ENTITY;

    const {
      data: ETLTask,
      isLoading,
      isError,
      error,
    } = useGetETLTask(match.params.ETLTaskId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (ETLTask) {
      return <span>{trimString(ETLTask[ETLTASK.nameField], 20)}</span>;
    }
  
    return null;
  };