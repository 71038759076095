import { Box, Container } from "@material-ui/core";
import useSettings from "../../../hooks/useSettings";
import PageHeader from "./PageHeader";
import { Helmet } from "react-helmet-async";

const PageWrapper = ({
  browserPageTitle,
  // title is the leanads page title (displayed at the top of page header)
  title,
  avatar: { url, name } = { url: "", name: "" },
  hasCancelButton = false,
  hasBackButton = false,
  hasAddButton = false,
  editButtonUrl = false,
  children,
}) => {
  // Hooks

  const { settings } = useSettings();

  // Rendering

  return (
    <>
      <Helmet>
        <title>{`Lean Ads | ${browserPageTitle}`}</title>
      </Helmet>
      <Container maxWidth={settings.compact ? "xl" : false} sx={{ mt: 6 }}>
        <PageHeader
          title={title}
          avatar={{ url, name }}
          hasCancelButton={hasCancelButton}
          hasBackButton={hasBackButton}
          hasAddButton={hasAddButton}
          editButtonUrl={editButtonUrl}
        />
        <Box sx={{ mt: 3 }}>{children}</Box>
      </Container>
    </>
  );
};

export default PageWrapper;
