import { PermissionException } from "../customExceptions";
import store from "../store";

export const checkPermissions = (neededPermissions) => {
  const { currentUser } = store.getState().auth;
  const hasNeededPermissions = neededPermissions.every(({ feature, action }) => {
    const userPermissions = currentUser?.permissions.find(p => p.feature === feature);

    // If the feature is not found in user permissions, return false
    if (!userPermissions) return false;

    // If the action is READ, return true as the existence of the feature implies READ permission
    if (action === 'READ') return true;

    const propertyMap = {
      CREATE: "canCreate",
      UPDATE: "canUpdate",
      DELETE: "canDelete",
    };
    return userPermissions[propertyMap[action]];
  });
  return hasNeededPermissions;
};

const waitForAuthReady = async () => {
  const { isInitialized } = store.getState().auth;
  // check if auth is already initialized, if it's already initialized, we return immediately
  if (isInitialized) {
    return true;
  }

  // wait until auth is initialized
  return new Promise((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.auth.isInitialized) {
        // if auth is initialized, then we stop subscribe and return
        unsubscribe();
        resolve();
      }
    });
  });
};

export const checkPermissionsAndRedirect = async (permissions) => {
  // in AuthProvider, we initialize auth. Whether the user is loggedin or not, it will be initialized.
  // checkPermissions should only happen after auth is initialized (otherwise use info is not ready yet
  // so we wait for it
    await waitForAuthReady();

    // REFACTOR test what is happening when refresh token fails
    if (!checkPermissions(permissions)) {

      throw new PermissionException('Unsufficient permissions')
    }
};