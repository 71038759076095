import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import Spinner from "../../components/general/Spinner";

const campaignDoctorRoute = createRoute({
  getParentRoute: () => appRoute,
  path: "campaign-doctor",
  beforeLoad: () =>
    checkPermissionsAndRedirect([
      { feature: "campaignDoctor", action: "READ" },
    ]),
  staticData: {
    breadcrumb: "Doctor (Campaigns)",
  },
  pendingComponent: Spinner,
}).lazy(() =>
  import("./campaignDoctor.lazy").then((d) => d.CampaignDoctorRoute)
);

export default campaignDoctorRoute;
