import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialSettings = {
  startDate: moment().startOf("month").valueOf(),
  endDate: moment().endOf("month").valueOf(),
};

// INITIAL STATE
const initialState = {
  accounts: [],
};


const accountReportingSettingsSlice = createSlice({
  name: "accountReportingSettings",
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.accounts = state.accounts.map((account) => {
        if (account.id === action.payload.id) {
          const updatedSettings = {
            ...account.reportingSettings,
            startDate: action.payload.startDate,
          };
          return { ...account, reportingSettings: updatedSettings };
        } else {
          return account;
        }
      });
    },
    setEndDate: (state, action) => {
      state.accounts = state.accounts.map((account) => {
        if (account.id === action.payload.id) {
          const updatedSettings = {
            ...account.reportingSettings,
            endDate: action.payload.endDate,
          };
          return { ...account, reportingSettings: updatedSettings };
        } else {
          return account;
        }
      });
    },
    initReportingSettingsSet: (state, action) => {
      if (!state.accounts.find((account) => account.id === action.payload.id)) {
        state.accounts.push({
          id: action.payload.id,
          reportingSettings: initialSettings,
        });
      }
    },
  },
});

export const { setStartDate, setEndDate, initReportingSettingsSet } =
  accountReportingSettingsSlice.actions;
export const accountReportingSettingsReducer =
  accountReportingSettingsSlice.reducer;
