import { useState } from "react";
import { Outlet } from "@tanstack/react-router";
import { experimentalStyled } from "@material-ui/core/styles";
import AppNavbar from "./Navbar";
import AppSidebar from "./Sidebar";

import styled from "@emotion/styled";

// Styles

const AppLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const AppLayoutWrapper = styled.div(({ theme, isCollapsed }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  // paddingTop: "64px",
  paddingTop: "48px",
  paddingBottom: "10px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: isCollapsed ? "80px" : "280px",
  },
}));

const AppLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const AppLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const AppLayout = () => {
  // Hooks

  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Handle Log Error

  // Rendering
  return (
    <AppLayoutRoot>
      <AppNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />
      <AppSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      <AppLayoutWrapper isCollapsed={isCollapsed}>
        <AppLayoutContainer>
          <AppLayoutContent>
            <Outlet />
          </AppLayoutContent>
        </AppLayoutContainer>
      </AppLayoutWrapper>
    </AppLayoutRoot>
  );
};

export default AppLayout;
