import * as api from "../apis/ETLRunsApi";
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
  queryOptions,
  useSuspenseQuery
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getPaginatedETLRunsQueryOptions = ({
  filterText,
  filterStartDate,
  filterEndDate,
  filterWithErrors,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "ETLRuns",
      filterText,
      filterStartDate,
      filterEndDate,
      filterWithErrors,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedETLRuns({
        filterText,
        filterStartDate,
        filterEndDate,
        filterWithErrors,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getETLRunQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["ETLRun", id],
    queryFn: () => api.getETLRun(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // we try to find the ETLRun in one of the "ETLRuns" queries
      let found;

      queryClient.getQueriesData({ queryKey: ["ETLRuns"] }).forEach((query) => {
        const data = query[1]?.data;
        if (data) {
          data.forEach((ETLRun) => {
            if (ETLRun.id === id) {
              found = ETLRun;
              queryKey = query[0];
            }
          });
        }
      });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

// useQuery

export const useGetPaginatedETLRuns = ({
  isInitFiltersFinished,
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery({
    ...getPaginatedETLRunsQueryOptions({
      filterText,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    }),
    enabled: Boolean(isInitFiltersFinished),
  });

export const useGetETLRun = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getETLRunQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetETLRun= (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getETLRunQueryOptions(id, queryClient));
};