import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import Spinner from "../../components/general/Spinner";

const dataImportsRoute = createRoute({
  getParentRoute: () => appRoute,
  path: "data-imports",
  beforeLoad: () =>
    checkPermissionsAndRedirect([
      { feature: "dataImports", action: "READ" },
    ]),
  staticData: {
    breadcrumb: "Data imports",
  },
  pendingComponent: Spinner
}).lazy(() =>
  import("./dataImports.lazy").then((d) => d.DataImportsRoute)
);

export default dataImportsRoute;
