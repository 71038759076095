import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import PageWrapper from "../../components/layouts/app/PageWrapper";
import Spinner from "../../components/general/Spinner";

const campaignAgendaRoute = createRoute({
  getParentRoute: () => appRoute,
  path: "campaign-agenda",
  beforeLoad: () =>
    checkPermissionsAndRedirect([
      { feature: "campaignAgenda", action: "READ" },
    ]),
  staticData: {
    breadcrumb: "Agenda",
  },
  pendingComponent: () => (
    <PageWrapper
      title={"Campaigns Agenda"}
      browserPageTitle={"Campaigns Agenda"}
    >
      <Spinner />
    </PageWrapper>
  ),
}).lazy(() =>
  import("./campaignAgenda.lazy").then((d) => d.CampaignAgendaRoute)
);

export default campaignAgendaRoute;
