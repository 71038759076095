import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetPostBoostCampaign } from "../queries/postBoostCampaigns";
import { ENTITY } from "../constants";

export const PostBoostCampaignBreadcrumb = ({ match }) => {
  // Entity
  const { POSTBOOSTCAMPAIGN } = ENTITY;

  const {
    data: postBoostCampaign,
    isLoading,
    isError,
    error,
  } = useGetPostBoostCampaign(match.params.postBoostCampaignId);

  if (isLoading) {
    //return <span>Loading...</span>;
    return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
  }

  if (isError && error instanceof NotFoundException) {
    return <span>Not found</span>;
  }

  if (postBoostCampaign) {
    return <span>{trimString(postBoostCampaign[POSTBOOSTCAMPAIGN.nameField], 20)}</span>;
  }

  return null;
};
