import {
  getOffersQueryOptions,
  getOfferQueryOptions,
  getOffersByAdFormatQueryOptions,
  getOffersByAdOpsObjectiveQueryOptions,
  getOffersByCountryQueryOptions,
  getOffersByPlatformQueryOptions,
  getOffersBySpecTemplateQueryOptions,
} from "../../queries/offers";
import { getPlatformsQueryOptions } from "../../queries/platforms";
import { getAdFormatsQueryOptions } from "../../queries/adFormats";
import { getSpecTemplatesQueryOptions } from "../../queries/specTemplates";
import { getAdOpsObjectivesQueryOptions } from "../../queries/adOpsObjectives";
import { getCountriesQueryOptions } from "../../queries/countries";
import { getMetricsQueryOptions } from "../../queries/metrics";


// necessary entities are pre-fetched when a user hover a 'create offer' or a 'edit offer' link
export const offerFormLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getPlatformsQueryOptions());
  queryClient.ensureQueryData(getAdFormatsQueryOptions());
  queryClient.ensureQueryData(getSpecTemplatesQueryOptions());
  queryClient.ensureQueryData(getAdOpsObjectivesQueryOptions());
  queryClient.ensureQueryData(getCountriesQueryOptions());
  queryClient.ensureQueryData(getMetricsQueryOptions());
};

// offers are pre-fetched when a user hover a 'offers' link
export const offerListLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getOffersQueryOptions());
};

// offers are pre-fetched when a user hover a 'offers' link within a platform
export const offerListByPlatformLoader = ({
  params,
  context: { queryClient },
}) => {
  const { platformId } = params;
  queryClient.ensureQueryData(getOffersByPlatformQueryOptions(platformId, queryClient));
};

// offers are pre-fetched when a user hover a 'offers' link within a adFormat
export const offerListByAdFormatLoader = ({
  params,
  context: { queryClient },
}) => {
  const { adFormatId } = params;
  queryClient.ensureQueryData(getOffersByAdFormatQueryOptions(adFormatId, queryClient));
};

// offers are pre-fetched when a user hover a 'offers' link within a adOpsObjective
export const offerListByAdOpsObjectiveLoader = ({
  params,
  context: { queryClient },
}) => {
  const { adOpsObjectiveId } = params;
  queryClient.ensureQueryData(
    getOffersByAdOpsObjectiveQueryOptions(adOpsObjectiveId, queryClient)
  );
};

// offers are pre-fetched when a user hover a 'offers' link within a country
export const offerListByCountryLoader = ({
  params,
  context: { queryClient },
}) => {
  const { countryId } = params;
  queryClient.ensureQueryData(getOffersByCountryQueryOptions(countryId, queryClient));
};

// offers are pre-fetched when a user hover a 'offers' link within a specTemplate
export const offerListBySpecTemplateLoader = ({
  params,
  context: { queryClient },
}) => {
  const { specTemplateId } = params;
  queryClient.ensureQueryData(
    getOffersBySpecTemplateQueryOptions(specTemplateId, queryClient)
  );
};

// offer is pre-fetched when a user hover a 'view offer' link
export const offerViewLoader = ({ params, context: { queryClient } }) => {
  const { offerId } = params;
  queryClient.ensureQueryData(getOfferQueryOptions(offerId, queryClient));
};
