import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetAdFormat } from "../queries/adFormats";
import { ENTITY } from "../constants";

export const AdFormatBreadcrumb = ({ match }) => {
  // Entity
  const { ADFORMAT } = ENTITY;

    const {
      data: adFormat,
      isLoading,
      isError,
      error,
    } = useGetAdFormat(match.params.adFormatId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (adFormat) {
      return <span>{trimString(adFormat[ADFORMAT.nameField], 20)}</span>;
    }
  
    return null;
  };