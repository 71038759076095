import { createSlice } from "@reduxjs/toolkit";

const initialSettings = {
  graphMetric: "realBudgetDelivered",
  graphType: "bar",
  graphSortBy: "metric",
  visibleSettings: true,
};

// INITIAL STATE
const initialState = {
  adOpsCampaigns: [],
};

const adOpsCampaignPerformanceSettingsSlice = createSlice({
  name: "adOpsCampaignPerformanceSettings",
  initialState,
  reducers: {
    setGraphMetric: (state, action) => {
      state.adOpsCampaigns = state.adOpsCampaigns.map((adOpsCampaign) => {
        if (adOpsCampaign.id === action.payload.id) {
          const updatedSettings = {
            ...adOpsCampaign.performanceSettings,
            graphMetric: action.payload.graphMetric,
          };
          return { ...adOpsCampaign, performanceSettings: updatedSettings };
        } else {
          return adOpsCampaign;
        }
      });
    },
    setGraphType: (state, action) => {
      state.adOpsCampaigns = state.adOpsCampaigns.map((adOpsCampaign) => {
        if (adOpsCampaign.id === action.payload.id) {
          const updatedSettings = {
            ...adOpsCampaign.performanceSettings,
            graphType: action.payload.graphType,
          };
          return { ...adOpsCampaign, performanceSettings: updatedSettings };
        } else {
          return adOpsCampaign;
        }
      });
    },
    setGraphSortBy: (state, action) => {
      state.adOpsCampaigns = state.adOpsCampaigns.map((adOpsCampaign) => {
        if (adOpsCampaign.id === action.payload.id) {
          const updatedSettings = {
            ...adOpsCampaign.performanceSettings,
            graphSortBy: action.payload.graphSortBy,
          };
          return { ...adOpsCampaign, performanceSettings: updatedSettings };
        } else {
          return adOpsCampaign;
        }
      });
    },
    setVisibleSettings: (state, action) => {
      state.adOpsCampaigns = state.adOpsCampaigns.map((adOpsCampaign) => {
        if (adOpsCampaign.id === action.payload.id) {
          const updatedSettings = {
            ...adOpsCampaign.performanceSettings,
            visibleSettings: action.payload.visibleSettings,
          };
          return { ...adOpsCampaign, performanceSettings: updatedSettings };
        } else {
          return adOpsCampaign;
        }
      });
    },
    initPerformanceSettingsSet: (state, action) => {
      if (
        !state.adOpsCampaigns.find(
          (adOpsCampaign) => adOpsCampaign.id === action.payload.id
        )
      ) {
        state.adOpsCampaigns.push({
          id: action.payload.id,
          performanceSettings: initialSettings,
        });
      }
    },
  },
});

export const {
  setGraphMetric,
  setGraphType,
  setGraphSortBy,
  setVisibleSettings,
  initPerformanceSettingsSet,
} = adOpsCampaignPerformanceSettingsSlice.actions;
export const adOpsCampaignPerformanceSettingsReducer =
  adOpsCampaignPerformanceSettingsSlice.reducer;
