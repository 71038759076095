import * as api from "../apis/countriesApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getCountriesQueryOptions = () =>
  queryOptions({
    queryKey: ["countries"],
    queryFn: api.getCountries,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getCountryQueryOptions = (id, queryClient) =>
  queryOptions({
    queryKey: ["country", id],
    queryFn: () => api.getCountry(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["countries"])
        ?.find((country) => country.id === id),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["countries"])?.dataUpdatedAt,
  });

// useQuery

export const useGetCountries = () => useQuery(getCountriesQueryOptions());

export const useGetCountry = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getCountryQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetCountries = () =>
  useSuspenseQuery(getCountriesQueryOptions());

export const useSuspenseGetCountry = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getCountryQueryOptions(id, queryClient));
};
