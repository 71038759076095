import { Link as RouterLink } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";
import { Box, Button, Container, Link, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const NotFound = ({ origin }) => {
  let homeUrl;

  switch (origin) {
    case "app":
      homeUrl = "/app";
      break;
    case "website":
      homeUrl = "/";
      break;
    default:
      homeUrl = "/";
      break;
  }

  // Hooks

  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  // Rendering

  return (
    <>
      <Helmet>
        <title>Lean ads | Page not found</title>
      </Helmet>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          minHeight: "100%",
          px: 3,
          py: "80px",
        }}
      >
        <Container>
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? "h4" : "h1"}
          >
            404: The page you are looking for isn’t here
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            You either tried some shady route or you came here by mistake.
            Whichever it is, try to {""}
            <Link
              color="textPrimary"
              underline="hover"
              component={RouterLink}
              to={homeUrl}
            >
              go back home
            </Link>
            .
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Box
              alt="Page not found"
              component="img"
              src={`/static/error/error404_${theme.palette.mode}.svg`}
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 350,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button
              color="primary"
              component={RouterLink}
              to={homeUrl}
              variant="outlined"
            >
              Back Home
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NotFound;
