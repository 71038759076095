import fetchServer from "../helpers/api/fetchServer";
import fetchServerUpdateImage from "../helpers/api/fetchServerUpdateImage";

export const createAdOpsObjective = async ({ name, url }) => {
  const method = "POST";
  const urlAPI = "/api/adOpsObjectives";
  const body = {
    name, url
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getAdOpsObjectives = async () => {
  const method = "GET";
  const urlAPI = "/api/adOpsObjectives";
  return await fetchServer({ method, urlAPI });
};

export const getAdOpsObjective = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adOpsObjectives/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updateAdOpsObjective = async ({ id, name, url }) => {
  const method = "PUT";
  const urlAPI = `/api/adOpsObjectives/${id}`;
  const body = { name, url };
  return await fetchServer({ method, urlAPI, body });
};

export const updateAdOpsObjectiveAvatar = async ({ id, formData }) => {
  const urlAPI = `/api/adOpsObjectives/${id}/avatar`;
  return await fetchServerUpdateImage({ urlAPI, formData });
};

export const deleteAdOpsObjectiveAvatar = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/adOpsObjectives/${id}/avatar`;
  return await fetchServer({ method, urlAPI });
};

export const deleteAdOpsObjective = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/adOpsObjectives/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreAdOpsObjective = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/adOpsObjectives/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteAdOpsObjectives = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/adOpsObjectives/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreAdOpsObjectives = async (arrayIds) => {
  const method = "POST";
  const urlAPI = '/api/adOpsObjectives/restore-many';
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};