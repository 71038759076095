const generateJSONHeader = () => {
  const xsrfToken = localStorage.getItem("xsrfToken") || null;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (xsrfToken) {
    headers["x-xsrf-token"] = xsrfToken;
  }
  return headers;
};

export default generateJSONHeader;
