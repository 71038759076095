import { Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import InitializingScreen from "../general/InitializingScreen";
import { useInitAuth } from "../../mutations/auth";

const AuthProvider = ({ children }) => {
  // Mutations

  const { mutate: mutateInitAuth } = useInitAuth();
  const firstTime = useRef(false);
  // Hooks
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    // only run this one time (this is for development mode, because a conflict is happening somewhere. it is not super clear)
    if (firstTime.current) {
      return;
    }
    mutateInitAuth();
    firstTime.current = true;
  }, [mutateInitAuth]);

  // Rendering

  return (
    <Fragment>
      {auth.isInitialized ? children : <InitializingScreen />}
    </Fragment>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
