import {
  getCampaignsQueryOptions,
  getCampaignQueryOptions,
  getCampaignsByAdvertiserQueryOptions,
  getCampaignsByBrandQueryOptions,
} from "../../queries/campaigns";
import { getAdvertisersQueryOptions } from "../../queries/advertisers";
import { getBrandsQueryOptions } from "../../queries/brands";

// necessary entities are pre-fetched when a user hover a 'create campaign' or a 'edit campaign' link
export const campaignFormLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getAdvertisersQueryOptions());
  queryClient.ensureQueryData(getBrandsQueryOptions());
};

// campaigns are pre-fetched when a user hover the 'campaigns' link
export const campaignListLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getCampaignsQueryOptions());
};

// campaigns are pre-fetched when a user hover a 'campaigns' link within a advertiser
export const campaignListByAdvertiserLoader = ({
  params,
  context: { queryClient },
}) => {
  const { advertiserId } = params;
  queryClient.ensureQueryData(
    getCampaignsByAdvertiserQueryOptions(advertiserId, queryClient)
  );
};

// campaigns are pre-fetched when a user hover a 'campaigns' link within a brand
export const campaignListByBrandLoader = ({
  params,
  context: { queryClient },
}) => {
  const { brandId } = params;
  queryClient.ensureQueryData(getCampaignsByBrandQueryOptions(brandId, queryClient));
};

// campaign is pre-fetched when a user hover a 'view campaign' link
export const campaignViewLoader = ({ params, context: { queryClient } }) => {
  const { campaignId } = params;
  queryClient.ensureQueryData(getCampaignQueryOptions(campaignId, queryClient));
};
