import "react-perfect-scrollbar/dist/css/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider";
import { SettingsProvider } from "./contexts/SettingsContext";
import store from "./store";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { routeTree } from "./routes";
import ErrorFallback from "./components/error/ErrorFallback";
import NotFound from "./pages/NotFound";

const queryClient = new QueryClient();
const router = createRouter({
  routeTree,
  // routes will be preloaded by default when the user hovers over a link
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  context: { queryClient },
});

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <QueryClientProvider client={queryClient}>
                <RouterProvider
                  router={router}
                  defaultErrorComponent={ErrorFallback}
                  // it is not super clear when this defaultNotFoundComponent will be rendered
                  // at the time I write this comment, the defaultNotFoundComponent is rendered when we ask for a url like this :
                  // http://localhost:3000/app/campaigns/66f431c203b6fdba8badab3d/notexist
                  // where the campaign id is valid but "notexist" corresponds to a tab that does not exist
                  // it can be considered a tanstack-router bug, because the notFoundComponent of the app should be displayed 
                  defaultNotFoundComponent={() => <NotFound origin="website" />}
                />
                <ReactQueryDevtools />
              </QueryClientProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>
);
