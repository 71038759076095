import { createRoute } from "@tanstack/react-router";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { adOpsCampaignRouteFactory } from "./adOpsCampaign";
import { lineItemViewLoader, lineItemFormLoader } from "../loaders/lineItem";
import { adOpListCampaignsByLineItemLoader } from "../loaders/adOpsCampaign";
import { LineItemBreadcrumb } from "../../breadcrumbs/lineItem";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { LINEITEM, ADOPSCAMPAIGN } = ENTITY;

export const lineItemRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const lineItemRoute = createRoute({
    getParentRoute: () => parent,
    path: LINEITEM.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: LINEITEM.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: LINEITEM.label.upFirstPlural,
    },
  });

  // List lineItems

  const lineItemListRoute = createRoute({
    getParentRoute: () => lineItemRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={LINEITEM} />,
  }).lazy(() => import("./lineItem.lazy").then((d) => d.LineItemListRoute));

  // Create lineItem

  const lineItemCreateRoute = createRoute({
    getParentRoute: () => lineItemRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: LINEITEM.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    loader: lineItemFormLoader,
    pendingComponent: () => <EntityCreateLoading entity={LINEITEM} />,
  }).lazy(() => import("./lineItem.lazy").then((d) => d.LineItemCreateRoute));

  // View lineItem

  const lineItemViewRootRoute = createRoute({
    getParentRoute: () => lineItemRoute,
    path: "$lineItemId",
    staticData: {
      breadcrumb: LineItemBreadcrumb,
    },
    loader: lineItemViewLoader,
  });

  // Edit lineItem

  const lineItemEditRoute = createRoute({
    getParentRoute: () => lineItemViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    loader: lineItemFormLoader,
    pendingComponent: () => <EntityEditLoading entity={LINEITEM} />,
  }).lazy(() => import("./lineItem.lazy").then((d) => d.LineItemEditRoute));

  // View lineItem Layout

  const lineItemViewLayoutRoute = createRoute({
    getParentRoute: () => lineItemViewRootRoute,
    id: "lineItem-view-layout",
    pendingComponent: () => <EntityViewLoading entity={LINEITEM} />,
  }).lazy(() =>
    import("./lineItem.lazy").then((d) => d.LineItemViewLayoutRoute)
  );

  // View lineItem - General Tab

  const lineItemViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => lineItemViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./lineItem.lazy").then((d) => d.LineItemViewLayoutGeneralTabRoute)
  );

  // View lineItem - Assets Tab

  const lineItemViewLayoutAssetsTabRoute = createRoute({
    getParentRoute: () => lineItemViewLayoutRoute,
    path: "assets",
    staticData: {
      breadcrumb: "Assets",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./lineItem.lazy").then((d) => d.LineItemViewLayoutAssetsTabRoute)
  );

  // View lineItem - Setup Tab

  const lineItemViewLayoutSetupTabRoute = createRoute({
    getParentRoute: () => lineItemViewLayoutRoute,
    path: "setup",
    staticData: {
      breadcrumb: "Setup",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./lineItem.lazy").then((d) => d.LineItemViewLayoutSetupTabRoute)
  );

  // View lineItem - Goals Tab

  const lineItemViewLayoutGoalsTabRoute = createRoute({
    getParentRoute: () => lineItemViewLayoutRoute,
    path: "goals",
    staticData: {
      breadcrumb: "Goals",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./lineItem.lazy").then((d) => d.LineItemViewLayoutGoalsTabRoute)
  );

  // View lineItem - Performance Tab

  const lineItemViewLayoutPerformanceTabRoute = createRoute({
    getParentRoute: () => lineItemViewLayoutRoute,
    path: "performance",
    staticData: {
      breadcrumb: "Performance",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./lineItem.lazy").then(
      (d) => d.LineItemViewLayoutPerformanceTabRoute
    )
  );

  // View lineItem - Data check Tab

  const lineItemViewLayoutDataCheckTabRoute = createRoute({
    getParentRoute: () => lineItemViewLayoutRoute,
    path: "dataCheck",
    staticData: {
      breadcrumb: "Data check",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./lineItem.lazy").then((d) => d.LineItemViewLayoutDataCheckTabRoute)
  );

  // View lineItem - Presentation Tab

  const lineItemViewLayoutPresentationTabRoute = createRoute({
    getParentRoute: () => lineItemViewLayoutRoute,
    path: "presentation",
    staticData: {
      breadcrumb: "Presentation",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./lineItem.lazy").then(
      (d) => d.LineItemViewLayoutPresentationTabRoute
    )
  );

  // View lineItem - About Tab

  const lineItemViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => lineItemViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./lineItem.lazy").then((d) => d.LineItemViewLayoutAboutTabRoute)
  );

  // View lineItem - AdOpsCampaigns Tab

  const lineItemViewLayoutAdOpsCampaignListTabRoute = createRoute({
    getParentRoute: () => lineItemViewLayoutRoute,
    path: ADOPSCAMPAIGN.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ADOPSCAMPAIGN.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ADOPSCAMPAIGN.label.upFirstPlural,
    },
    loader: adOpListCampaignsByLineItemLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./lineItem.lazy").then(
      (d) => d.LineItemViewLayoutAdOpsCampaignListTabRoute
    )
  );

  // LineItem Children
  const adOpsCampaignRoute = adOpsCampaignRouteFactory(lineItemViewRootRoute);

  // Route tree
  lineItemRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [lineItemListRoute]),
    lineItemCreateRoute,
    lineItemViewRootRoute.addChildren([
      lineItemEditRoute,
      lineItemViewLayoutRoute.addChildren([
        lineItemViewLayoutGeneralTabRoute,
        lineItemViewLayoutAssetsTabRoute,
        lineItemViewLayoutSetupTabRoute,
        lineItemViewLayoutGoalsTabRoute,
        lineItemViewLayoutPerformanceTabRoute,
        lineItemViewLayoutDataCheckTabRoute,
        lineItemViewLayoutPresentationTabRoute,
        lineItemViewLayoutAboutTabRoute,
        lineItemViewLayoutAdOpsCampaignListTabRoute,
      ]),
      adOpsCampaignRoute,
    ]),
  ]);

  return lineItemRoute;
};

const lineItemRoute = lineItemRouteFactory();

export default lineItemRoute;
