import { createSlice } from "@reduxjs/toolkit";

const initialPagination = {
  page: 1,
  nbDocsPerPage: 25,
};

// INITIAL STATE
const initialState = {
  postBoostCampaigns: initialPagination,
  posts: [],
};

const postBoostCampaignPaginationSlice = createSlice({
  name: "postBoostCampaignPagination",
  initialState,
  reducers: {
    setPage: (state, action) => {
      switch (action.payload.module) {
        case "postBoostCampaigns":
          state.postBoostCampaigns.page = action.payload.page;
          break;
        case "posts":
          state.posts = state.posts.map((post) => {
            if (post.id === action.payload.id) {
              const updatedPagination = {
                ...post.pagination,
                page: action.payload.page,
              };
              return { ...post, pagination: updatedPagination };
            } else {
              return post;
            }
          });
          break;
        
        default:
          break;
      }
    },
    setNbDocsPerPage: (state, action) => {
      switch (action.payload.module) {
        case "postBoostCampaigns":
          state.postBoostCampaigns.nbDocsPerPage = action.payload.nbDocsPerPage;
          break;
        case "posts":
          state.posts = state.posts.map((post) => {
            if (post.id === action.payload.id) {
              const updatedPagination = {
                ...post.pagination,
                nbDocsPerPage: action.payload.nbDocsPerPage,
              };
              return { ...post, pagination: updatedPagination };
            } else {
              return post;
            }
          });
          break;
        
        default:
          break;
      }
    },
    initPaginationSet: (state, action) => {
      switch (action.payload.module) {
        case "postBoostCampaigns":
          state.postBoostCampaigns = initialPagination;
          break;
        case "posts":
          if (
            !state.posts.find((post) => post.id === action.payload.id)
          ) {
            state.posts.push({
              id: action.payload.id,
              pagination: initialPagination,
            });
          }
          break;
       
        default:
          break;
      }
    },
  },
});

export const {
  setPage,
  setNbDocsPerPage,
  initPaginationSet,
} = postBoostCampaignPaginationSlice.actions;
export const postBoostCampaignPaginationReducer = postBoostCampaignPaginationSlice.reducer;
