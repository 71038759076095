import { createSlice } from "@reduxjs/toolkit";

const initialPagination = {
  page: 1,
  nbDocsPerPage: 25,
};

// INITIAL STATE
const initialState = {
  lineItems: initialPagination,
  campaigns: [],
  offers: [],
};

const lineItemPaginationSlice = createSlice({
  name: "lineItemPagination",
  initialState,
  reducers: {
    setPage: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems.page = action.payload.page;
          break;
        case "campaigns":
          state.campaigns = state.campaigns.map((campaign) => {
            if (campaign.id === action.payload.id) {
              const updatedPagination = {
                ...campaign.pagination,
                page: action.payload.page,
              };
              return { ...campaign, pagination: updatedPagination };
            } else {
              return campaign;
            }
          });
          break;
        case "offers":
          state.offers = state.offers.map((offer) => {
            if (offer.id === action.payload.id) {
              const updatedPagination = {
                ...offer.pagination,
                page: action.payload.page,
              };
              return { ...offer, pagination: updatedPagination };
            } else {
              return offer;
            }
          });
          break;

        default:
          break;
      }
    },
    setNbDocsPerPage: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems.nbDocsPerPage = action.payload.nbDocsPerPage;
          break;
        case "campaigns":
          state.campaigns = state.campaigns.map((campaign) => {
            if (campaign.id === action.payload.id) {
              const updatedPagination = {
                ...campaign.pagination,
                nbDocsPerPage: action.payload.nbDocsPerPage,
              };
              return { ...campaign, pagination: updatedPagination };
            } else {
              return campaign;
            }
          });
          break;
        case "offers":
          state.offers = state.offers.map((offer) => {
            if (offer.id === action.payload.id) {
              const updatedPagination = {
                ...offer.pagination,
                nbDocsPerPage: action.payload.nbDocsPerPage,
              };
              return { ...offer, pagination: updatedPagination };
            } else {
              return offer;
            }
          });
          break;

        default:
          break;
      }
    },
    initPaginationSet: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems = initialPagination;
          break;
        case "campaigns":
          if (
            !state.campaigns.find(
              (campaign) => campaign.id === action.payload.id
            )
          ) {
            state.campaigns.push({
              id: action.payload.id,
              pagination: initialPagination,
            });
          }
          break;
        case "offers":
          if (!state.offers.find((offer) => offer.id === action.payload.id)) {
            state.offers.push({
              id: action.payload.id,
              pagination: initialPagination,
            });
          }
          break;

        default:
          break;
      }
    },
  },
});

export const { setPage, setNbDocsPerPage, initPaginationSet } =
  lineItemPaginationSlice.actions;
export const lineItemPaginationReducer = lineItemPaginationSlice.reducer;
