import fetchServer from "../helpers/api/fetchServer";

export const createCampaign = async ({
  advertiserId,
  name,
  brandId,
  hasNoBrand,
  platformId,
  startDate,
  endDate,
  targetGender,
  targetAgeMinimum,
  targetAgeMaximum,
  targetOther,
  defaultMarginRate,
}) => {
  const method = "POST";
  const urlAPI = "/api/campaigns";
  const body = {
    advertiserId,
    name,
    brandId,
    hasNoBrand,
    platformId,
    startDate,
    endDate,
    targetGender,
    targetAgeMinimum,
    targetAgeMaximum,
    targetOther,
    defaultMarginRate,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getCampaigns = async () => {
  const method = "GET";
  const urlAPI = "/api/campaigns";
  return await fetchServer({ method, urlAPI });
};

export const getCampaignsByAdvertiser = async (advertiserId) => {
  const method = "GET";
  const urlAPI = `/api/campaigns?advertiser=${advertiserId}`;
  return await fetchServer({ method, urlAPI });
};

export const getCampaignsByBrand = async (brandId) => {
  const method = "GET";
  const urlAPI = `/api/campaigns?brand=${brandId}`;
  return await fetchServer({ method, urlAPI });
};

export const getCampaign = async (id) => {
  const method = "GET";
  const urlAPI = `/api/campaigns/${id}`;
  return await fetchServer({ method, urlAPI, source: 'CAMPAIGN' });
};

export const updateCampaign = async ({
  id,
  advertiserId,
  name,
  brandId,
  hasNoBrand,
  platformId,
  startDate,
  endDate,
  targetGender,
  targetAgeMinimum,
  targetAgeMaximum,
  targetOther,
  defaultMarginRate,
  proposalUrl,
  hasNoProposal,
  proposalClientValidation,
  endReportUrl,
  hasNoEndReport,
  endReportSent,
  invoiceCode,
  isInvoiceSent,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}`;
  const body = {
    advertiserId,
    name,
    brandId,
    hasNoBrand,
    platformId,
    startDate,
    endDate,
    targetGender,
    targetAgeMinimum,
    targetAgeMaximum,
    targetOther,
    defaultMarginRate,
    proposalUrl,
    hasNoProposal,
    proposalClientValidation,
    endReportUrl,
    hasNoEndReport,
    endReportSent,
    invoiceCode,
    isInvoiceSent,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignGeneral = async ({
  id,
  advertiserId,
  name,
  brandId,
  hasNoBrand,
  platformId,
  startDate,
  endDate,
  defaultMarginRate,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/general`;
  const body = {
    advertiserId,
    name,
    brandId,
    hasNoBrand,
    platformId,
    startDate,
    endDate,
    defaultMarginRate,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignProposal = async ({
  id,
  proposalUrl,
  hasNoProposal,
  proposalClientValidation,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/proposal`;
  const body = {
    proposalUrl,
    hasNoProposal,
    proposalClientValidation,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignTarget = async ({
  id,
  targetGender,
  targetAgeMinimum,
  targetAgeMaximum,
  targetOther,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/target`;
  const body = {
    targetGender,
    targetAgeMinimum,
    targetAgeMaximum,
    targetOther,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignEndReport = async ({
  id,
  endReportUrl,
  hasNoEndReport,
  endReportSent
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/end-report`;
  const body = {
    endReportUrl,
    hasNoEndReport,
    endReportSent
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignInvoice = async ({
  id,
  invoiceCode,
  isInvoiceSent,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/invoice`;
  const body = {
    invoiceCode,
    isInvoiceSent,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignSetup = async ({
  id,
  isAdOpsSetupDone,
  adOpsSetupComment,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/setup`;
  const body = {
    isAdOpsSetupDone,
    adOpsSetupComment,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignLiveCheck = async ({ id, isLiveChecked }) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/live-check`;
  const body = {
    isLiveChecked,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignEmailStart = async ({
  id,
  isStartCampaignEmailSent,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/email-start`;
  const body = {
    isStartCampaignEmailSent,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignEmailEnd = async ({
  id,
  isEndCampaignEmailSent,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/email-end`;
  const body = {
    isEndCampaignEmailSent,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignTargetingCheck = async ({
  id,
  isTargetingCheckDone,
  targetingCheckComment,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/targeting-check`;
  const body = {
    isTargetingCheckDone,
    targetingCheckComment,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignConfigTPCReporting = async ({
  id,
  isConfigTPCReportingDone,
  configTPCReportingComment,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/config-TPC-reporting`;
  const body = {
    isConfigTPCReportingDone,
    configTPCReportingComment,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignGoalCheckStart = async ({
  id,
  isGoalCheckStartDone,
  goalCheckStartComment,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/goal-check-start`;
  const body = {
    isGoalCheckStartDone,
    goalCheckStartComment,
  };
  return await fetchServer({ method, urlAPI, body });
};


export const updateCampaignGoalCheckMid = async ({
  id,
  isGoalCheckMidDone,
  goalCheckMidComment,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/goal-check-mid`;
  const body = {
    isGoalCheckMidDone,
    goalCheckMidComment,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCampaignFinalizeMargin = async ({
  id,
  isMarginFinalized,
}) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/finalize-margin`;
  const body = {
    isMarginFinalized,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const deleteCampaign = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/campaigns/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreCampaign = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/campaigns/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteCampaigns = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/campaigns/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreCampaigns = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/campaigns/restore-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};
