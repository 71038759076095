import {
  getAdOpsCampaignQueryOptions,
  getPaginatedAdOpsCampaignsByLineItemQueryOptions,
} from "../../queries/adOpsCampaigns";

// adOpsCampaigns are pre-fetched when a user hover a 'adOpsCampaigns' link within a lineItem
export const adOpListCampaignsByLineItemLoader = ({
  params,
  context: { queryClient },
}) => {
  const { lineItemId } = params;

  // REFACTOR
  // These temporary settings are redux slices initial values
  const filterText = "";
  const filterDeleted = false;
  const sortBy = "name-desc";
  const page = 1;
  const nbDocsPerPage = 25;

  queryClient.ensureQueryData(
    getPaginatedAdOpsCampaignsByLineItemQueryOptions({
      lineItemId,
      filterText,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// adOpsCampaign is pre-fetched when a user hover a 'view adOpsCampaign' link
export const adOpsCampaignViewLoader = ({
  params,
  context: { queryClient },
}) => {
  const { adOpsCampaignId } = params;
  queryClient.ensureQueryData(
    getAdOpsCampaignQueryOptions(adOpsCampaignId, queryClient)
  );
};
