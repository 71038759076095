import * as api from "../apis/lineItemsApi";
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

const getPaginatedLineItemsQueryOptions = ({
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "lineItems",
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedLineItems({
        filterText,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getPaginatedLineItemsByCampaignQueryOptions = ({
  campaignId,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "campaign.lineItems",
      campaignId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedLineItemsByCampaign({
        campaignId,
        filterText,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getPaginatedLineItemsByOfferQueryOptions = ({
  offerId,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "offer.lineItems",
      offerId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedLineItemsByOffer({
        offerId,
        filterText,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getLineItemQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["lineItem", id],
    queryFn: () => api.getLineItem(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // we try to find the lineItem in one of the "lineItems" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["lineItems"] })
        .forEach((query) => {
          const data = query[1]?.data;
          if (data) {
            data.forEach((lineItem) => {
              if (lineItem.id === id) {
                found = lineItem;
                queryKey = query[0];
              }
            });
          }
        });

      // otherwise, we try to find the lineItem in one of the "campaign.lineItems" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["campaign.lineItems"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((lineItem) => {
                if (lineItem.id === id) {
                  found = lineItem;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      // otherwise, we try to find the lineItem in one of the "offer.lineItems" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["offer.lineItems"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((lineItem) => {
                if (lineItem.id === id) {
                  found = lineItem;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

const getLineItemStatsByGenderQueryOptions = (lineItemId) =>
  queryOptions({
    queryKey: ["lineItem.statsByGender", lineItemId],
    queryFn: () => api.getLineItemStatsByGender(lineItemId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getLineItemStatsByAgeQueryOptions = (lineItemId) =>
  queryOptions({
    queryKey: ["lineItem.statsByAge", lineItemId],
    queryFn: () => api.getLineItemStatsByAge(lineItemId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getLineItemStatsByPlacementQueryOptions = (lineItemId) =>
  queryOptions({
    queryKey: ["lineItem.statsByPlacement", lineItemId],
    queryFn: () => api.getLineItemStatsByPlacement(lineItemId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getLineItemStatsByPlatformQueryOptions = (lineItemId) =>
  queryOptions({
    queryKey: ["lineItem.statsByPlatform", lineItemId],
    queryFn: () => api.getLineItemStatsByPlatform(lineItemId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getLineItemStatsByDevicePlatformQueryOptions = (lineItemId) =>
  queryOptions({
    queryKey: ["lineItem.statsByDevicePlatform", lineItemId],
    queryFn: () => api.getLineItemStatsByDevicePlatform(lineItemId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getLineItemStatsByDeviceQueryOptions = (lineItemId) =>
  queryOptions({
    queryKey: ["lineItem.statsByDevice", lineItemId],
    queryFn: () => api.getLineItemStatsByDevice(lineItemId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getLineItemStatsByAdQueryOptions = (lineItemId) =>
  queryOptions({
    queryKey: ["lineItem.statsByAd", lineItemId],
    queryFn: () => api.getLineItemStatsByAd(lineItemId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getLineItemStatsByAdSetQueryOptions = (lineItemId) =>
  queryOptions({
    queryKey: ["lineItem.statsByAdSet", lineItemId],
    queryFn: () => api.getLineItemStatsByAdSet(lineItemId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getLineItemStatsByDayQueryOptions = (lineItemId) =>
  queryOptions({
    queryKey: ["lineItem.statsByDay", lineItemId],
    queryFn: () => api.getLineItemStatsByDay(lineItemId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getLineItemDataCheckQueryOptions = (lineItemId) =>
  queryOptions({
    queryKey: ["lineItem.dataCheck", lineItemId],
    queryFn: () => api.getLineItemDataCheck(lineItemId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

// useQuery

export const useGetPaginatedLineItems = ({
  isInitFiltersFinished,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery({
    ...getPaginatedLineItemsQueryOptions({
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    }),
    enabled: Boolean(isInitFiltersFinished),
  });

export const useGetPaginatedLineItemsByCampaign = ({
  campaignId,
  isInitFiltersFinished,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery({
    ...getPaginatedLineItemsByCampaignQueryOptions({
      campaignId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    }),
    enabled: Boolean(isInitFiltersFinished) && Boolean(campaignId),
  });

export const useGetPaginatedLineItemsByOffer = ({
  offerId,
  isInitFiltersFinished,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery({
    ...getPaginatedLineItemsByOfferQueryOptions({
      offerId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    }),
    enabled: Boolean(isInitFiltersFinished) && Boolean(offerId),
  });

export const useGetLineItem = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getLineItemQueryOptions(id, queryClient));
};

export const useGetLineItemStatsByGender = (lineItemId) =>
  useQuery(getLineItemStatsByGenderQueryOptions(lineItemId));

export const useGetLineItemStatsByAge = (lineItemId) =>
  useQuery(getLineItemStatsByAgeQueryOptions(lineItemId));

export const useGetLineItemStatsByPlacement = (lineItemId) =>
  useQuery(getLineItemStatsByPlacementQueryOptions(lineItemId));

export const useGetLineItemStatsByPlatform = (lineItemId) =>
  useQuery(getLineItemStatsByPlatformQueryOptions(lineItemId));

export const useGetLineItemStatsByDevicePlatform = (lineItemId) =>
  useQuery(getLineItemStatsByDevicePlatformQueryOptions(lineItemId));

export const useGetLineItemStatsByDevice = (lineItemId) =>
  useQuery(getLineItemStatsByDeviceQueryOptions(lineItemId));

export const useGetLineItemStatsByAd = (lineItemId) =>
  useQuery(getLineItemStatsByAdQueryOptions(lineItemId));

export const useGetLineItemStatsByAdSet = (lineItemId) =>
  useQuery(getLineItemStatsByAdSetQueryOptions(lineItemId));

export const useGetLineItemStatsByDay = (lineItemId) =>
  useQuery(getLineItemStatsByDayQueryOptions(lineItemId));

// useSuspenseQuery

export const useSuspenseGetLineItem = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getLineItemQueryOptions(id, queryClient));
};

export const useSuspenseGetLineItemDataCheck = (lineItemId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getLineItemDataCheckQueryOptions(lineItemId, queryClient)
  );
};
