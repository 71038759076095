import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { offerRouteFactory } from "./offer";
import { specTemplateViewLoader } from "../loaders/specTemplate";
import { offerListBySpecTemplateLoader } from "../loaders/offer";
import { SpecTemplateBreadcrumb } from "../../breadcrumbs/specTemplate";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { SPECTEMPLATE, OFFER } = ENTITY;

export const specTemplateRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const specTemplateRoute = createRoute({
    getParentRoute: () => parent,
    path: SPECTEMPLATE.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: SPECTEMPLATE.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: SPECTEMPLATE.label.upFirstPlural,
    },
  });

  // List specTemplates

  const specTemplateListRoute = createRoute({
    getParentRoute: () => specTemplateRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={SPECTEMPLATE} />,
  }).lazy(() =>
    import("./specTemplate.lazy").then((d) => d.SpecTemplateListRoute)
  );

  // Create specTemplate

  const specTemplateCreateRoute = createRoute({
    getParentRoute: () => specTemplateRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: SPECTEMPLATE.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    pendingComponent: () => <EntityCreateLoading entity={SPECTEMPLATE} />,
  }).lazy(() =>
    import("./specTemplate.lazy").then((d) => d.SpecTemplateCreateRoute)
  );

  // View specTemplate

  const specTemplateViewRootRoute = createRoute({
    getParentRoute: () => specTemplateRoute,
    path: "$specTemplateId",
    staticData: {
      breadcrumb: SpecTemplateBreadcrumb,
    },
    loader: specTemplateViewLoader,
  });

  // Edit specTemplate

  const specTemplateEditRoute = createRoute({
    getParentRoute: () => specTemplateViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    pendingComponent: () => <EntityEditLoading entity={SPECTEMPLATE} />,
  }).lazy(() =>
    import("./specTemplate.lazy").then((d) => d.SpecTemplateEditRoute)
  );

  // View specTemplate Layout

  const specTemplateViewLayoutRoute = createRoute({
    getParentRoute: () => specTemplateViewRootRoute,
    id: "specTemplate-view-layout",
    pendingComponent: () => <EntityViewLoading entity={SPECTEMPLATE} />,
  }).lazy(() =>
    import("./specTemplate.lazy").then((d) => d.SpecTemplateViewLayoutRoute)
  );

  // View specTemplate - General Tab

  const specTemplateViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => specTemplateViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./specTemplate.lazy").then(
      (d) => d.SpecTemplateViewLayoutGeneralTabRoute
    )
  );

  // View specTemplate - About Tab

  const specTemplateViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => specTemplateViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./specTemplate.lazy").then(
      (d) => d.SpecTemplateViewLayoutAboutTabRoute
    )
  );

  // View specTemplate - Offers Tab

  const specTemplateViewLayoutOfferListTabRoute = createRoute({
    getParentRoute: () => specTemplateViewLayoutRoute,
    path: OFFER.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: OFFER.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: OFFER.label.upFirstPlural,
    },
    loader: offerListBySpecTemplateLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./specTemplate.lazy").then(
      (d) => d.SpecTemplateViewLayoutOfferListTabRoute
    )
  );

  // SpecTemplate Children
  const offerRoute = offerRouteFactory(specTemplateViewRootRoute);

  // Route tree
  specTemplateRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [specTemplateListRoute]),
    specTemplateCreateRoute,
    specTemplateViewRootRoute.addChildren([
      specTemplateEditRoute,
      specTemplateViewLayoutRoute.addChildren([
        specTemplateViewLayoutGeneralTabRoute,
        specTemplateViewLayoutAboutTabRoute,
        specTemplateViewLayoutOfferListTabRoute,
      ]),
      offerRoute,
    ]),
  ]);

  return specTemplateRoute;
};

const specTemplateRoute = specTemplateRouteFactory();

export default specTemplateRoute;
