import * as api from "../apis/metricsApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getMetricsQueryOptions = () =>
  queryOptions({
    queryKey: ["metrics"],
    queryFn: api.getMetrics,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getMetricsByPlatformQueryOptions = (platformId, queryClient) =>
  queryOptions({
    queryKey: ["platform.metrics", platformId],
    queryFn: () => api.getMetricsByPlatform(platformId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["metrics"])
        ?.filter((metric) => metric.platform.id === platformId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["metrics"])?.dataUpdatedAt,
  });

export const getMetricQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["metric", id],
    queryFn: () => api.getMetric(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // if "metrics" query exist, we use it to init data
      if (queryClient.getQueryState(["metrics"])) {
        queryKey = ["metrics"];
        return queryClient
          .getQueryData(["metrics"])
          ?.find((metric) => metric.id === id);
      }

      // otherwise, we try to find the metric in one of the "platform.metrics" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["platform.metrics"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((metric) => {
            if (metric.id === id) {
              found = metric;
              queryKey = query[0];
            }
          });
        });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

// useQuery

export const useGetMetricsByPlatform = (platformId) => {
  const queryClient = useQueryClient();
  return useQuery(getMetricsByPlatformQueryOptions(platformId, queryClient));
};

export const useGetMetric = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getMetricQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetMetrics = () => {
  return useSuspenseQuery(getMetricsQueryOptions());
};

export const useSuspenseGetMetric = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getMetricQueryOptions(id, queryClient));
};

export const useSuspenseGetMetricsByPlatform = (platformId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getMetricsByPlatformQueryOptions(platformId, queryClient));
};

