import {
  getMetricQueryOptions,
  getMetricsByPlatformQueryOptions,
} from "../../queries/metrics";
import { getPlatformsQueryOptions } from "../../queries/platforms";

// metrics are pre-fetched when a user hover a 'metrics' link within a platform
export const metricListByPlatformLoader = ({
  params,
  context: { queryClient },
}) => {
  const { platformId } = params;
  queryClient.ensureQueryData(getMetricsByPlatformQueryOptions(platformId, queryClient));
};

// necessary entities are pre-fetched when a user hover a 'create metric' or a 'edit metric' link
export const metricFormLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getPlatformsQueryOptions());
};

// metric is pre-fetched when a user hover a 'view metric' link
export const metricViewLoader = ({ params, context: { queryClient } }) => {
  const { metricId } = params;
  queryClient.ensureQueryData(getMetricQueryOptions(metricId, queryClient));
};
