import fetchServer from "../helpers/api/fetchServer";
import fetchServerUpdateImage from "../helpers/api/fetchServerUpdateImage";

export const createAdPlatform = async ({ name, url }) => {
  const method = "POST";
  const urlAPI = "/api/adPlatforms";
  const body = {
    name, url
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getAdPlatforms = async () => {
  const method = "GET";
  const urlAPI = "/api/adPlatforms";
  return await fetchServer({ method, urlAPI });
};

export const getAdPlatform = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adPlatforms/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updateAdPlatform = async ({ id, name, url }) => {
  const method = "PUT";
  const urlAPI = `/api/adPlatforms/${id}`;
  const body = { name, url };
  return await fetchServer({ method, urlAPI, body });
};

export const updateAdPlatformLogo = async ({ id, formData }) => {
  const urlAPI = `/api/adPlatforms/${id}/logo`;
  return await fetchServerUpdateImage({ urlAPI, formData });
};

export const deleteAdPlatformLogo = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/adPlatforms/${id}/logo`;
  return await fetchServer({ method, urlAPI });
};

export const deleteAdPlatform = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/adPlatforms/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreAdPlatform = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/adPlatforms/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteAdPlatforms = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/adPlatforms/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreAdPlatforms = async (arrayIds) => {
  const method = "POST";
  const urlAPI = '/api/adPlatforms/restore-many';
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};