import PropTypes from "prop-types";
import { List, ListSubheader } from "@material-ui/core";
import NavItem from "./NavItem";

const renderNavItems = ({ depth = 0, items, isCollapsed = false, basePath = "" }) => (
  <List disablePadding>
    {items.map((item) => {
      const key = `${item.title}-${depth}`;
      const path = `${basePath}${item.path || ''}`;

      if (item.children) {
        return (
          <NavItem
            depth={depth}
            icon={item.icon}
            info={item.info}
            key={key}
            open={false}
            path={path}
            title={item.title}
            isCollapsed={isCollapsed}
          >
            {renderNavItems({
              depth: isCollapsed ? depth : depth + 1,
              items: item.children,
              isCollapsed,
              basePath: path,
            })}
          </NavItem>
        );
      }

      return (
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={path}
          title={item.title}
          isCollapsed={isCollapsed}
        />
      );
    })}
  </List>
);

const NavSection = ({ items, title, shortTitle, isCollapsed, ...other }) => {
  return (
    <List
      subheader={
        isCollapsed ? (
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              color: "text.primary",
              fontSize: "0.75rem",
              lineHeight: 2.5,
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            {shortTitle}
          </ListSubheader>
        ) : (
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              color: "text.primary",
              fontSize: "0.75rem",
              lineHeight: 2.5,
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            {title}
          </ListSubheader>
        )
      }
      {...other}
    >
      {renderNavItems({ items, isCollapsed })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  shortTitle: PropTypes.string,
  isCollapsed: PropTypes.bool,
};

export default NavSection;