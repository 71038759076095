import * as api from "../apis/authApi";
import { ValidationException } from "../customExceptions";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { setIsInitialized, setLoggedIn, setCurrentUser } from "../slices/auth";

// Mutations

export const useInitAuth = () => {
  const auth = useSelector((state) => state.auth);
  const { mutate: mutateRefreshToken } = useRefreshToken();
  const dispatch = useDispatch();
  const { mutate: mutateLogout } = useLogout();

  return useMutation({
    mutationFn: api.initAuth,
    throwOnError: false,
    onSuccess: (result) => {
      startRefreshTokenTimer(result.authTokenExpiresIn, mutateRefreshToken);
      storeToLocalStorage(result.xsrfToken, result.user.themeSettings);
      dispatch(setLoggedIn(result.user));
    },
    onSettled: () => {
      dispatch(setIsInitialized());
    },
    onError: () => {
      if (auth.isAuthenticated) {
        mutateLogout();
      }
    },
  });
};

export const useLogin = () => {
  const { mutate: mutateRefreshToken } = useRefreshToken();
  return useMutation({
    mutationFn: api.login,
    throwOnError: (error) => !(error instanceof ValidationException),
    onSuccess: (result) => {
      startRefreshTokenTimer(result.authTokenExpiresIn, mutateRefreshToken);
      storeToLocalStorage(result.xsrfToken, result.user.themeSettings);
    },
  });
};

export const useLogout = () => {
  return useMutation({
    mutationFn: api.logout,
    throwOnError: false,
    onSettled: () => {
      stopRefreshTokenTimer();
    },
  });
};

const useRefreshToken = () => {
  const auth = useSelector((state) => state.auth);
  const { mutate: mutateLogout } = useLogout();
  const { mutate: mutateRefreshToken } = useMutation({
    mutationFn: api.refreshToken,
    throwOnError: true,
    onSuccess: (result) => {
      startRefreshTokenTimer(result.authTokenExpiresIn, mutateRefreshToken);
      storeToLocalStorage(result.xsrfToken, result.user.themeSettings);
    },
    onError: () => {
      if (auth.isAuthenticated) {
        mutateLogout();
      }
    },
  });

  return { mutate: mutateRefreshToken };
};

export const useSignup = () => {
  return useMutation({
    mutationFn: api.signup,
    throwOnError: (error) => !(error instanceof ValidationException),
  });
};

export const useVerifyEmail = () => {
  const { mutate: mutateRefreshToken } = useRefreshToken();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);

  return useMutation({
    mutationFn: api.verifyEmail,
    throwOnError: (error) => !(error instanceof ValidationException),
    onSuccess: (result) => {
      const { user, authTokenExpiresIn, xsrfToken } = result;
      startRefreshTokenTimer(authTokenExpiresIn, mutateRefreshToken);
      storeToLocalStorage(xsrfToken, user.themeSettings);
      // the user returned by the server does NOT include permissions
      // we include them "manually" because we know that the permissions have not changed
      user.permissions = currentUser.permissions;
      dispatch(setCurrentUser(user));
    },
  });
};

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: api.forgotPassword,
    throwOnError: (error) => !(error instanceof ValidationException),
  });
};

export const useResetPassword = () => {
  return useMutation({
    mutationFn: api.resetPassword,
    throwOnError: (error) => !(error instanceof ValidationException),
  });
};

// Helpers : Refresh token timer

let refreshTokenTimeout;

const startRefreshTokenTimer = (expiresIn, callback) => {
  const timeout = expiresIn - 60 * 1000;
  refreshTokenTimeout = setTimeout(() => {
    callback();
  }, timeout);
};

const stopRefreshTokenTimer = () => {
  clearTimeout(refreshTokenTimeout);
};

// Helper : store xsrfToken and theme settings to local storage

const storeToLocalStorage = (xsrfToken, themeSettings) => {
  localStorage.setItem("xsrfToken", xsrfToken);
  if (themeSettings) {
    localStorage.setItem("themeSettings", JSON.stringify(themeSettings));
  }
};
