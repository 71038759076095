import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { postRouteFactory } from "./post";
import { dataOriginRouteFactory } from "./dataOrigin";
import {
  accountFormLoader,
  accountListLoader,
  accountViewLoader,
} from "../loaders/account";
import { postListByAccountLoader } from "../loaders/post";
import { dataOriginListByAccountLoader } from "../loaders/dataOrigin";
import { AccountBreadcrumb } from "../../breadcrumbs/account";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { ACCOUNT, POST, DATAORIGIN } = ENTITY;

export const accountRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const accountRoute = createRoute({
    getParentRoute: () => parent,
    path: ACCOUNT.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ACCOUNT.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ACCOUNT.label.upFirstPlural,
    },
  });

  // List accounts

  const accountListRoute = createRoute({
    getParentRoute: () => accountRoute,
    path: "/",
    loader: accountListLoader,
    pendingComponent: () => <EntityListLoading entity={ACCOUNT} />,
  }).lazy(() => import("./account.lazy").then((d) => d.AccountListRoute));

  // Create account

  const accountCreateRoute = createRoute({
    getParentRoute: () => accountRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ACCOUNT.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    loader: accountFormLoader,
    pendingComponent: () => <EntityCreateLoading entity={ACCOUNT} />,
  }).lazy(() => import("./account.lazy").then((d) => d.AccountCreateRoute));

  // View account

  const accountViewRootRoute = createRoute({
    getParentRoute: () => accountRoute,
    path: "$accountId",
    staticData: {
      breadcrumb: AccountBreadcrumb,
    },
    loader: accountViewLoader,
  });

  // Edit account

  const accountEditRoute = createRoute({
    getParentRoute: () => accountViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    loader: accountFormLoader,
    pendingComponent: () => <EntityEditLoading entity={ACCOUNT} />,
  }).lazy(() => import("./account.lazy").then((d) => d.AccountEditRoute));

  // View account Layout

  const accountViewLayoutRoute = createRoute({
    getParentRoute: () => accountViewRootRoute,
    id: "account-view-layout",
    pendingComponent: () => <EntityViewLoading entity={ACCOUNT} />,
  }).lazy(() => import("./account.lazy").then((d) => d.AccountViewLayoutRoute));

  // View account - General Tab

  const accountViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => accountViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./account.lazy").then((d) => d.AccountViewLayoutGeneralTabRoute)
  );

  // View account - Followers Tab

  const accountViewLayoutFollowersTabRoute = createRoute({
    getParentRoute: () => accountViewLayoutRoute,
    path: "followers",
    staticData: {
      breadcrumb: "Followers",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./account.lazy").then((d) => d.AccountViewLayoutFollowersTabRoute)
  );

  // View account - Reporting Tab

  const accountViewLayoutReportingTabRoute = createRoute({
    getParentRoute: () => accountViewLayoutRoute,
    path: "reporting",
    staticData: {
      breadcrumb: "Reporting",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./account.lazy").then((d) => d.AccountViewLayoutReportingTabRoute)
  );

  // View account - Presentation Tab

  const accountViewLayoutPresentationTabRoute = createRoute({
    getParentRoute: () => accountViewLayoutRoute,
    path: "presentation",
    staticData: {
      breadcrumb: "Presentation",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./account.lazy").then(
      (d) => d.AccountViewLayoutPresentationTabRoute
    )
  );

  // View account - About Tab

  const accountViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => accountViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./account.lazy").then((d) => d.AccountViewLayoutAboutTabRoute)
  );

  // View account - Posts Tab

  const accountViewLayoutPostPaginatedListCannotDeleteTabRoute = createRoute({
    getParentRoute: () => accountViewLayoutRoute,
    path: POST.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: POST.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: POST.label.upFirstPlural,
    },
    loader: postListByAccountLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./account.lazy").then(
      (d) => d.AccountViewLayoutPostPaginatedListCannotDeleteTabRoute
    )
  );

  // View account - DataOrigins Tab

  const accountViewLayoutDataOriginListTabRoute = createRoute({
    getParentRoute: () => accountViewLayoutRoute,
    path: DATAORIGIN.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: DATAORIGIN.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: DATAORIGIN.label.upFirstPlural,
    },
    loader: dataOriginListByAccountLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./account.lazy").then(
      (d) => d.AccountViewLayoutDataOriginListTabRoute
    )
  );

  // Account Children
  const postRoute = postRouteFactory(accountViewRootRoute);
  const dataOriginRoute = dataOriginRouteFactory(accountViewRootRoute);

  // Route tree
  accountRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [accountListRoute]),
    accountCreateRoute,
    accountViewRootRoute.addChildren([
      accountEditRoute,
      accountViewLayoutRoute.addChildren([
        accountViewLayoutGeneralTabRoute,
        accountViewLayoutFollowersTabRoute,
        accountViewLayoutReportingTabRoute,
        accountViewLayoutPresentationTabRoute,
        accountViewLayoutAboutTabRoute,
        accountViewLayoutPostPaginatedListCannotDeleteTabRoute,
        accountViewLayoutDataOriginListTabRoute,
      ]),
      postRoute,
      dataOriginRoute,
    ]),
  ]);

  return accountRoute;
};

const accountRoute = accountRouteFactory();

export default accountRoute;
