import {
  getBrandQueryOptions,
  getBrandsByAdvertiserQueryOptions,
} from "../../queries/brands";
import { getAdvertisersQueryOptions } from "../../queries/advertisers";

// necessary entities are pre-fetched when a user hover a 'create brand' or a 'edit brand' link
export const brandFormLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getAdvertisersQueryOptions());
};

// brands are pre-fetched when a user hover a 'brands' link within a advertiser
export const brandListByAdvertiserLoader = ({
  params,
  context: { queryClient },
}) => {
  const { advertiserId } = params;
  queryClient.ensureQueryData(
    getBrandsByAdvertiserQueryOptions(advertiserId, queryClient)
  );
};

// brand is pre-fetched when a user hover a 'view brand' link
export const brandViewLoader = ({ params, context: { queryClient } }) => {
  const { brandId } = params;
  queryClient.ensureQueryData(getBrandQueryOptions(brandId, queryClient));
};
