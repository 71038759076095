import React, { useMemo } from "react";
import { Link as RouterLink, useMatches } from "@tanstack/react-router";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import ChevronRightIcon from "../../../icons/ChevronRight";

const AppBreadcrumbs = () => {
  const matches = useMatches();

  // useMemo is not really useful at the moment
  // It guarantees that breadcrumbs are re-rendered only if the route has changed (ie. matches has changed)
  const breadcrumbs = useMemo(
    () =>
      matches
        // filter out the routes with no breadcrumbs defined (example : layout routes will be filtered out)
        .filter((match) => match.staticData?.breadcrumb)
        .map((match) => {
          let breadcrumb = match.staticData?.breadcrumb;
          // The breadcrumb can be a text or a React Node 
          if (typeof breadcrumb === "function") {
            const BreadcrumbItem = match.staticData?.breadcrumb;
            breadcrumb = <BreadcrumbItem match={match} />;
          }
          return {
            breadcrumb: breadcrumb,
            pathname: match.pathname,
          };
        }),
    [matches]
  );

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<ChevronRightIcon fontSize="small" sx={{ mt: 0.4 }} />}
      sx={{ mt: 1 }}
    >
      {breadcrumbs.map(({ breadcrumb, pathname }, index) => {
        const isLast = index === breadcrumbs.length - 1;

        return isLast ? (
          <Typography
            key={index}
            color="textSecondary"
            variant="subtitle2"
            sx={{ pt: 0.4 }}
          >
            {breadcrumb}
          </Typography>
        ) : (
          <Link
            key={index}
            color="textPrimary"
            component={RouterLink}
            to={pathname}
            variant="subtitle2"
            underline="hover"
          >
            {breadcrumb}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
