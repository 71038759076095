import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { offerRouteFactory } from "./offer";
import { adFormatViewLoader } from "../loaders/adFormat";
import { offerListByAdFormatLoader } from "../loaders/offer";
import { AdFormatBreadcrumb } from "../../breadcrumbs/adFormat";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { ADFORMAT, OFFER } = ENTITY;

export const adFormatRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const adFormatRoute = createRoute({
    getParentRoute: () => parent,
    path: ADFORMAT.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ADFORMAT.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ADFORMAT.label.upFirstPlural,
    },
  });

  // List adFormats

  const adFormatListRoute = createRoute({
    getParentRoute: () => adFormatRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={ADFORMAT} />,
  }).lazy(() => import("./adFormat.lazy").then((d) => d.AdFormatListRoute));

  // Create adFormat

  const adFormatCreateRoute = createRoute({
    getParentRoute: () => adFormatRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ADFORMAT.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    pendingComponent: () => <EntityCreateLoading entity={ADFORMAT} />,
  }).lazy(() => import("./adFormat.lazy").then((d) => d.AdFormatCreateRoute));

  // View adFormat

  const adFormatViewRootRoute = createRoute({
    getParentRoute: () => adFormatRoute,
    path: "$adFormatId",
    staticData: {
      breadcrumb: AdFormatBreadcrumb,
    },
    loader: adFormatViewLoader,
  });

  // Edit adFormat

  const adFormatEditRoute = createRoute({
    getParentRoute: () => adFormatViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    pendingComponent: () => <EntityEditLoading entity={ADFORMAT} />,
  }).lazy(() => import("./adFormat.lazy").then((d) => d.AdFormatEditRoute));

  // View adFormat Layout

  const adFormatViewLayoutRoute = createRoute({
    getParentRoute: () => adFormatViewRootRoute,
    id: "adFormat-view-layout",
    pendingComponent: () => <EntityViewLoading entity={ADFORMAT} />,
  }).lazy(() =>
    import("./adFormat.lazy").then((d) => d.AdFormatViewLayoutRoute)
  );

  // View adFormat - General Tab

  const adFormatViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => adFormatViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adFormat.lazy").then((d) => d.AdFormatViewLayoutGeneralTabRoute)
  );

  // View adFormat - About Tab

  const adFormatViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => adFormatViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adFormat.lazy").then((d) => d.AdFormatViewLayoutAboutTabRoute)
  );

  // View adFormat - Offers Tab

  const adFormatViewLayoutOfferListTabRoute = createRoute({
    getParentRoute: () => adFormatViewLayoutRoute,
    path: OFFER.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: OFFER.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: OFFER.label.upFirstPlural,
    },
    loader: offerListByAdFormatLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adFormat.lazy").then((d) => d.AdFormatViewLayoutOfferListTabRoute)
  );

  // AdFormat Children
  const offerRoute = offerRouteFactory(adFormatViewRootRoute);

  // Route tree
  adFormatRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [adFormatListRoute]),
    adFormatCreateRoute,
    adFormatViewRootRoute.addChildren([
      adFormatEditRoute,
      adFormatViewLayoutRoute.addChildren([
        adFormatViewLayoutGeneralTabRoute,
        adFormatViewLayoutAboutTabRoute,
        adFormatViewLayoutOfferListTabRoute,
      ]),
      offerRoute,
    ]),
  ]);

  return adFormatRoute;
};

const adFormatRoute = adFormatRouteFactory();

export default adFormatRoute;
