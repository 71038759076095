import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "name-asc"
};

// INITIAL STATE
const initialState = {
  adOpsObjectives: initialFilters,
};

const adOpsObjectiveFiltersSlice = createSlice({
  name: "adOpsObjectiveFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      state.adOpsObjectives.tab = action.payload.tab;
    },
    setTextFilter: (state, action) => {
      state.adOpsObjectives.text = action.payload.text;
    },
    sortByNameAsc: (state, action) => {
      state.adOpsObjectives.sortBy = "name-asc";
    },
    sortByNameDesc: (state, action) => {
      state.adOpsObjectives.sortBy = "name-desc";
    }
  },
});

export const { setTabFilter, setTextFilter, sortByNameAsc, sortByNameDesc } = adOpsObjectiveFiltersSlice.actions;
export const adOpsObjectiveFiltersReducer = adOpsObjectiveFiltersSlice.reducer;
