import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "name-asc",
};

// INITIAL STATE
const initialState = {
  brands: initialFilters,
  advertisers: [],
};

const brandFiltersSlice = createSlice({
  name: "brandFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "brands":
          state.brands.tab = action.payload.tab;
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                tab: action.payload.tab,
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;

        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "brands":
          state.brands.text = action.payload.text;
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                text: action.payload.text,
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
       
        default:
          break;
      }
    },
    sortByNameAsc: (state, action) => {
      switch (action.payload.module) {
        case "brands":
          state.brands.sortBy = "name-asc";
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                sortBy: "name-asc",
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        

        default:
          break;
      }
    },
    sortByNameDesc: (state, action) => {
      switch (action.payload.module) {
        case "brands":
          state.brands.sortBy = "name-desc";
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                sortBy: "name-desc",
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;

        default:
          break;
      }
    },
    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "brands":
          state.brands = initialFilters;
          break;
        case "advertisers":
          if (
            !state.advertisers.find(
              (advertiser) => advertiser.id === action.payload.id
            )
          ) {
            state.advertisers.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        
        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  sortByNameAsc,
  sortByNameDesc,
  initFilterSet,
} = brandFiltersSlice.actions;

export const brandFiltersReducer = brandFiltersSlice.reducer;
