import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetCampaign } from "../queries/campaigns";
import { ENTITY } from "../constants";

export const CampaignBreadcrumb = ({ match }) => {
  // Entity
  const { CAMPAIGN } = ENTITY;


    const {
      data: campaign,
      isLoading,
      isError,
      error,
    } = useGetCampaign(match.params.campaignId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (campaign) {
      return <span>{trimString(campaign[CAMPAIGN.nameField], 20)}</span>;
    }
  
    return null;
  };