import fetchServer from "../helpers/api/fetchServer";

export const getPaginatedETLRuns = async ({
  filterText,
  filterStartDate,
  filterEndDate,
  filterWithErrors,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = "/api/ETLRuns?";
  // Filters
  if (filterText) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `text=${filterText}`;
  }
  if (filterStartDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${filterStartDate}`;
  }
  if (filterEndDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${filterEndDate}`;
  }
  if (filterWithErrors) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `withErrors=${filterWithErrors}`;
  }
  // Sort by
  if (urlAPI.slice(-1) !== "?") {
    urlAPI += "&";
  }

  urlAPI += `sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getETLRun = async (id) => {
  const method = "GET";
  const urlAPI = `/api/ETLRuns/${id}`;
  return await fetchServer({ method, urlAPI });
};
