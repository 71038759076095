import { createRoute } from "@tanstack/react-router";
import { websiteRoute } from ".";

// Routes

export const loginRoute = createRoute({
  getParentRoute: () => websiteRoute,
  path: "authentication/login",
}).lazy(() => import("./auth.lazy").then((d) => d.LoginRoute));

export const signupRoute = createRoute({
  getParentRoute: () => websiteRoute,
  path: "authentication/signup",
}).lazy(() => import("./auth.lazy").then((d) => d.SignUpRoute));

export const verifyEmailRoute = createRoute({
  getParentRoute: () => websiteRoute,
  path: "authentication/verify-email",
}).lazy(() => import("./auth.lazy").then((d) => d.VerifyEmailRoute));

export const forgotPasswordRoute = createRoute({
  getParentRoute: () => websiteRoute,
  path: "authentication/forgot-password",
}).lazy(() => import("./auth.lazy").then((d) => d.ForgotPasswordRoute));

export const resetPasswordRoute = createRoute({
  getParentRoute: () => websiteRoute,
  path: "authentication/reset-password",
}).lazy(() => import("./auth.lazy").then((d) => d.ResetPasswordRoute));
