import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { adAccountViewLoader, adAccountFormLoader } from "../loaders/adAccount";
import { AdAccountBreadcrumb } from "../../breadcrumbs/adAccount";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { ADACCOUNT } = ENTITY;

export const adAccountRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const adAccountRoute = createRoute({
    getParentRoute: () => parent,
    path: ADACCOUNT.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ADACCOUNT.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ADACCOUNT.label.upFirstPlural,
    },
  });

  // List adAccounts

  const adAccountListRoute = createRoute({
    getParentRoute: () => adAccountRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={ADACCOUNT} />,
  }).lazy(() => import("./adAccount.lazy").then((d) => d.AdAccountListRoute));

  // View adAccount

  const adAccountViewRootRoute = createRoute({
    getParentRoute: () => adAccountRoute,
    path: "$adAccountId",
    staticData: {
      breadcrumb: AdAccountBreadcrumb,
    },
    loader: adAccountViewLoader,
  });

  // Edit adAccount

  const adAccountEditRoute = createRoute({
    getParentRoute: () => adAccountViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    loader: adAccountFormLoader,
    pendingComponent: () => <EntityEditLoading entity={ADACCOUNT} />,
  }).lazy(() => import("./adAccount.lazy").then((d) => d.AdAccountEditRoute));

  // View adAccount Layout

  const adAccountViewLayoutRoute = createRoute({
    getParentRoute: () => adAccountViewRootRoute,
    id: "adAccount-view-layout",
    pendingComponent: () => <EntityViewLoading entity={ADACCOUNT} />,
  }).lazy(() =>
    import("./adAccount.lazy").then((d) => d.AdAccountViewLayoutRoute)
  );

  // View adAccount - General Tab

  const adAccountViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => adAccountViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adAccount.lazy").then(
      (d) => d.AdAccountViewLayoutGeneralTabRoute
    )
  );

  // View adAccount - About Tab

  const adAccountViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => adAccountViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adAccount.lazy").then((d) => d.AdAccountViewLayoutAboutTabRoute)
  );

  // Route tree
  adAccountRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [adAccountListRoute]),
    adAccountViewRootRoute.addChildren([
      adAccountEditRoute,
      adAccountViewLayoutRoute.addChildren([
        adAccountViewLayoutGeneralTabRoute,
        adAccountViewLayoutAboutTabRoute,
      ]),
    ]),
  ]);

  return adAccountRoute;
};

const adAccountRoute = adAccountRouteFactory();

export default adAccountRoute;
