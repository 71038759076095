import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { accountRouteFactory } from "./account";
import { problemTypeRouteFactory } from "./problemType";
import { metricRouteFactory } from "./metric";
import { offerRouteFactory } from "./offer";
import {
  platformFormLoader,
  platformListLoader,
  platformViewLoader,
} from "../loaders/platform";
import { problemTypeListByPlatformLoader } from "../loaders/problemType";
import { accountListByPlatformLoader } from "../loaders/account";
import { offerListByPlatformLoader } from "../loaders/offer";
import { metricListByPlatformLoader } from "../loaders/metric";
import { PlatformBreadcrumb } from "../../breadcrumbs/platform";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { PLATFORM, ACCOUNT, PROBLEMTYPE, METRIC, OFFER } = ENTITY;

export const platformRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const platformRoute = createRoute({
    getParentRoute: () => parent,
    path: PLATFORM.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: PLATFORM.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: PLATFORM.label.upFirstPlural,
    },
  });

  // List platforms

  const platformListRoute = createRoute({
    getParentRoute: () => platformRoute,
    path: "/",
    loader: platformListLoader,
    pendingComponent: () => <EntityListLoading entity={PLATFORM} />,
  }).lazy(() => import("./platform.lazy").then((d) => d.PlatformListRoute));

  // Create platform

  const platformCreateRoute = createRoute({
    getParentRoute: () => platformRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: PLATFORM.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    loader: platformFormLoader,
    pendingComponent: () => <EntityEditLoading entity={PLATFORM} />,
  }).lazy(() => import("./platform.lazy").then((d) => d.PlatformCreateRoute));

  // View platform

  const platformViewRootRoute = createRoute({
    getParentRoute: () => platformRoute,
    path: "$platformId",
    staticData: {
      breadcrumb: PlatformBreadcrumb,
    },
    loader: platformViewLoader,
  });

  // Edit platform

  const platformEditRoute = createRoute({
    getParentRoute: () => platformViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    loader: platformFormLoader,
    pendingComponent: () => <EntityEditLoading entity={PLATFORM} />,
  }).lazy(() => import("./platform.lazy").then((d) => d.PlatformEditRoute));

  // View platform Layout

  const platformViewLayoutRoute = createRoute({
    getParentRoute: () => platformViewRootRoute,
    id: "platform-view-layout",
    pendingComponent: () => <EntityViewLoading entity={PLATFORM} />,
  }).lazy(() =>
    import("./platform.lazy").then((d) => d.PlatformViewLayoutRoute)
  );

  // View platform - General Tab

  const platformViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => platformViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./platform.lazy").then((d) => d.PlatformViewLayoutGeneralTabRoute)
  );

  // View platform - About Tab

  const platformViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => platformViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./platform.lazy").then((d) => d.PlatformViewLayoutAboutTabRoute)
  );

  // View platform - Accounts Tab

  const platformViewLayoutAccountListTabRoute = createRoute({
    getParentRoute: () => platformViewLayoutRoute,
    path: ACCOUNT.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ACCOUNT.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ACCOUNT.label.upFirstPlural,
    },
    loader: accountListByPlatformLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./platform.lazy").then(
      (d) => d.PlatformViewLayoutAccountListTabRoute
    )
  );

  // View platform - ProblemTypes Tab

  const platformViewLayoutProblemTypeListTabRoute = createRoute({
    getParentRoute: () => platformViewLayoutRoute,
    path: PROBLEMTYPE.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: PROBLEMTYPE.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: PROBLEMTYPE.label.upFirstPlural,
    },
    loader: problemTypeListByPlatformLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./platform.lazy").then(
      (d) => d.PlatformViewLayoutProblemTypeListTabRoute
    )
  );

  // View platform - Metrics Tab

  const platformViewLayoutMetricListTabRoute = createRoute({
    getParentRoute: () => platformViewLayoutRoute,
    path: METRIC.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: METRIC.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: METRIC.label.upFirstPlural,
    },
    loader: metricListByPlatformLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./platform.lazy").then(
      (d) => d.PlatformViewLayoutMetricListTabRoute
    )
  );

  // View platform - Offers Tab

  const platformViewLayoutOfferListTabRoute = createRoute({
    getParentRoute: () => platformViewLayoutRoute,
    path: OFFER.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: OFFER.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: OFFER.label.upFirstPlural,
    },
    loader: offerListByPlatformLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./platform.lazy").then((d) => d.PlatformViewLayoutOfferListTabRoute)
  );

  // Platform Children
  const accountRoute = accountRouteFactory(platformViewRootRoute);
  const problemTypeRoute = problemTypeRouteFactory(platformViewRootRoute);
  const metricRoute = metricRouteFactory(platformViewRootRoute);
  const offerRoute = offerRouteFactory(platformViewRootRoute);

  // Route tree
  platformRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [platformListRoute]),
    platformCreateRoute,
    platformViewRootRoute.addChildren([
      platformEditRoute,
      platformViewLayoutRoute.addChildren([
        platformViewLayoutGeneralTabRoute,
        platformViewLayoutAboutTabRoute,
        platformViewLayoutAccountListTabRoute,
        platformViewLayoutProblemTypeListTabRoute,
        platformViewLayoutMetricListTabRoute,
        platformViewLayoutOfferListTabRoute,
      ]),
      accountRoute,
      problemTypeRoute,
      metricRoute,
      offerRoute,
    ]),
  ]);

  return platformRoute;
};

const platformRoute = platformRouteFactory();

export default platformRoute;
