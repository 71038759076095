import * as api from "../apis/ETLTasksApi";
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getPaginatedETLTasksQueryOptions = ({
  filterText,
  filterStartDate,
  filterEndDate,
  filterWithErrors,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "ETLTasks",
      filterText,
      filterStartDate,
      filterEndDate,
      filterWithErrors,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedETLTasks({
        filterText,
        filterStartDate,
        filterEndDate,
        filterWithErrors,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getPaginatedETLTasksByETLRunQueryOptions = ({
  ETLRunId,
  filterText,
  filterStartDate,
  filterEndDate,
  filterWithErrors,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "ETLRun.ETLTasks",
      ETLRunId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterWithErrors,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedETLTasksByETLRun({
        ETLRunId,
        filterText,
        filterStartDate,
        filterEndDate,
        filterWithErrors,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getETLTaskQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["ETLTask", id],
    queryFn: () => api.getETLTask(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // we try to find the ETLTask in one of the "ETLTasks" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["ETLTasks"] })
        .forEach((query) => {
          const data = query[1]?.data;
          if (data) {
            data.forEach((ETLTask) => {
              if (ETLTask.id === id) {
                found = ETLTask;
                queryKey = query[0];
              }
            });
          }
        });

      // otherwise, we try to find the ETLTask in one of the "ETLRun.ETLTasks" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["ETLRun.ETLTasks"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((ETLTask) => {
                if (ETLTask.id === id) {
                  found = ETLTask;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

// useQuery

export const useGetPaginatedETLTasks = ({
  isInitFiltersFinished,
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery({
    ...getPaginatedETLTasksQueryOptions({
      filterText,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    }),
    enabled: Boolean(isInitFiltersFinished),
  });

export const useGetPaginatedETLTasksByETLRun = ({
  ETLRunId,
  isInitFiltersFinished,
  filterText,
  filterStartDate,
  filterEndDate,
  filterWithErrors,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery({
    ...getPaginatedETLTasksByETLRunQueryOptions({
      ETLRunId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterWithErrors,
      sortBy,
      page,
      nbDocsPerPage,
    }),
    enabled: Boolean(isInitFiltersFinished) && Boolean(ETLRunId),
  });

export const useGetETLTask = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getETLTaskQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetETLTask = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getETLTaskQueryOptions(id, queryClient));
};
