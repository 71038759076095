import { createRoute } from "@tanstack/react-router";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { appRoute } from "./index";
import { adOpsCampaignViewLoader } from "../loaders/adOpsCampaign";
import { AdOpsCampaignBreadcrumb } from "../../breadcrumbs/adOpsCampaign";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";

const { ADOPSCAMPAIGN } = ENTITY;

export const adOpsCampaignRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const adOpsCampaignRoute = createRoute({
    getParentRoute: () => parent,
    path: ADOPSCAMPAIGN.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ADOPSCAMPAIGN.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ADOPSCAMPAIGN.label.upFirstPlural,
    },
  });

  // List adOpsCampaigns

  const adOpsCampaignListRoute = createRoute({
    getParentRoute: () => adOpsCampaignRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={ADOPSCAMPAIGN} />,
  }).lazy(() =>
    import("./adOpsCampaign.lazy").then((d) => d.AdOpsCampaignListRoute)
  );

  // View adOpsCampaign

  const adOpsCampaignViewRootRoute = createRoute({
    getParentRoute: () => adOpsCampaignRoute,
    path: "$adOpsCampaignId",
    staticData: {
      breadcrumb: AdOpsCampaignBreadcrumb,
    },
    loader: adOpsCampaignViewLoader,
  });

  // View adOpsCampaign Layout

  const adOpsCampaignViewLayoutRoute = createRoute({
    getParentRoute: () => adOpsCampaignViewRootRoute,
    id: "adOpsCampaign-view-layout",
    pendingComponent: () => <EntityViewLoading entity={ADOPSCAMPAIGN} />,
  }).lazy(() =>
    import("./adOpsCampaign.lazy").then((d) => d.AdOpsCampaignViewLayoutRoute)
  );

  // View adOpsCampaign - General Tab

  const adOpsCampaignViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => adOpsCampaignViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adOpsCampaign.lazy").then(
      (d) => d.AdOpsCampaignViewLayoutGeneralTabRoute
    )
  );

  // View adOpsCampaign - Performance Tab

  const adOpsCampaignViewLayoutPerformanceTabRoute = createRoute({
    getParentRoute: () => adOpsCampaignViewLayoutRoute,
    path: "performance",
    staticData: {
      breadcrumb: "Performance",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adOpsCampaign.lazy").then(
      (d) => d.AdOpsCampaignViewLayoutPerformanceTabRoute
    )
  );

  // View adOpsCampaign - Data check Tab

  const adOpsCampaignViewLayoutDataCheckTabRoute = createRoute({
    getParentRoute: () => adOpsCampaignViewLayoutRoute,
    path: "dataCheck",
    staticData: {
      breadcrumb: "Data check",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adOpsCampaign.lazy").then(
      (d) => d.AdOpsCampaignViewLayoutDataCheckTabRoute
    )
  );

  // View adOpsCampaign - About Tab

  const adOpsCampaignViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => adOpsCampaignViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adOpsCampaign.lazy").then(
      (d) => d.AdOpsCampaignViewLayoutAboutTabRoute
    )
  );

  // Route tree
  adOpsCampaignRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [adOpsCampaignListRoute]),
    adOpsCampaignViewRootRoute.addChildren([
      adOpsCampaignViewLayoutRoute.addChildren([
        adOpsCampaignViewLayoutGeneralTabRoute,
        adOpsCampaignViewLayoutPerformanceTabRoute,
        adOpsCampaignViewLayoutDataCheckTabRoute,
        adOpsCampaignViewLayoutAboutTabRoute,
      ]),
    ]),
  ]);

  return adOpsCampaignRoute;
};

const adOpsCampaignRoute = adOpsCampaignRouteFactory();

export default adOpsCampaignRoute;
