import * as api from "../apis/adFormatsApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getAdFormatsQueryOptions = () =>
  queryOptions({
    queryKey: ["adFormats"],
    queryFn: api.getAdFormats,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getAdFormatQueryOptions = (id, queryClient) =>
  queryOptions({
    queryKey: ["adFormat", id],
    queryFn: () => api.getAdFormat(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["adFormats"])
        ?.find((adFormat) => adFormat.id === id),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["adFormats"])?.dataUpdatedAt,
  });

// useQuery

export const useGetAdFormats = () => useQuery(getAdFormatsQueryOptions());

export const useGetAdFormat = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getAdFormatQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetAdFormats = () =>
  useSuspenseQuery(getAdFormatsQueryOptions());

export const useSuspenseGetAdFormat = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getAdFormatQueryOptions(id, queryClient));
};
