import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialSettings = {
  startDate: moment().startOf("month").valueOf(),
  endDate: moment().endOf("month").valueOf(),
  activeSlide: 1,
  visibleFilters: true,
};

// INITIAL STATE
const initialState = {
  accounts: [],
};


const accountPresentationSettingsSlice = createSlice({
  name: "accountPresentationSettings",
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.accounts = state.accounts.map((account) => {
        if (account.id === action.payload.id) {
          const updatedSettings = {
            ...account.presentationSettings,
            startDate: action.payload.startDate,
          };
          return { ...account, presentationSettings: updatedSettings };
        } else {
          return account;
        }
      });
    },
    setEndDate: (state, action) => {
      state.accounts = state.accounts.map((account) => {
        if (account.id === action.payload.id) {
          const updatedSettings = {
            ...account.presentationSettings,
            endDate: action.payload.endDate,
          };
          return { ...account, presentationSettings: updatedSettings };
        } else {
          return account;
        }
      });
    },
    setActiveSlide: (state, action) => {
      state.accounts = state.accounts.map((account) => {
        if (account.id === action.payload.id) {
          const updatedSettings = {
            ...account.presentationSettings,
            activeSlide: action.payload.activeSlide,
          };
          return { ...account, presentationSettings: updatedSettings };
        } else {
          return account;
        }
      });
    },
    setVisibleFilters: (state, action) => {
      state.accounts = state.accounts.map((account) => {
        if (account.id === action.payload.id) {
          const updatedSettings = {
            ...account.presentationSettings,
            visibleFilters: action.payload.visibleFilters,
          };
          return { ...account, presentationSettings: updatedSettings };
        } else {
          return account;
        }
      });
    },
    initPresentationSettingsSet: (state, action) => {
      if (!state.accounts.find((account) => account.id === action.payload.id)) {
        state.accounts.push({
          id: action.payload.id,
          presentationSettings: initialSettings,
        });
      }
    },
  },
});

export const { setStartDate, setEndDate, setActiveSlide, setVisibleFilters, initPresentationSettingsSet } =
  accountPresentationSettingsSlice.actions;
export const accountPresentationSettingsReducer =
  accountPresentationSettingsSlice.reducer;
