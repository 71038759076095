import { createSlice } from "@reduxjs/toolkit";

// INITIAL STATE
const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "name-asc",
};

// INITIAL STATE
const initialState = {
  users: initialFilters,
};

const userFiltersSlice = createSlice({
  name: "userFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      state.users.tab = action.payload.tab;
    },
    setTextFilter: (state, action) => {
      state.users.text = action.payload.text;
    },
    sortByNameAsc: (state, action) => {
      state.users.sortBy = "name-asc";
    },
    sortByNameDesc: (state, action) => {
      state.users.sortBy = "name-desc";
    }
  },
});

export const { setTabFilter, setTextFilter, sortByNameAsc, sortByNameDesc } = userFiltersSlice.actions;
export const userFiltersReducer = userFiltersSlice.reducer;
