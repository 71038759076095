import {
  getProblemTypeQueryOptions,
  getProblemTypesByPlatformQueryOptions,
} from "../../queries/problemTypes";
import { getPlatformsQueryOptions } from "../../queries/platforms";

// necessary entities are pre-fetched when a user hover a 'create problemType' or a 'edit problemType' link
export const problemTypeFormLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getPlatformsQueryOptions());
};

// problemTypes are pre-fetched when a user hover a 'problemTypes' link within a platform
export const problemTypeListByPlatformLoader = ({
  params,
  context: { queryClient },
}) => {
  const { platformId } = params;
  queryClient.ensureQueryData(
    getProblemTypesByPlatformQueryOptions(platformId, queryClient)
  );
};

// problemType is pre-fetched when a user hover a 'view problemType' link
export const problemTypeViewLoader = ({ params, context: { queryClient } }) => {
  const { problemTypeId } = params;
  queryClient.ensureQueryData(
    getProblemTypeQueryOptions(problemTypeId, queryClient)
  );
};
