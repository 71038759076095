import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import {
  dataOriginViewLoader,
  dataOriginFormLoader,
} from "../loaders/dataOrigin";
import { DataOriginBreadcrumb } from "../../breadcrumbs/dataOrigin";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { DATAORIGIN } = ENTITY;

export const dataOriginRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const dataOriginRoute = createRoute({
    getParentRoute: () => parent,
    path: DATAORIGIN.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: DATAORIGIN.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: DATAORIGIN.label.upFirstPlural,
    },
  });

  // List dataOrigins

  const dataOriginListRoute = createRoute({
    getParentRoute: () => dataOriginRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={DATAORIGIN} />,
  }).lazy(() => import("./dataOrigin.lazy").then((d) => d.DataOriginListRoute));

  // View dataOrigin

  const dataOriginViewRootRoute = createRoute({
    getParentRoute: () => dataOriginRoute,
    path: "$dataOriginId",
    staticData: {
      breadcrumb: DataOriginBreadcrumb,
    },
    loader: dataOriginViewLoader,
  });

  // Edit dataOrigin

  const dataOriginEditRoute = createRoute({
    getParentRoute: () => dataOriginViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    loader: dataOriginFormLoader,
    pendingComponent: () => <EntityEditLoading entity={DATAORIGIN} />,
  }).lazy(() => import("./dataOrigin.lazy").then((d) => d.DataOriginEditRoute));

  // View dataOrigin Layout

  const dataOriginViewLayoutRoute = createRoute({
    getParentRoute: () => dataOriginViewRootRoute,
    id: "dataOrigin-view-layout",
    pendingComponent: () => <EntityViewLoading entity={DATAORIGIN} />,
  }).lazy(() =>
    import("./dataOrigin.lazy").then((d) => d.DataOriginViewLayoutRoute)
  );

  // View dataOrigin - General Tab

  const dataOriginViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => dataOriginViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./dataOrigin.lazy").then(
      (d) => d.DataOriginViewLayoutGeneralTabRoute
    )
  );

  // View dataOrigin - About Tab

  const dataOriginViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => dataOriginViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./dataOrigin.lazy").then((d) => d.DataOriginViewLayoutAboutTabRoute)
  );

  // Route tree
  dataOriginRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [dataOriginListRoute]),
    dataOriginViewRootRoute.addChildren([
      dataOriginEditRoute,
      dataOriginViewLayoutRoute.addChildren([
        dataOriginViewLayoutGeneralTabRoute,
        dataOriginViewLayoutAboutTabRoute,
      ]),
    ]),
  ]);

  return dataOriginRoute;
};

const dataOriginRoute = dataOriginRouteFactory();

export default dataOriginRoute;
