import { createRoute } from "@tanstack/react-router";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { appRoute } from "./index";
import { postBoostCampaignViewLoader } from "../loaders/postBoostCampaign";
import { PostBoostCampaignBreadcrumb } from "../../breadcrumbs/postBoostCampaign";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";

const { POSTBOOSTCAMPAIGN } = ENTITY;

export const postBoostCampaignRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const postBoostCampaignRoute = createRoute({
    getParentRoute: () => parent,
    path: POSTBOOSTCAMPAIGN.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: POSTBOOSTCAMPAIGN.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: POSTBOOSTCAMPAIGN.label.upFirstPlural,
    },
  });

  // List postBoostCampaigns

  const postBoostCampaignListRoute = createRoute({
    getParentRoute: () => postBoostCampaignRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={POSTBOOSTCAMPAIGN} />,
  }).lazy(() =>
    import("./postBoostCampaign.lazy").then((d) => d.PostBoostCampaignListRoute)
  );

  // View postBoostCampaign

  const postBoostCampaignViewRootRoute = createRoute({
    getParentRoute: () => postBoostCampaignRoute,
    path: "$postBoostCampaignId",
    staticData: {
      breadcrumb: PostBoostCampaignBreadcrumb,
    },
    loader: postBoostCampaignViewLoader,
  });

  // View postBoostCampaign Layout

  const postBoostCampaignViewLayoutRoute = createRoute({
    getParentRoute: () => postBoostCampaignViewRootRoute,
    id: "postBoostCampaign-view-layout",
    pendingComponent: () => <EntityViewLoading entity={POSTBOOSTCAMPAIGN} />,
  }).lazy(() =>
    import("./postBoostCampaign.lazy").then(
      (d) => d.PostBoostCampaignViewLayoutRoute
    )
  );

  // View postBoostCampaign - General Tab

  const postBoostCampaignViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => postBoostCampaignViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./postBoostCampaign.lazy").then(
      (d) => d.PostBoostCampaignViewLayoutGeneralTabRoute
    )
  );

  // View postBoostCampaign - Performance Tab

  const postBoostCampaignViewLayoutPerformanceTabRoute = createRoute({
    getParentRoute: () => postBoostCampaignViewLayoutRoute,
    path: "performance",
    staticData: {
      breadcrumb: "Performance",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./postBoostCampaign.lazy").then(
      (d) => d.PostBoostCampaignViewLayoutPerformanceTabRoute
    )
  );

  // View postBoostCampaign - About Tab

  const postBoostCampaignViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => postBoostCampaignViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./postBoostCampaign.lazy").then(
      (d) => d.PostBoostCampaignViewLayoutAboutTabRoute
    )
  );

  // Route tree
  postBoostCampaignRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [postBoostCampaignListRoute]),
    postBoostCampaignViewRootRoute.addChildren([
      postBoostCampaignViewLayoutRoute.addChildren([
        postBoostCampaignViewLayoutGeneralTabRoute,
        postBoostCampaignViewLayoutPerformanceTabRoute,
        postBoostCampaignViewLayoutAboutTabRoute,
      ]),
    ]),
  ]);

  return postBoostCampaignRoute;
};

const postBoostCampaignRoute = postBoostCampaignRouteFactory();

export default postBoostCampaignRoute;
