import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { offerRouteFactory } from "./offer";
import { countryViewLoader } from "../loaders/country";
import { offerListByCountryLoader } from "../loaders/offer";
import { CountryBreadcrumb } from "../../breadcrumbs/country";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { COUNTRY, OFFER } = ENTITY;

export const countryRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const countryRoute = createRoute({
    getParentRoute: () => parent,
    path: COUNTRY.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: COUNTRY.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: COUNTRY.label.upFirstPlural,
    },
  });

  // List countries

  const countryListRoute = createRoute({
    getParentRoute: () => countryRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={COUNTRY} />,
  }).lazy(() => import("./country.lazy").then((d) => d.CountryListRoute));

  // Create country

  const countryCreateRoute = createRoute({
    getParentRoute: () => countryRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: COUNTRY.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    pendingComponent: () => <EntityCreateLoading entity={COUNTRY} />,
  }).lazy(() => import("./country.lazy").then((d) => d.CountryCreateRoute));

  // View country

  const countryViewRootRoute = createRoute({
    getParentRoute: () => countryRoute,
    path: "$countryId",
    staticData: {
      breadcrumb: CountryBreadcrumb,
    },
    loader: countryViewLoader,
  });

  // Edit country

  const countryEditRoute = createRoute({
    getParentRoute: () => countryViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    pendingComponent: () => <EntityEditLoading entity={COUNTRY} />,
  }).lazy(() => import("./country.lazy").then((d) => d.CountryEditRoute));

  // View country Layout

  const countryViewLayoutRoute = createRoute({
    getParentRoute: () => countryViewRootRoute,
    id: "country-view-layout",
    pendingComponent: () => <EntityViewLoading entity={COUNTRY} />,
  }).lazy(() => import("./country.lazy").then((d) => d.CountryViewLayoutRoute));

  // View country - General Tab

  const countryViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => countryViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./country.lazy").then((d) => d.CountryViewLayoutGeneralTabRoute)
  );

  // View country - About Tab

  const countryViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => countryViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./country.lazy").then((d) => d.CountryViewLayoutAboutTabRoute)
  );

  // View country - Offers Tab

  const countryViewLayoutOfferListTabRoute = createRoute({
    getParentRoute: () => countryViewLayoutRoute,
    path: OFFER.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: OFFER.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: OFFER.label.upFirstPlural,
    },
    loader: offerListByCountryLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./country.lazy").then((d) => d.CountryViewLayoutOfferListTabRoute)
  );

  // Country Children
  const offerRoute = offerRouteFactory(countryViewRootRoute);

  // Route tree
  countryRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [countryListRoute]),
    countryCreateRoute,
    countryViewRootRoute.addChildren([
      countryEditRoute,
      countryViewLayoutRoute.addChildren([
        countryViewLayoutGeneralTabRoute,
        countryViewLayoutAboutTabRoute,
        countryViewLayoutOfferListTabRoute,
      ]),
      offerRoute,
    ]),
  ]);

  return countryRoute;
};

const countryRoute = countryRouteFactory();

export default countryRoute;
