import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "live",
  text: "",
  sortBy: "startDate-desc",
  startDate: null,
  endDate: null,
};

// INITIAL STATE
const initialState = {
  campaigns: initialFilters,
  advertisers: [],
  brands: [],
  platforms: [],
  users: [],
};

// const mockState = {
//   campaigns: initialFilters,
//   advertisers: [
//     {
//       id: "123",
//       filters: initialFilters,
//     },
//     {
//       id: "456",
//       filters: initialFilters,
//     },
//   ]
// };

const campaignFiltersSlice = createSlice({
  name: "campaignFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns.tab = action.payload.tab;
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                tab: action.payload.tab,
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                tab: action.payload.tab,
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                tab: action.payload.tab,
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns.text = action.payload.text;
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                text: action.payload.text,
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                text: action.payload.text,
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                text: action.payload.text,
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        default:
          break;
      }
    },
    setStartDate: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns.startDate = action.payload.startDate;
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                startDate: action.payload.startDate,
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                startDate: action.payload.startDate,
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                startDate: action.payload.startDate,
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        default:
          break;
      }
    },
    setEndDate: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns.endDate = action.payload.endDate;
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                endDate: action.payload.endDate,
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                endDate: action.payload.endDate,
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                endDate: action.payload.endDate,
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByCampaignAsc: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns.sortBy = "campaign-asc";
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                sortBy: "campaign-asc",
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "campaign-asc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "campaign-asc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByCampaignDesc: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns.sortBy = "campaign-desc";
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                sortBy: "campaign-desc",
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "campaign-desc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "campaign-desc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByClientBudgetAsc: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns.sortBy = "clientBudget-asc";
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                sortBy: "clientBudget-asc",
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "clientBudget-asc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "clientBudget-asc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByClientBudgetDesc: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns.sortBy = "clientBudget-desc";
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                sortBy: "clientBudget-desc",
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "clientBudget-desc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "clientBudget-desc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByStartDateAsc: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns.sortBy = "startDate-asc";
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                sortBy: "startDate-asc",
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "startDate-asc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "startDate-asc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByStartDateDesc: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns.sortBy = "startDate-desc";
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                sortBy: "startDate-desc",
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "startDate-desc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "startDate-desc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByEndDateAsc: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns.sortBy = "endDate-asc";
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                sortBy: "endDate-asc",
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "endDate-asc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "endDate-asc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByEndDateDesc: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns.sortBy = "endDate-desc";
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                sortBy: "endDate-desc",
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "brands":
          state.brands = state.brands.map((brand) => {
            if (brand.id === action.payload.id) {
              const updatedFilters = {
                ...brand.filters,
                sortBy: "endDate-desc",
              };
              return { ...brand, filters: updatedFilters };
            } else {
              return brand;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "endDate-desc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "campaigns":
          state.campaigns = initialFilters;
          break;
        case "advertisers":
          if (
            !state.advertisers.find(
              (advertiser) => advertiser.id === action.payload.id
            )
          ) {
            state.advertisers.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        case "brands":
          if (!state.brands.find((brand) => brand.id === action.payload.id)) {
            state.brands.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        case "platforms":
          if (
            !state.platforms.find(
              (platform) => platform.id === action.payload.id
            )
          ) {
            state.platforms.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  setStartDate,
  setEndDate,
  sortByCampaignAsc,
  sortByCampaignDesc,
  sortByClientBudgetAsc,
  sortByClientBudgetDesc,
  sortByStartDateAsc,
  sortByStartDateDesc,
  sortByEndDateAsc,
  sortByEndDateDesc,
  initFilterSet,
} = campaignFiltersSlice.actions;
export const campaignFiltersReducer = campaignFiltersSlice.reducer;
