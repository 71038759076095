import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetUser } from "../queries/users";
import { ENTITY } from "../constants";

export const UserBreadcrumb = ({ match }) => {
  // Entity
  const { USER } = ENTITY;

  const {
    data: user,
    isLoading,
    isError,
    error,
  } = useGetUser(match.params.userId);

  if (isLoading) {
    //return <span>Loading...</span>;
    return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
  }

  if (isError && error instanceof NotFoundException) {
    return <span>Not found</span>;
  }

  if (user) {
    return <span>{trimString(user[USER.nameField], 20)}</span>;
  }

  return null;
};
