import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "name-asc",
};

// INITIAL STATE
const initialState = {
  advertisers: initialFilters,
};

const advertiserFiltersSlice = createSlice({
  name: "advertiserFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      state.advertisers.tab = action.payload.tab;
    },
    setTextFilter: (state, action) => {
      state.advertisers.text = action.payload.text;
    },
    sortByNameAsc: (state, action) => {
      state.advertisers.sortBy = "name-asc";
    },
    sortByNameDesc: (state, action) => {
      state.advertisers.sortBy = "name-desc";
    },
  },
});

export const { setTabFilter, setTextFilter, sortByNameAsc, sortByNameDesc } =
  advertiserFiltersSlice.actions;
export const advertiserFiltersReducer = advertiserFiltersSlice.reducer;
