import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { ETLTaskRouteFactory } from "./ETLTask";
import { ETLRunViewLoader } from "../loaders/ETLRun";
import { ETLTaskListByETLRunLoader } from "../loaders/ETLTask";
import { ETLRunBreadcrumb } from "../../breadcrumbs/ETLRun";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";

const { ETLRUN, ETLTASK } = ENTITY;

export const ETLRunRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const ETLRunRoute = createRoute({
    getParentRoute: () => parent,
    path: ETLRUN.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ETLRUN.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ETLRUN.label.upFirstPlural,
    },
  });

  // List ETLRuns

  const ETLRunListRoute = createRoute({
    getParentRoute: () => ETLRunRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={ETLRUN} />,
  }).lazy(() => import("./ETLRun.lazy").then((d) => d.ETLRunListRoute));

  // View ETLRun

  const ETLRunViewRootRoute = createRoute({
    getParentRoute: () => ETLRunRoute,
    path: "$ETLRunId",
    staticData: {
      breadcrumb: ETLRunBreadcrumb,
    },
    loader: ETLRunViewLoader,
  });

  // View ETLRun Layout

  const ETLRunViewLayoutRoute = createRoute({
    getParentRoute: () => ETLRunViewRootRoute,
    id: "ETLRun-view-layout",
    pendingComponent: () => <EntityViewLoading entity={ETLRUN} />,
  }).lazy(() => import("./ETLRun.lazy").then((d) => d.ETLRunViewLayoutRoute));

  // View ETLRun - General Tab

  const ETLRunViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => ETLRunViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./ETLRun.lazy").then((d) => d.ETLRunViewLayoutGeneralTabRoute)
  );

  // View ETLRun - About Tab

  const ETLRunViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => ETLRunViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./ETLRun.lazy").then((d) => d.ETLRunViewLayoutAboutTabRoute)
  );

  // View ETLRun - ETLTasks Tab

  const ETLRunViewLayoutETLTaskListTabRoute = createRoute({
    getParentRoute: () => ETLRunViewLayoutRoute,
    path: ETLTASK.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ETLTASK.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ETLTASK.label.upFirstPlural,
    },
    loader: ETLTaskListByETLRunLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./ETLRun.lazy").then((d) => d.ETLRunViewLayoutETLTaskListTabRoute)
  );

  // ETLRun Children
  const ETLTaskRoute = ETLTaskRouteFactory(ETLRunViewRootRoute);

  // Route tree
  ETLRunRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [ETLRunListRoute]),
    ETLRunViewRootRoute.addChildren([
      ETLRunViewLayoutRoute.addChildren([
        ETLRunViewLayoutGeneralTabRoute,
        ETLRunViewLayoutETLTaskListTabRoute,
        ETLRunViewLayoutAboutTabRoute,
      ]),
      ETLTaskRoute,
    ]),
  ]);

  return ETLRunRoute;
};

const ETLRunRoute = ETLRunRouteFactory();

export default ETLRunRoute;
