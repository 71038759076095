import {
  getAdPlatformsQueryOptions,
  getAdPlatformQueryOptions,
} from "../../queries/adPlatforms";

// adPlatforms are pre-fetched when a user hover a 'adPlatforms' link
export const adPlatformListLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getAdPlatformsQueryOptions());
};

// adPlatform is pre-fetched when a user hover a 'view adPlatform' link
export const adPlatformViewLoader = ({ params, context: { queryClient } }) => {
  const { adPlatformId } = params;
  queryClient.ensureQueryData(
    getAdPlatformQueryOptions(adPlatformId, queryClient)
  );
};
