import { createSlice } from "@reduxjs/toolkit";

const initialPagination = {
  page: 1,
  nbDocsPerPage: 25,
};

// INITIAL STATE
const initialState = {
  ETLTasks: initialPagination,
  ETLRuns: [],
};

const ETLTaskPaginationSlice = createSlice({
  name: "ETLTaskPagination",
  initialState,
  reducers: {
    setPage: (state, action) => {
      switch (action.payload.module) {
        case "ETLTasks":
          state.ETLTasks.page = action.payload.page;
          break;
        case "ETLRuns":
          state.ETLRuns = state.ETLRuns.map((ETLRun) => {
            if (ETLRun.id === action.payload.id) {
              const updatedPagination = {
                ...ETLRun.pagination,
                page: action.payload.page,
              };
              return { ...ETLRun, pagination: updatedPagination };
            } else {
              return ETLRun;
            }
          });
          break;

        default:
          break;
      }
    },
    setNbDocsPerPage: (state, action) => {
      switch (action.payload.module) {
        case "ETLTasks":
          state.ETLTasks.nbDocsPerPage = action.payload.nbDocsPerPage;
          break;
        case "ETLRuns":
          state.ETLRuns = state.ETLRuns.map((ETLRun) => {
            if (ETLRun.id === action.payload.id) {
              const updatedPagination = {
                ...ETLRun.pagination,
                nbDocsPerPage: action.payload.nbDocsPerPage,
              };
              return { ...ETLRun, pagination: updatedPagination };
            } else {
              return ETLRun;
            }
          });
          break;
        default:
          break;
      }
    },
    initPaginationSet: (state, action) => {
      switch (action.payload.module) {
        case "ETLTasks":
          state.ETLTasks = initialPagination;
          break;
        case "ETLRuns":
          if (
            !state.ETLRuns.find((ETLRun) => ETLRun.id === action.payload.id)
          ) {
            state.ETLRuns.push({
              id: action.payload.id,
              pagination: initialPagination,
            });
          }
          break;
        default:
          break;
      }
    },
  },
});

export const { setPage, setNbDocsPerPage, initPaginationSet } =
  ETLTaskPaginationSlice.actions;
export const ETLTaskPaginationReducer = ETLTaskPaginationSlice.reducer;
