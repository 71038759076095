import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { lineItemRouteFactory } from "./lineItem";
import {
  offerFormLoader,
  offerListLoader,
  offerViewLoader,
} from "../loaders/offer";
import { lineItemListByOfferLoader } from "../loaders/lineItem";
import { OfferBreadcrumb } from "../../breadcrumbs/offer";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { OFFER, LINEITEM } = ENTITY;

export const offerRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const offerRoute = createRoute({
    getParentRoute: () => parent,
    path: OFFER.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: OFFER.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: OFFER.label.upFirstPlural,
    },
  });

  // List offers

  const offerListRoute = createRoute({
    getParentRoute: () => offerRoute,
    path: "/",
    loader: offerListLoader,
    pendingComponent: () => <EntityListLoading entity={OFFER} />,
  }).lazy(() => import("./offer.lazy").then((d) => d.OfferListRoute));

  // Create offer

  const offerCreateRoute = createRoute({
    getParentRoute: () => offerRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: OFFER.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    loader: offerFormLoader,
    pendingComponent: () => <EntityCreateLoading entity={OFFER} />,
  }).lazy(() => import("./offer.lazy").then((d) => d.OfferCreateRoute));

  // View offer

  const offerViewRootRoute = createRoute({
    getParentRoute: () => offerRoute,
    path: "$offerId",
    staticData: {
      breadcrumb: OfferBreadcrumb,
    },
    loader: offerViewLoader,
  });

  // Edit offer

  const offerEditRoute = createRoute({
    getParentRoute: () => offerViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    loader: offerFormLoader,
    pendingComponent: () => <EntityEditLoading entity={OFFER} />,
  }).lazy(() => import("./offer.lazy").then((d) => d.OfferEditRoute));

  // View offer Layout

  const offerViewLayoutRoute = createRoute({
    getParentRoute: () => offerViewRootRoute,
    id: "offer-view-layout",
    pendingComponent: () => <EntityViewLoading entity={OFFER} />,
  }).lazy(() => import("./offer.lazy").then((d) => d.OfferViewLayoutRoute));

  // View offer - General Tab

  const offerViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => offerViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./offer.lazy").then((d) => d.OfferViewLayoutGeneralTabRoute)
  );

  // View offer - Goals Tab

  const offerViewLayoutGoalsTabRoute = createRoute({
    getParentRoute: () => offerViewLayoutRoute,
    path: "goals",
    staticData: {
      breadcrumb: "Goals",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./offer.lazy").then((d) => d.OfferViewLayoutGoalsTabRoute)
  );

  // View offer - About Tab

  const offerViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => offerViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./offer.lazy").then((d) => d.OfferViewLayoutAboutTabRoute)
  );

  // View offer - LineItems Tab

  const offerViewLayoutLineItemPaginatedListTabRoute = createRoute({
    getParentRoute: () => offerViewLayoutRoute,
    path: LINEITEM.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: LINEITEM.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: LINEITEM.label.upFirstPlural,
    },
    loader: lineItemListByOfferLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./offer.lazy").then(
      (d) => d.OfferViewLayoutLineItemPaginatedListTabRoute
    )
  );

  // Offer Children
  const lineItemRoute = lineItemRouteFactory(offerViewRootRoute);

  // Route tree
  offerRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [offerListRoute]),
    offerCreateRoute,
    offerViewRootRoute.addChildren([
      offerEditRoute,
      offerViewLayoutRoute.addChildren([
        offerViewLayoutGeneralTabRoute,
        offerViewLayoutGoalsTabRoute,
        offerViewLayoutAboutTabRoute,
        offerViewLayoutLineItemPaginatedListTabRoute,
      ]),
      lineItemRoute,
    ]),
  ]);

  return offerRoute;
};

const offerRoute = offerRouteFactory();

export default offerRoute;
