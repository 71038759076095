import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { metricFormLoader, metricViewLoader } from "../loaders/metric";
import { MetricBreadcrumb } from "../../breadcrumbs/metric";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { METRIC } = ENTITY;

export const metricRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const metricRoute = createRoute({
    getParentRoute: () => parent,
    path: METRIC.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: METRIC.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: METRIC.label.upFirstPlural,
    },
  });

  // List metrics

  const metricListRoute = createRoute({
    getParentRoute: () => metricRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={METRIC} />,
  }).lazy(() => import("./metric.lazy").then((d) => d.MetricListRoute));

  // Create metric

  const metricCreateRoute = createRoute({
    getParentRoute: () => metricRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: METRIC.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    loader: metricFormLoader,
    pendingComponent: () => <EntityEditLoading entity={METRIC} />,
  }).lazy(() => import("./metric.lazy").then((d) => d.MetricCreateRoute));

  // View metric

  const metricViewRootRoute = createRoute({
    getParentRoute: () => metricRoute,
    path: "$metricId",
    staticData: {
      breadcrumb: MetricBreadcrumb,
    },
    loader: metricViewLoader,
  });

  // Edit metric

  const metricEditRoute = createRoute({
    getParentRoute: () => metricViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    loader: metricFormLoader,
    pendingComponent: () => <EntityEditLoading entity={METRIC} />,
  }).lazy(() => import("./metric.lazy").then((d) => d.MetricEditRoute));

  // View metric Layout

  const metricViewLayoutRoute = createRoute({
    getParentRoute: () => metricViewRootRoute,
    id: "metric-view-layout",
    pendingComponent: () => <EntityViewLoading entity={METRIC} />,
  }).lazy(() => import("./metric.lazy").then((d) => d.MetricViewLayoutRoute));

  // View metric - General Tab

  const metricViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => metricViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./metric.lazy").then((d) => d.MetricViewLayoutGeneralTabRoute)
  );

  // View metric - About Tab

  const metricViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => metricViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./metric.lazy").then((d) => d.MetricViewLayoutAboutTabRoute)
  );

  // Route tree
  metricRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [metricListRoute]),
    metricCreateRoute,
    metricViewRootRoute.addChildren([
      metricEditRoute,
      metricViewLayoutRoute.addChildren([
        metricViewLayoutGeneralTabRoute,
        metricViewLayoutAboutTabRoute,
      ]),
    ]),
  ]);

  return metricRoute;
};

const metricRoute = metricRouteFactory();

export default metricRoute;
