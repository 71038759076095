import fetchServer from "../helpers/api/fetchServer";
import fetchServerUpdateImage from "../helpers/api/fetchServerUpdateImage";

export const createUser = async ({ email, firstName, lastName }) => {
  const method = "POST";
  const urlAPI = "/api/users";
  const body = {
    email,
    firstName,
    lastName,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const sendInvitationEmailToUser = async ({ id }) => {
  const method = "PUT";
  const urlAPI = `/api/users/${id}/send-invitation-email`;
  return await fetchServer({ method, urlAPI });
};

export const getUsers = async () => {
  const method = "GET";
  const urlAPI = "/api/users";
  return await fetchServer({ method, urlAPI });
};

export const getUser = async (id) => {
  const method = "GET";
  const urlAPI = `/api/users/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const getUserPermissions = async (id) => {
  const method = "GET";
  const urlAPI = `/api/users/${id}/permissions`;
  return await fetchServer({ method, urlAPI });
};

export const updateUser = async ({ id, email, firstName, lastName }) => {
  const method = "PUT";
  const urlAPI = `/api/users/${id}`;
  const body = { email, firstName, lastName };
  return await fetchServer({ method, urlAPI, body });
};

export const updateUserPersonalInfos = async ({ id, firstName, lastName }) => {
  const method = "PUT";
  const urlAPI = `/api/users/${id}/personal-infos`;
  const body = { firstName, lastName };
  return await fetchServer({ method, urlAPI, body });
};

export const updateUserEmail = async ({ id, email, password }) => {
  const method = "PUT";
  const urlAPI = `/api/users/${id}/email`;
  const body = { email, password };
  return await fetchServer({ method, urlAPI, body });
};

export const updateUserAvatar = async ({ id, formData }) => {
  const urlAPI = `/api/users/${id}/avatar`;
  return await fetchServerUpdateImage({ urlAPI, formData });
};

export const updateUserPermissions = async ({ id, module, permissions }) => {
  const method = "PUT";
  const urlAPI = `/api/users/${id}/permissions`;
  const body = {
    module,
    permissions,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const deleteUserAvatar = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/users/${id}/avatar`;
  return await fetchServer({ method, urlAPI });
};

export const deleteUser = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/users/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreUser = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/users/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteUsers = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/users/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreUsers = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/users/restore-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};
