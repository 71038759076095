import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { campaignRouteFactory } from "./campaign";
import { postRouteFactory } from "./post";
import { brandFormLoader, brandViewLoader } from "../loaders/brand";
import { campaignListByBrandLoader } from "../loaders/campaign";
import { postListByBrandLoader } from "../loaders/post";
import { BrandBreadcrumb } from "../../breadcrumbs/brand";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { BRAND, CAMPAIGN, POST } = ENTITY;

export const brandRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const brandRoute = createRoute({
    getParentRoute: () => parent,
    path: BRAND.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: BRAND.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: BRAND.label.upFirstPlural,
    },
  });

  // List brands

  const brandListRoute = createRoute({
    getParentRoute: () => brandRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={BRAND} />,
  }).lazy(() => import("./brand.lazy").then((d) => d.BrandListRoute));

  // Create brand

  const brandCreateRoute = createRoute({
    getParentRoute: () => brandRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: BRAND.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    loader: brandFormLoader,
    pendingComponent: () => <EntityCreateLoading entity={BRAND} />,
  }).lazy(() => import("./brand.lazy").then((d) => d.BrandCreateRoute));

  // View brand

  const brandViewRootRoute = createRoute({
    getParentRoute: () => brandRoute,
    path: "$brandId",
    staticData: {
      breadcrumb: BrandBreadcrumb,
    },
    loader: brandViewLoader,
  });

  // Edit brand

  const brandEditRoute = createRoute({
    getParentRoute: () => brandViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    loader: brandFormLoader,
    pendingComponent: () => <EntityEditLoading entity={BRAND} />,
  }).lazy(() => import("./brand.lazy").then((d) => d.BrandEditRoute));

  // View brand Layout

  const brandViewLayoutRoute = createRoute({
    getParentRoute: () => brandViewRootRoute,
    id: "brand-view-layout",
    pendingComponent: () => <EntityViewLoading entity={BRAND} />,
  }).lazy(() => import("./brand.lazy").then((d) => d.BrandViewLayoutRoute));

  // View brand - General Tab

  const brandViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => brandViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./brand.lazy").then((d) => d.BrandViewLayoutGeneralTabRoute)
  );

  // View brand - About Tab

  const brandViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => brandViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./brand.lazy").then((d) => d.BrandViewLayoutAboutTabRoute)
  );

  // View brand - Campaigns Tab

  const brandViewLayoutCampaignListTabRoute = createRoute({
    getParentRoute: () => brandViewLayoutRoute,
    path: CAMPAIGN.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: CAMPAIGN.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: CAMPAIGN.label.upFirstPlural,
    },
    loader: campaignListByBrandLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./brand.lazy").then((d) => d.BrandViewLayoutCampaignListTabRoute)
  );

  // View brand - Posts Tab

  const brandViewLayoutPostPaginatedListCannotDeleteTabRoute = createRoute({
    getParentRoute: () => brandViewLayoutRoute,
    path: POST.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: POST.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: POST.label.upFirstPlural,
    },
    loader: postListByBrandLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./brand.lazy").then(
      (d) => d.BrandViewLayoutPostPaginatedListCannotDeleteTabRoute
    )
  );

  // Brand Children
  const campaignRoute = campaignRouteFactory(brandViewRootRoute);
  const postRoute = postRouteFactory(brandViewRootRoute);

  // Route tree
  brandRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [brandListRoute]),
    brandCreateRoute,
    brandViewRootRoute.addChildren([
      brandEditRoute,
      brandViewLayoutRoute.addChildren([
        brandViewLayoutGeneralTabRoute,
        brandViewLayoutAboutTabRoute,
        brandViewLayoutCampaignListTabRoute,
        brandViewLayoutPostPaginatedListCannotDeleteTabRoute,
      ]),
      campaignRoute,
      postRoute,
    ]),
  ]);

  return brandRoute;
};

const brandRoute = brandRouteFactory();

export default brandRoute;
