import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetPlatform } from "../queries/platforms";
import { ENTITY } from "../constants";

export const PlatformBreadcrumb = ({ match }) => {
    // Entity
    const { PLATFORM } = ENTITY;

  const {
    data: platform,
    isLoading,
    isError,
    error,
  } = useGetPlatform(match.params.platformId);

  if (isLoading) {
    //return <span>Loading...</span>;
    return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
  }

  if (isError && error instanceof NotFoundException) {
    return <span>Not found</span>;
  }

  if (platform) {
    return <span>{trimString(platform[PLATFORM.nameField], 20)}</span>;
  }

  return null;
};
