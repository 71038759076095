import { createSlice } from "@reduxjs/toolkit";

const initialSettings = {
  graphMetric: "realBudgetDelivered",
  graphType: "bar",
  graphSortBy: "metric",
  visibleSettings: true,
};

// INITIAL STATE
const initialState = {
  lineItems: [],
};


const lineItemPerformanceSettingsSlice = createSlice({
  name: "lineItemPerformanceSettings",
  initialState,
  reducers: {
    setGraphMetric: (state, action) => {
      state.lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.id === action.payload.id) {
          const updatedSettings = {
            ...lineItem.performanceSettings,
            graphMetric: action.payload.graphMetric,
          };
          return { ...lineItem, performanceSettings: updatedSettings };
        } else {
          return lineItem;
        }
      });
    },
    setGraphType: (state, action) => {
      state.lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.id === action.payload.id) {
          const updatedSettings = {
            ...lineItem.performanceSettings,
            graphType: action.payload.graphType,
          };
          return { ...lineItem, performanceSettings: updatedSettings };
        } else {
          return lineItem;
        }
      });
    },
    setGraphSortBy: (state, action) => {
      state.lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.id === action.payload.id) {
          const updatedSettings = {
            ...lineItem.performanceSettings,
            graphSortBy: action.payload.graphSortBy,
          };
          return { ...lineItem, performanceSettings: updatedSettings };
        } else {
          return lineItem;
        }
      });
    },
    setVisibleSettings: (state, action) => {
      state.lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.id === action.payload.id) {
          const updatedSettings = {
            ...lineItem.performanceSettings,
            visibleSettings: action.payload.visibleSettings,
          };
          return { ...lineItem, performanceSettings: updatedSettings };
        } else {
          return lineItem;
        }
      });
    },
    initPerformanceSettingsSet: (state, action) => {
      if (!state.lineItems.find((lineItem) => lineItem.id === action.payload.id)) {
        state.lineItems.push({
          id: action.payload.id,
          performanceSettings: initialSettings,
        });
      }
    },
  },
});

export const { setGraphMetric, setGraphType, setGraphSortBy, setVisibleSettings, initPerformanceSettingsSet } =
  lineItemPerformanceSettingsSlice.actions;
export const lineItemPerformanceSettingsReducer =
  lineItemPerformanceSettingsSlice.reducer;
