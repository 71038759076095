import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "url-asc",
};

// INITIAL STATE
const initialState = {
  accounts: initialFilters,
  advertisers: [],
  platforms: [],
  users: [],
};

// const mockState = {
//   accounts: initialFilters,
//   advertisers: [
//     {
//       id: "123",
//       filters: initialFilters,
//     },
//     {
//       id: "456",
//       filters: initialFilters,
//     },
//   ]
// };

const accountFiltersSlice = createSlice({
  name: "accountFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "accounts":
          state.accounts.tab = action.payload.tab;
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                tab: action.payload.tab,
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                tab: action.payload.tab,
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "accounts":
          state.accounts.text = action.payload.text;
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                text: action.payload.text,
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                text: action.payload.text,
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByUrlAsc: (state, action) => {
      switch (action.payload.module) {
        case "accounts":
          state.accounts.sortBy = "url-asc";
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                sortBy: "url-asc",
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "url-asc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByUrlDesc: (state, action) => {
      switch (action.payload.module) {
        case "accounts":
          state.accounts.sortBy = "url-desc";
          break;
        case "advertisers":
          state.advertisers = state.advertisers.map((advertiser) => {
            if (advertiser.id === action.payload.id) {
              const updatedFilters = {
                ...advertiser.filters,
                sortBy: "url-desc",
              };
              return { ...advertiser, filters: updatedFilters };
            } else {
              return advertiser;
            }
          });
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "url-desc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;

        default:
          break;
      }
    },
    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "accounts":
          state.accounts = initialFilters;
          break;
        case "advertisers":
          if (
            !state.advertisers.find(
              (advertiser) => advertiser.id === action.payload.id
            )
          ) {
            state.advertisers.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        case "platforms":
          if (
            !state.platforms.find(
              (platform) => platform.id === action.payload.id
            )
          ) {
            state.platforms.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  sortByUrlAsc,
  sortByUrlDesc,
  initFilterSet,
} = accountFiltersSlice.actions;
export const accountFiltersReducer = accountFiltersSlice.reducer;
