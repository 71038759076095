import fetchServer from "../helpers/api/fetchServer";

export const initAuth = async () => {
  return await refreshToken();
};

export const refreshToken = async () => {
  const method = "POST";
  const urlAPI = "/api/auth/refresh-token";
  return await fetchServer({ method, urlAPI, noXsrfHeaders: true});

};

export const login = async ({ email, password }) => {
  const method = "POST";
  const urlAPI = "/api/auth/authenticate";
  const body = { email, password };
  return await fetchServer({ method, urlAPI, body});

};

export const logout = async () => {
  const method = "POST";
  const urlAPI = "/api/auth/revoke-token";
  return await fetchServer({ method, urlAPI});
};

export const signup = async ({
  firstName,
  lastName,
  email,
  password,
  acceptTerms,
}) => {

  const method = "POST";
  const urlAPI = "/api/auth/signup";
  const body = {
    firstName,
    lastName,
    email,
    password,
    acceptTerms,
  };

  return await fetchServer({ method, urlAPI, body, noXsrfHeaders: true});
};

export const verifyEmail = async ({ email, token }) => {
  const method = "POST";
  const urlAPI = "/api/auth/verify-email";
  const body = { email, token };
  return await fetchServer({ method, urlAPI, body, noXsrfHeaders: true});
};

// resetPassword is done after forgot password form
// while updatePassword is done in the app (User settings)
export const resetPassword = async ({ email, token, newPassword }) => {
  const method = "POST";
  const urlAPI = "/api/auth/reset-password";
  const body = { email, token, newPassword };
  return await fetchServer({ method, urlAPI, body, noXsrfHeaders: true});
};

export const forgotPassword = async ({ email }) => {
  const method = "POST";
  const urlAPI = "/api/auth/forgot-password";
  const body = { email };
  return await fetchServer({ method, urlAPI, body, noXsrfHeaders: true});
};

export const updateEmail = async ({ email, password }) => {
  const method = "POST";
  const urlAPI = "/api/auth/update-email";
  const body = { email, password };
  return await fetchServer({ method, urlAPI, body});
};

export const updatePassword = async ({ currentPassword, newPassword }) => {
  const method = "POST";
  const urlAPI = "/api/auth/update-password";
  const body = { currentPassword, newPassword };
  return await fetchServer({ method, urlAPI, body});
};
