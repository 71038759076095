import {
  getLineItemQueryOptions,
  getPaginatedLineItemsByCampaignQueryOptions,
  getPaginatedLineItemsByOfferQueryOptions,
} from "../../queries/lineItems";
import { getAdvertisersQueryOptions } from "../../queries/advertisers";
import { getCampaignsQueryOptions } from "../../queries/campaigns";
import { getCountriesQueryOptions } from "../../queries/countries";
import { getPlatformsQueryOptions } from "../../queries/platforms";
import { getOffersQueryOptions } from "../../queries/offers";
import { getMetricsQueryOptions } from "../../queries/metrics";

// necessary entities are pre-fetched when a user hover a 'create lineItem' or a 'edit lineItem' link
export const lineItemFormLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getAdvertisersQueryOptions());
  queryClient.ensureQueryData(getCampaignsQueryOptions());
  queryClient.ensureQueryData(getCountriesQueryOptions());
  queryClient.ensureQueryData(getPlatformsQueryOptions());
  queryClient.ensureQueryData(getOffersQueryOptions());
  queryClient.ensureQueryData(getMetricsQueryOptions());
};

// lineItems are pre-fetched when a user hover a 'lineItems' link within a campaign
export const lineItemListByCampaignLoader = ({
  params,
  context: { queryClient },
}) => {
  const { campaignId } = params;

  // REFACTOR
  // These temporary settings are redux slices initial values
  const filterText = "";
  const filterStartDate = null;
  const filterEndDate = null;
  const filterDeleted = false;
  const sortBy = "startDate-desc";
  const page = 1;
  const nbDocsPerPage = 25;

  queryClient.ensureQueryData(
    getPaginatedLineItemsByCampaignQueryOptions({
      campaignId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// lineItems are pre-fetched when a user hover a 'lineItems' link within a offer
export const lineItemListByOfferLoader = ({
  params,
  context: { queryClient },
}) => {
  const { offerId } = params;

  // REFACTOR
  // These temporary settings are redux slices initial values
  const filterText = "";
  const filterStartDate = null;
  const filterEndDate = null;
  const filterDeleted = false;
  const sortBy = "startDate-desc";
  const page = 1;
  const nbDocsPerPage = 25;

  queryClient.ensureQueryData(
    getPaginatedLineItemsByOfferQueryOptions({
      offerId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// lineItem is pre-fetched when a user hover a 'view lineItem' link
export const lineItemViewLoader = ({ params, context: { queryClient } }) => {
  const { lineItemId } = params;
  queryClient.ensureQueryData(getLineItemQueryOptions(lineItemId, queryClient));
};
