import PropTypes from "prop-types";
import { Link as RouterLink } from "@tanstack/react-router";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "../../../icons/Menu";
import Logo from "../../general/Logo";

const Navbar = ({ onSidebarMobileOpen }) => (
  <AppBar
    elevation={0}
    sx={{
      backgroundColor: "background.paper",
      color: "text.secondary",
    }}
  >
    <Toolbar sx={{ minHeight: 64 }}>
      <IconButton
        color="inherit"
        onClick={onSidebarMobileOpen}
        sx={{
          display: {
            md: "none",
          },
        }}
      >
        <MenuIcon fontSize="small" />
      </IconButton>
      <RouterLink to="/">
        <Logo
          sx={{
            display: {
              md: "inline",
              xs: "none",
            },
            height: 40,
            width: 40,
          }}
        />
      </RouterLink>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          alignItems: "center",
          display: {
            md: "flex",
            xs: "none",
          },
        }}
      >
        <Link
          color="textSecondary"
          component={RouterLink}
          to="/browse"
          underline="none"
          variant="body1"
        >
          Features
        </Link>
        <Chip
          color="primary"
          label="NEW"
          size="small"
          sx={{
            maxHeight: 20,
            ml: 1,
            mr: 2,
          }}
        />
        <Link
          color="textSecondary"
          component={RouterLink}
          to="/docs"
          underline="none"
          variant="body1"
        >
          Pricing
        </Link>
        <Divider
          orientation="vertical"
          sx={{
            height: 32,
            mx: 2,
          }}
        />
        <Button
          color="primary"
          component="a"
          href="/app"
          size="small"
          variant="contained"
        >
          Login
        </Button>
      </Box>
    </Toolbar>
    <Divider />
  </AppBar>
);

Navbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default Navbar;
