import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "name-asc"
};

// INITIAL STATE
const initialState = {
  specTemplates: initialFilters,
};

const specTemplateFiltersSlice = createSlice({
  name: "specTemplateFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      state.specTemplates.tab = action.payload.tab;
    },
    setTextFilter: (state, action) => {
      state.specTemplates.text = action.payload.text;
    },
    sortByNameAsc: (state, action) => {
      state.specTemplates.sortBy = "name-asc";
    },
    sortByNameDesc: (state, action) => {
      state.specTemplates.sortBy = "name-desc";
    }
  },
});

export const { setTabFilter, setTextFilter, sortByNameAsc, sortByNameDesc } = specTemplateFiltersSlice.actions;
export const specTemplateFiltersReducer = specTemplateFiltersSlice.reducer;
