import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { postRouteFactory } from "./post";
import {
  problemTypeFormLoader,
  problemTypeViewLoader,
} from "../loaders/problemType";
import { postListByProblemTypeLoader } from "../loaders/post";
import { ProblemTypeBreadcrumb } from "../../breadcrumbs/problemType";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { PROBLEMTYPE, POST } = ENTITY;

export const problemTypeRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const problemTypeRoute = createRoute({
    getParentRoute: () => parent,
    path: PROBLEMTYPE.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: PROBLEMTYPE.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: PROBLEMTYPE.label.upFirstPlural,
    },
  });

  // List problemTypes

  const problemTypeListRoute = createRoute({
    getParentRoute: () => problemTypeRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={PROBLEMTYPE} />,
  }).lazy(() =>
    import("./problemType.lazy").then((d) => d.ProblemTypeListRoute)
  );

  // Create problemType

  const problemTypeCreateRoute = createRoute({
    getParentRoute: () => problemTypeRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: PROBLEMTYPE.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    loader: problemTypeFormLoader,
    pendingComponent: () => <EntityCreateLoading entity={PROBLEMTYPE} />,
  }).lazy(() =>
    import("./problemType.lazy").then((d) => d.ProblemTypeCreateRoute)
  );

  // View problemType

  const problemTypeViewRootRoute = createRoute({
    getParentRoute: () => problemTypeRoute,
    path: "$problemTypeId",
    staticData: {
      breadcrumb: ProblemTypeBreadcrumb,
    },
    loader: problemTypeViewLoader,
  });

  // Edit problemType

  const problemTypeEditRoute = createRoute({
    getParentRoute: () => problemTypeViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    loader: problemTypeFormLoader,
    pendingComponent: () => <EntityEditLoading entity={PROBLEMTYPE} />,
  }).lazy(() =>
    import("./problemType.lazy").then((d) => d.ProblemTypeEditRoute)
  );

  // View problemType Layout

  const problemTypeViewLayoutRoute = createRoute({
    getParentRoute: () => problemTypeViewRootRoute,
    id: "problemType-view-layout",
    pendingComponent: () => <EntityViewLoading entity={PROBLEMTYPE} />,
  }).lazy(() =>
    import("./problemType.lazy").then((d) => d.ProblemTypeViewLayoutRoute)
  );

  // View problemType - General Tab

  const problemTypeViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => problemTypeViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./problemType.lazy").then(
      (d) => d.ProblemTypeViewLayoutGeneralTabRoute
    )
  );

  // View problemType - About Tab

  const problemTypeViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => problemTypeViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./problemType.lazy").then(
      (d) => d.ProblemTypeViewLayoutAboutTabRoute
    )
  );

  // View problemType - Posts Tab

  const problemTypeViewLayoutPostPaginatedListCannotDeleteTabRoute =
    createRoute({
      getParentRoute: () => problemTypeViewLayoutRoute,
      path: POST.type.plural,
      beforeLoad: () =>
        checkPermissionsAndRedirect([
          { feature: POST.type.singular, action: "READ" },
        ]),
      staticData: {
        breadcrumb: POST.label.upFirstPlural,
      },
      loader: postListByProblemTypeLoader,
      pendingComponent: Spinner,
    }).lazy(() =>
      import("./problemType.lazy").then(
        (d) => d.ProblemTypeViewLayoutPostPaginatedListCannotDeleteTabRoute
      )
    );

  // ProblemType Children
  const postRoute = postRouteFactory(problemTypeViewRootRoute);

  // Route tree
  problemTypeRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [problemTypeListRoute]),
    problemTypeCreateRoute,
    problemTypeViewRootRoute.addChildren([
      problemTypeEditRoute,
      problemTypeViewLayoutRoute.addChildren([
        problemTypeViewLayoutGeneralTabRoute,
        problemTypeViewLayoutAboutTabRoute,
        problemTypeViewLayoutPostPaginatedListCannotDeleteTabRoute,
      ]),
      postRoute,
    ]),
  ]);

  return problemTypeRoute;
};

const problemTypeRoute = problemTypeRouteFactory();

export default problemTypeRoute;
