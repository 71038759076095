import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { ETLTaskViewLoader } from "../loaders/ETLTask";
import { ETLTaskBreadcrumb } from "../../breadcrumbs/ETLTask";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";

const { ETLTASK } = ENTITY;

export const ETLTaskRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const ETLTaskRoute = createRoute({
    getParentRoute: () => parent,
    path: ETLTASK.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ETLTASK.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ETLTASK.label.upFirstPlural,
    },
  });

  // List ETLTasks

  const ETLTaskListRoute = createRoute({
    getParentRoute: () => ETLTaskRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={ETLTASK} />,
  }).lazy(() => import("./ETLTask.lazy").then((d) => d.ETLTaskListRoute));

  // View ETLTask

  const ETLTaskViewRootRoute = createRoute({
    getParentRoute: () => ETLTaskRoute,
    path: "$ETLTaskId",
    staticData: {
      breadcrumb: ETLTaskBreadcrumb,
    },
    loader: ETLTaskViewLoader,
  });

  // View ETLTask Layout

  const ETLTaskViewLayoutRoute = createRoute({
    getParentRoute: () => ETLTaskViewRootRoute,
    id: "ETLTask-view-layout",
    pendingComponent: () => <EntityViewLoading entity={ETLTASK} />,
  }).lazy(() => import("./ETLTask.lazy").then((d) => d.ETLTaskViewLayoutRoute));

  // View ETLTask - General Tab

  const ETLTaskViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => ETLTaskViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./ETLTask.lazy").then((d) => d.ETLTaskViewLayoutGeneralTabRoute)
  );

  // View ETLTask - About Tab

  const ETLTaskViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => ETLTaskViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./ETLTask.lazy").then((d) => d.ETLTaskViewLayoutAboutTabRoute)
  );

  // Route tree
  ETLTaskRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [ETLTaskListRoute]),
    ETLTaskViewRootRoute.addChildren([
      ETLTaskViewLayoutRoute.addChildren([
        ETLTaskViewLayoutGeneralTabRoute,
        ETLTaskViewLayoutAboutTabRoute,
      ]),
    ]),
  ]);

  return ETLTaskRoute;
};

const ETLTaskRoute = ETLTaskRouteFactory();

export default ETLTaskRoute;
