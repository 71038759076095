import { createRoute } from "@tanstack/react-router";
import WebsiteLayout from "../../components/layouts/website/WebsiteLayout";
import {
  rootRoute
} from "../index";


export const websiteRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: "websiteLayout",
  component: WebsiteLayout,
});

export const homeRoute = createRoute({
  getParentRoute: () => websiteRoute,
  path: "/",
}).lazy(() => import("./index.lazy").then((d) => d.HomeRoute));
