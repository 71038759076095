import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "name-asc",
};

// INITIAL STATE
const initialState = {
  offers: initialFilters,
  platforms: [],
  adFormats: [],
  specTemplates: [],
  countries: [],
  adOpsObjectives: [],
};

// const mockState = {
//   offers: initialFilters,
//   advertisers: [
//     {
//       id: "123",
//       filters: initialFilters,
//     },
//     {
//       id: "456",
//       filters: initialFilters,
//     },
//   ]
// };

const offerFiltersSlice = createSlice({
  name: "offerFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "offers":
          state.offers.tab = action.payload.tab;
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                tab: action.payload.tab,
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        case "adFormats":
          state.adFormats = state.adFormats.map((adFormat) => {
            if (adFormat.id === action.payload.id) {
              const updatedFilters = {
                ...adFormat.filters,
                tab: action.payload.tab,
              };
              return { ...adFormat, filters: updatedFilters };
            } else {
              return adFormat;
            }
          });
          break;
        case "specTemplates":
          state.specTemplates = state.specTemplates.map((specTemplate) => {
            if (specTemplate.id === action.payload.id) {
              const updatedFilters = {
                ...specTemplate.filters,
                tab: action.payload.tab,
              };
              return { ...specTemplate, filters: updatedFilters };
            } else {
              return specTemplate;
            }
          });
          break;
        case "countries":
          state.countries = state.countries.map((country) => {
            if (country.id === action.payload.id) {
              const updatedFilters = {
                ...country.filters,
                tab: action.payload.tab,
              };
              return { ...country, filters: updatedFilters };
            } else {
              return country;
            }
          });
          break;
        case "adOpsObjectives":
          state.adOpsObjectives = state.adOpsObjectives.map(
            (adOpsObjective) => {
              if (adOpsObjective.id === action.payload.id) {
                const updatedFilters = {
                  ...adOpsObjective.filters,
                  tab: action.payload.tab,
                };
                return { ...adOpsObjective, filters: updatedFilters };
              } else {
                return adOpsObjective;
              }
            }
          );
          break;
        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "offers":
          state.offers.text = action.payload.text;
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                text: action.payload.text,
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        case "adFormats":
          state.adFormats = state.adFormats.map((adFormat) => {
            if (adFormat.id === action.payload.id) {
              const updatedFilters = {
                ...adFormat.filters,
                text: action.payload.text,
              };
              return { ...adFormat, filters: updatedFilters };
            } else {
              return adFormat;
            }
          });
          break;
        case "specTemplates":
          state.specTemplates = state.specTemplates.map((specTemplate) => {
            if (specTemplate.id === action.payload.id) {
              const updatedFilters = {
                ...specTemplate.filters,
                text: action.payload.tab,
              };
              return { ...specTemplate, filters: updatedFilters };
            } else {
              return specTemplate;
            }
          });
          break;
        case "countries":
          state.countries = state.countries.map((country) => {
            if (country.id === action.payload.id) {
              const updatedFilters = {
                ...country.filters,
                text: action.payload.text,
              };
              return { ...country, filters: updatedFilters };
            } else {
              return country;
            }
          });
          break;
        case "adOpsObjectives":
          state.adOpsObjectives = state.adOpsObjectives.map(
            (adOpsObjective) => {
              if (adOpsObjective.id === action.payload.id) {
                const updatedFilters = {
                  ...adOpsObjective.filters,
                  text: action.payload.text,
                };
                return { ...adOpsObjective, filters: updatedFilters };
              } else {
                return adOpsObjective;
              }
            }
          );
          break;
        default:
          break;
      }
    },
    sortByNameAsc: (state, action) => {
      switch (action.payload.module) {
        case "offers":
          state.offers.sortBy = "name-asc";
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "name-asc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        case "adFormats":
          state.adFormats = state.adFormats.map((adFormat) => {
            if (adFormat.id === action.payload.id) {
              const updatedFilters = {
                ...adFormat.filters,
                sortBy: "name-asc",
              };
              return { ...adFormat, filters: updatedFilters };
            } else {
              return adFormat;
            }
          });
          break;
        case "specTemplates":
          state.specTemplates = state.specTemplates.map((specTemplate) => {
            if (specTemplate.id === action.payload.id) {
              const updatedFilters = {
                ...specTemplate.filters,
                sortBy: "name-asc",
              };
              return { ...specTemplate, filters: updatedFilters };
            } else {
              return specTemplate;
            }
          });
          break;
        case "countries":
          state.countries = state.countries.map((country) => {
            if (country.id === action.payload.id) {
              const updatedFilters = {
                ...country.filters,
                sortBy: "name-asc",
              };
              return { ...country, filters: updatedFilters };
            } else {
              return country;
            }
          });
          break;
        case "adOpsObjectives":
          state.adOpsObjectives = state.adOpsObjectives.map(
            (adOpsObjective) => {
              if (adOpsObjective.id === action.payload.id) {
                const updatedFilters = {
                  ...adOpsObjective.filters,
                  sortBy: "name-asc",
                };
                return { ...adOpsObjective, filters: updatedFilters };
              } else {
                return adOpsObjective;
              }
            }
          );
          break;
        default:
          break;
      }
    },
    sortByNameDesc: (state, action) => {
      switch (action.payload.module) {
        case "offers":
          state.offers.sortBy = "name-desc";
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "name-desc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        case "adFormats":
          state.adFormats = state.adFormats.map((adFormat) => {
            if (adFormat.id === action.payload.id) {
              const updatedFilters = {
                ...adFormat.filters,
                sortBy: "name-desc",
              };
              return { ...adFormat, filters: updatedFilters };
            } else {
              return adFormat;
            }
          });
          break;
        case "specTemplates":
          state.specTemplates = state.specTemplates.map((specTemplate) => {
            if (specTemplate.id === action.payload.id) {
              const updatedFilters = {
                ...specTemplate.filters,
                sortBy: "name-desc",
              };
              return { ...specTemplate, filters: updatedFilters };
            } else {
              return specTemplate;
            }
          });
          break;
        case "countries":
          state.countries = state.countries.map((country) => {
            if (country.id === action.payload.id) {
              const updatedFilters = {
                ...country.filters,
                sortBy: "name-desc",
              };
              return { ...country, filters: updatedFilters };
            } else {
              return country;
            }
          });
          break;
        case "adOpsObjectives":
          state.adOpsObjectives = state.adOpsObjectives.map(
            (adOpsObjective) => {
              if (adOpsObjective.id === action.payload.id) {
                const updatedFilters = {
                  ...adOpsObjective.filters,
                  sortBy: "name-desc",
                };
                return { ...adOpsObjective, filters: updatedFilters };
              } else {
                return adOpsObjective;
              }
            }
          );
          break;
        default:
          break;
      }
    },
    sortByPlatformAsc: (state, action) => {
      switch (action.payload.module) {
        case "offers":
          state.offers.sortBy = "platform-asc";
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "platform-asc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        case "adFormats":
          state.adFormats = state.adFormats.map((adFormat) => {
            if (adFormat.id === action.payload.id) {
              const updatedFilters = {
                ...adFormat.filters,
                sortBy: "platform-asc",
              };
              return { ...adFormat, filters: updatedFilters };
            } else {
              return adFormat;
            }
          });
          break;
        case "specTemplates":
          state.specTemplates = state.specTemplates.map((specTemplate) => {
            if (specTemplate.id === action.payload.id) {
              const updatedFilters = {
                ...specTemplate.filters,
                sortBy: "platform-asc",
              };
              return { ...specTemplate, filters: updatedFilters };
            } else {
              return specTemplate;
            }
          });
          break;
        case "countries":
          state.countries = state.countries.map((country) => {
            if (country.id === action.payload.id) {
              const updatedFilters = {
                ...country.filters,
                sortBy: "platform-asc",
              };
              return { ...country, filters: updatedFilters };
            } else {
              return country;
            }
          });
          break;
        case "adOpsObjectives":
          state.adOpsObjectives = state.adOpsObjectives.map(
            (adOpsObjective) => {
              if (adOpsObjective.id === action.payload.id) {
                const updatedFilters = {
                  ...adOpsObjective.filters,
                  sortBy: "platform-asc",
                };
                return { ...adOpsObjective, filters: updatedFilters };
              } else {
                return adOpsObjective;
              }
            }
          );
          break;
        default:
          break;
      }
    },
    sortByPlatformDesc: (state, action) => {
      switch (action.payload.module) {
        case "offers":
          state.offers.sortBy = "platform-desc";
          break;
        case "platforms":
          state.platforms = state.platforms.map((platform) => {
            if (platform.id === action.payload.id) {
              const updatedFilters = {
                ...platform.filters,
                sortBy: "platform-desc",
              };
              return { ...platform, filters: updatedFilters };
            } else {
              return platform;
            }
          });
          break;
        case "adFormats":
          state.adFormats = state.adFormats.map((adFormat) => {
            if (adFormat.id === action.payload.id) {
              const updatedFilters = {
                ...adFormat.filters,
                sortBy: "platform-desc",
              };
              return { ...adFormat, filters: updatedFilters };
            } else {
              return adFormat;
            }
          });
          break;
        case "specTemplates":
          state.specTemplates = state.specTemplates.map((specTemplate) => {
            if (specTemplate.id === action.payload.id) {
              const updatedFilters = {
                ...specTemplate.filters,
                sortBy: "platform-desc",
              };
              return { ...specTemplate, filters: updatedFilters };
            } else {
              return specTemplate;
            }
          });
          break;
        case "countries":
          state.countries = state.countries.map((country) => {
            if (country.id === action.payload.id) {
              const updatedFilters = {
                ...country.filters,
                sortBy: "platform-desc",
              };
              return { ...country, filters: updatedFilters };
            } else {
              return country;
            }
          });
          break;
        case "adOpsObjectives":
          state.adOpsObjectives = state.adOpsObjectives.map(
            (adOpsObjective) => {
              if (adOpsObjective.id === action.payload.id) {
                const updatedFilters = {
                  ...adOpsObjective.filters,
                  sortBy: "platform-desc",
                };
                return { ...adOpsObjective, filters: updatedFilters };
              } else {
                return adOpsObjective;
              }
            }
          );
          break;
        default:
          break;
      }
    },
    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "offers":
          state.offers = initialFilters;
          break;
        case "platforms":
          if (
            !state.platforms.find(
              (platform) => platform.id === action.payload.id
            )
          ) {
            state.platforms.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        case "adFormats":
          if (
            !state.adFormats.find(
              (adFormat) => adFormat.id === action.payload.id
            )
          ) {
            state.adFormats.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        case "specTemplates":
          if (
            !state.specTemplates.find(
              (specTemplate) => specTemplate.id === action.payload.id
            )
          ) {
            state.specTemplates.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        case "countries":
          if (
            !state.countries.find((country) => country.id === action.payload.id)
          ) {
            state.countries.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        case "adOpsObjectives":
          if (
            !state.adOpsObjectives.find(
              (adOpsObjective) => adOpsObjective.id === action.payload.id
            )
          ) {
            state.adOpsObjectives.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  sortByNameAsc,
  sortByNameDesc,
  sortByPlatformAsc,
  sortByPlatformDesc,
  initFilterSet,
} = offerFiltersSlice.actions;
export const offerFiltersReducer = offerFiltersSlice.reducer;
