import fetchServer from "../helpers/api/fetchServer";

export const getAdAccounts = async () => {
  const method = "GET";
  const urlAPI = "/api/adAccounts";
  return await fetchServer({ method, urlAPI });
};

export const getAdAccountsByAccount = async (accountId) => {
  const method = "GET";
  const urlAPI = `/api/adAccounts?account=${accountId}`;
  return await fetchServer({ method, urlAPI });
};

export const getAdAccountsByAdPlatform = async (adPlatformId) => {
  const method = "GET";
  const urlAPI = `/api/adAccounts?adPlatform=${adPlatformId}`;
  return await fetchServer({ method, urlAPI });
};

export const getAdAccount = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adAccounts/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updateAdAccount = async ({ id, accountIds }) => {
  const method = "PUT";
  const urlAPI = `/api/adAccounts/${id}`;
  const body = { accountIds };
  return await fetchServer({ method, urlAPI, body });
};

