import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { lineItemRouteFactory } from "./lineItem";
import {
  campaignListLoader,
  campaignFormLoader,
  campaignViewLoader,
} from "../loaders/campaign";
import { lineItemListByCampaignLoader } from "../loaders/lineItem";
import { CampaignBreadcrumb } from "../../breadcrumbs/campaign";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { CAMPAIGN, LINEITEM } = ENTITY;

export const campaignRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const campaignRoute = createRoute({
    getParentRoute: () => parent,
    path: CAMPAIGN.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: CAMPAIGN.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: CAMPAIGN.label.upFirstPlural,
    },
  });

  // List campaigns

  const campaignListRoute = createRoute({
    getParentRoute: () => campaignRoute,
    path: "/",
    loader: campaignListLoader,
    pendingComponent: () => <EntityListLoading entity={CAMPAIGN} />,
  }).lazy(() => import("./campaign.lazy").then((d) => d.CampaignListRoute));

  // Create campaign

  const campaignCreateRoute = createRoute({
    getParentRoute: () => campaignRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: CAMPAIGN.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    loader: campaignFormLoader,
    pendingComponent: () => <EntityCreateLoading entity={CAMPAIGN} />,
  }).lazy(() => import("./campaign.lazy").then((d) => d.CampaignCreateRoute));

  // View campaign

  const campaignViewRootRoute = createRoute({
    getParentRoute: () => campaignRoute,
    path: "$campaignId",
    staticData: {
      breadcrumb: CampaignBreadcrumb,
    },
    loader: campaignViewLoader,
  });

  // Edit campaign

  const campaignEditRoute = createRoute({
    getParentRoute: () => campaignViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    loader: campaignFormLoader,
    pendingComponent: () => <EntityEditLoading entity={CAMPAIGN} />,
  }).lazy(() => import("./campaign.lazy").then((d) => d.CampaignEditRoute));

  // View campaign Layout

  const campaignViewLayoutRoute = createRoute({
    getParentRoute: () => campaignViewRootRoute,
    id: "campaign-view-layout",
    pendingComponent: () => <EntityViewLoading entity={CAMPAIGN} />,
  }).lazy(() =>
    import("./campaign.lazy").then((d) => d.CampaignViewLayoutRoute)
  );

  // View campaign - General Tab

  const campaignViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => campaignViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./campaign.lazy").then((d) => d.CampaignViewLayoutGeneralTabRoute)
  );

  // View campaign - Structure Tab

  const campaignViewLayoutStructureTabRoute = createRoute({
    getParentRoute: () => campaignViewLayoutRoute,
    path: "structure",
    staticData: {
      breadcrumb: "Structure",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./campaign.lazy").then((d) => d.CampaignViewLayoutStructureTabRoute)
  );

  // View campaign - Workflow Tab

  const campaignViewLayoutWorkflowTabRoute = createRoute({
    getParentRoute: () => campaignViewLayoutRoute,
    path: "workflow",
    staticData: {
      breadcrumb: "Workflow",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./campaign.lazy").then((d) => d.CampaignViewLayoutWorkflowTabRoute)
  );

  // View campaign - About Tab

  const campaignViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => campaignViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./campaign.lazy").then((d) => d.CampaignViewLayoutAboutTabRoute)
  );

  // View campaign - LineItems Tab

  const campaignViewLayoutLineItemPaginatedListTabRoute = createRoute({
    getParentRoute: () => campaignViewLayoutRoute,
    path: LINEITEM.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: LINEITEM.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: LINEITEM.label.upFirstPlural,
    },
    loader: lineItemListByCampaignLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./campaign.lazy").then(
      (d) => d.CampaignViewLayoutLineItemPaginatedListTabRoute
    )
  );

  // Campaign Children
  const lineItemRoute = lineItemRouteFactory(campaignViewRootRoute);

  // Route tree
  campaignRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [campaignListRoute]),
    campaignCreateRoute,
    campaignViewRootRoute.addChildren([
      campaignEditRoute,
      campaignViewLayoutRoute.addChildren([
        campaignViewLayoutGeneralTabRoute,
        campaignViewLayoutStructureTabRoute,
        campaignViewLayoutWorkflowTabRoute,
        campaignViewLayoutAboutTabRoute,
        campaignViewLayoutLineItemPaginatedListTabRoute,
      ]),
      lineItemRoute,
    ]),
  ]);

  return campaignRoute;
};

const campaignRoute = campaignRouteFactory();

export default campaignRoute;
