import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "startDate-desc",
  // This boolean allows the component to know that initFilterSet has run
  // This is useful for paginated entities
  isInitFiltersFinished: true
};

// INITIAL STATE
const initialState = {
  ETLTasks: initialFilters,
  ETLRuns: [],
};

const ETLTaskFiltersSlice = createSlice({
  name: "ETLTaskFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "ETLTasks":
          state.ETLTasks.tab = action.payload.tab;
          break;
        case "ETLRuns":
          state.ETLRuns = state.ETLRuns.map((ETLRun) => {
            if (ETLRun.id === action.payload.id) {
              const updatedFilters = {
                ...ETLRun.filters,
                tab: action.payload.tab,
              };
              return { ...ETLRun, filters: updatedFilters };
            } else {
              return ETLRun;
            }
          });
          break;
        
        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "ETLTasks":
          state.ETLTasks.text = action.payload.text;
          break;
        case "ETLRuns":
          state.ETLRuns = state.ETLRuns.map((ETLRun) => {
            if (ETLRun.id === action.payload.id) {
              const updatedFilters = {
                ...ETLRun.filters,
                text: action.payload.text,
              };
              return { ...ETLRun, filters: updatedFilters };
            } else {
              return ETLRun;
            }
          });
          break;
        
        default:
          break;
      }
    },
    sortByTypeAsc: (state, action) => {
      switch (action.payload.module) {
        case "ETLTasks":
          state.ETLTasks.sortBy = "type-asc";
          break;
        case "ETLRuns":
          state.ETLRuns = state.ETLRuns.map((ETLRun) => {
            if (ETLRun.id === action.payload.id) {
              const updatedFilters = {
                ...ETLRun.filters,
                sortBy: "type-asc",
              };
              return { ...ETLRun, filters: updatedFilters };
            } else {
              return ETLRun;
            }
          });
          break;
        
        default:
          break;
      }
    },
    sortByTypeDesc: (state, action) => {
      switch (action.payload.module) {
        case "ETLTasks":
          state.ETLTasks.sortBy = "type-desc";
          break;
        case "ETLRuns":
          state.ETLRuns = state.ETLRuns.map((ETLRun) => {
            if (ETLRun.id === action.payload.id) {
              const updatedFilters = {
                ...ETLRun.filters,
                sortBy: "type-desc",
              };
              return { ...ETLRun, filters: updatedFilters };
            } else {
              return ETLRun;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByStartDateAsc: (state, action) => {
      switch (action.payload.module) {
        case "ETLTasks":
          state.ETLTasks.sortBy = "startDate-asc";
          break;
        case "ETLRuns":
          state.ETLRuns = state.ETLRuns.map((ETLRun) => {
            if (ETLRun.id === action.payload.id) {
              const updatedFilters = {
                ...ETLRun.filters,
                sortBy: "startDate-asc",
              };
              return { ...ETLRun, filters: updatedFilters };
            } else {
              return ETLRun;
            }
          });
          break;
        
        default:
          break;
      }
    },
    sortByStartDateDesc: (state, action) => {
      switch (action.payload.module) {
        case "ETLTasks":
          state.ETLTasks.sortBy = "startDate-desc";
          break;
        case "ETLRuns":
          state.ETLRuns = state.ETLRuns.map((ETLRun) => {
            if (ETLRun.id === action.payload.id) {
              const updatedFilters = {
                ...ETLRun.filters,
                sortBy: "startDate-desc",
              };
              return { ...ETLRun, filters: updatedFilters };
            } else {
              return ETLRun;
            }
          });
          break;
        
        default:
          break;
      }
    },
    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "ETLTasks":
          state.ETLTasks = initialFilters;
          break;
        case "ETLRuns":
          if (
            !state.ETLRuns.find((ETLRun) => ETLRun.id === action.payload.id)
          ) {
            state.ETLRuns.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  sortByTypeAsc,
  sortByTypeDesc,
  sortByStartDateAsc,
  sortByStartDateDesc,
  initFilterSet,
} = ETLTaskFiltersSlice.actions;
export const ETLTaskFiltersReducer = ETLTaskFiltersSlice.reducer;
