import { Link as RouterLink } from "@tanstack/react-router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Box, Typography, Button } from "@material-ui/core";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import PageWrapper from "../../components/layouts/app/PageWrapper";

const EntityNotFound = () => {
  // Hooks
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PageWrapper
      title={`Not found`}
      browserPageTitle={`Not found`}
    >
      <Box sx={{ mt: 10 }}>
        <Typography
          align="center"
          color="textPrimary"
          variant={mobileDevice ? "h4" : "h1"}
        >
          Not found
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          sx={{ mt: 0.5 }}
          variant="subtitle2"
        >
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 6,
          }}
        >
          <Box
            alt={`Not found`}
            component="img"
            src={`/static/error/error404_${theme.palette.mode}.svg`}
            sx={{
              height: "auto",
              maxWidth: "100%",
              width: 350,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 6,
          }}
        >
          <Button
            color="primary"
            component={RouterLink}
            startIcon={<ArrowLeftIcon fontSize="small" />}
            to={"/app"}
            variant="outlined"
          >
            Home
          </Button>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default EntityNotFound;
