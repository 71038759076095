import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import Spinner from "../../components/general/Spinner";

const postBoostDoctorRoute = createRoute({
  getParentRoute: () => appRoute,
  path: "post-boost-doctor",
  beforeLoad: () =>
    checkPermissionsAndRedirect([
      { feature: "postBoostDoctor", action: "READ" },
    ]),
  staticData: {
    breadcrumb: "Doctor (Post boost)",
  },
  pendingComponent: Spinner,
}).lazy(() =>
  import("./postBoostDoctor.lazy").then((d) => d.PostBoostDoctorRoute)
);

export default postBoostDoctorRoute;
