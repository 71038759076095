import { createRoute } from "@tanstack/react-router";
import AppLayout from "../../components/layouts/app/AppLayout";
import AuthGuard from "../../components/routes/AuthGuard";
import AuthProvider from "../../components/routes/AuthProvider";
import { rootRoute } from "../index";
import NotFound from "../../pages/NotFound";
import Spinner from "../../components/general/Spinner";

export const appRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "app",
  staticData: {
    breadcrumb: 'Home',
  },
  component: () => (
    <AuthProvider>
      <AuthGuard>
        <AppLayout />
      </AuthGuard>
    </AuthProvider>
  ),
  pendingComponent: Spinner,
  notFoundComponent: () => <NotFound origin="app"/>
});

export const appHomeRoute = createRoute({
  getParentRoute: () => appRoute,
  path: "/",
}).lazy(() => import("./index.lazy").then((d) => d.AppHomeRoute));

