import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetDataOrigin } from "../queries/dataOrigins";
import { ENTITY } from "../constants";

export const DataOriginBreadcrumb = ({ match }) => {
  // Entity
  const { DATAORIGIN } = ENTITY;

    const {
      data: dataOrigin,
      isLoading,
      isError,
      error,
    } = useGetDataOrigin(match.params.dataOriginId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (dataOrigin) {
      return <span>{trimString(dataOrigin[DATAORIGIN.nameField], 20)}</span>;
    }
  
    return null;
  };