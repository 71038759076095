import ServerError from "./ServerError";
import UnknownError from "./UnknownError";
import UnauthorizedError from "./UnauthorizedError";
import {
  PermissionException,
  NotFoundException,
  ServerException,
  UnauthorizedException,
} from "../../customExceptions";
import NotFound from "../../pages/NotFound";
import EntityNotFound from "../../pages/entities/EntityNotFound";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { useEffect } from "react";

// reset in the props is rovided by tanstack router
// when we reset => it will force to remount the routes
const ErrorFallback = ({ error, reset }) => {
  const { reset: resetQuery } = useQueryErrorResetBoundary();
  useEffect(() => {
    // Reset the query error boundary
    resetQuery();
  }, [resetQuery]);

  if (error instanceof UnauthorizedException) {
    return <UnauthorizedError />;
  }
  if (error instanceof PermissionException) {
    return <NotFound origin={"app"} />;
  }
  if (error instanceof NotFoundException) {
    return <EntityNotFound />;
  }
  if (error instanceof ServerException) {
    return <ServerError reset={reset} />;
  }
  return <UnknownError reset={reset} />;
};

export default ErrorFallback;