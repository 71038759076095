import {
  getPlatformsQueryOptions,
  getPlatformQueryOptions,
} from "../../queries/platforms";
import { getAdPlatformsQueryOptions } from "../../queries/adPlatforms";

// necessary entities are pre-fetched when a user hover a 'create platform' or a 'edit platform' link
export const platformFormLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getAdPlatformsQueryOptions());
};

// platforms are pre-fetched when a user hover a 'platforms' link
export const platformListLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getPlatformsQueryOptions());
};

// platform is pre-fetched when a user hover a 'view platform' link
export const platformViewLoader = ({ params, context: { queryClient } }) => {
  const { platformId } = params;
  queryClient.ensureQueryData(getPlatformQueryOptions(platformId, queryClient));
};
