import { createSlice } from "@reduxjs/toolkit";

// INITIAL STATE
const initialPagination = {
  page: 1,
  nbDocsPerPage: 25,
};

// INITIAL STATE
const initialState = {
  ETLRuns: initialPagination
};

const ETLRunPaginationSlice = createSlice({
  name: "ETLRunPagination",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.ETLRuns.page = action.payload.page;
    },
    setNbDocsPerPage: (state, action) => {
      state.ETLRuns.nbDocsPerPage = action.payload.nbDocsPerPage;
    },
  },
});

export const { setPage, setNbDocsPerPage } = ETLRunPaginationSlice.actions;
export const ETLRunPaginationReducer = ETLRunPaginationSlice.reducer;
