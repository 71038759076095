import {
  getPostBoostCampaignQueryOptions,
  getPaginatedPostBoostCampaignsByPostQueryOptions,
} from "../../queries/postBoostCampaigns";

// postBoostCampaigns are pre-fetched when a user hover a 'postBoostCampaigns' link within a post
export const postBoostCampaignListByPostLoader = ({
  params,
  context: { queryClient },
}) => {
  const { postId } = params;

  // REFACTOR
  // These temporary settings are redux slices initial values
  const filterText = "";
  const filterDeleted = false;
  const sortBy = "name-desc";
  const page = 1;
  const nbDocsPerPage = 25;

  queryClient.ensureQueryData(
    getPaginatedPostBoostCampaignsByPostQueryOptions({
      postId,
      filterText,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// postBoostCampaign is pre-fetched when a user hover a 'view postBoostCampaign' link
export const postBoostCampaignViewLoader = ({
  params,
  context: { queryClient },
}) => {
  const { postBoostCampaignId } = params;
  queryClient.ensureQueryData(
    getPostBoostCampaignQueryOptions(postBoostCampaignId, queryClient)
  );
};
