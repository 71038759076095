import { createSlice } from "@reduxjs/toolkit";

const initialPagination = {
  page: 1,
  nbDocsPerPage: 25,
};

// INITIAL STATE
const initialState = {
  adOpsCampaigns: initialPagination,
  lineItems: [],
};

const adOpsCampaignPaginationSlice = createSlice({
  name: "adOpsCampaignPagination",
  initialState,
  reducers: {
    setPage: (state, action) => {
      switch (action.payload.module) {
        case "adOpsCampaigns":
          state.adOpsCampaigns.page = action.payload.page;
          break;
        case "lineItems":
          state.lineItems = state.lineItems.map((lineItem) => {
            if (lineItem.id === action.payload.id) {
              const updatedPagination = {
                ...lineItem.pagination,
                page: action.payload.page,
              };
              return { ...lineItem, pagination: updatedPagination };
            } else {
              return lineItem;
            }
          });
          break;
        
        default:
          break;
      }
    },
    setNbDocsPerPage: (state, action) => {
      switch (action.payload.module) {
        case "adOpsCampaigns":
          state.adOpsCampaigns.nbDocsPerPage = action.payload.nbDocsPerPage;
          break;
        case "lineItems":
          state.lineItems = state.lineItems.map((lineItem) => {
            if (lineItem.id === action.payload.id) {
              const updatedPagination = {
                ...lineItem.pagination,
                nbDocsPerPage: action.payload.nbDocsPerPage,
              };
              return { ...lineItem, pagination: updatedPagination };
            } else {
              return lineItem;
            }
          });
          break;
        
        default:
          break;
      }
    },
    initPaginationSet: (state, action) => {
      switch (action.payload.module) {
        case "adOpsCampaigns":
          state.adOpsCampaigns = initialPagination;
          break;
        case "lineItems":
          if (
            !state.lineItems.find((lineItem) => lineItem.id === action.payload.id)
          ) {
            state.lineItems.push({
              id: action.payload.id,
              pagination: initialPagination,
            });
          }
          break;
       
        default:
          break;
      }
    },
  },
});

export const {
  setPage,
  setNbDocsPerPage,
  initPaginationSet,
} = adOpsCampaignPaginationSlice.actions;
export const adOpsCampaignPaginationReducer = adOpsCampaignPaginationSlice.reducer;
