import * as api from "../apis/usersApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getUsersQueryOptions = () =>
  queryOptions({
    queryKey: ["users"],
    queryFn: api.getUsers,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getUserQueryOptions = (id, queryClient) =>
  queryOptions({
    queryKey: ["user", id],
    queryFn: () => api.getUser(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient.getQueryData(["users"])?.find((user) => user.id === id),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["users"])?.dataUpdatedAt,
  });

export const getUserPermissionsQueryOptions = (userId) =>
  queryOptions({
    queryKey: ["userPermissions", userId],
    queryFn: () => api.getUserPermissions(userId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

// useQuery

export const useGetUsers = () => useQuery(getUsersQueryOptions());

export const useGetUser = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getUserQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetUsers = () =>
  useSuspenseQuery(getUsersQueryOptions());

export const useSuspenseGetUser = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getUserQueryOptions(id, queryClient));
};

export const useSuspenseGetUserPermissions = (userId) => {
  return useSuspenseQuery(getUserPermissionsQueryOptions(userId));
};