import { useRef, useState, Fragment } from "react";
import { Link as RouterLink, useNavigate } from "@tanstack/react-router";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";
import CogIcon from "../../../icons/Cog";
import LoadingButton from "../../general/LoadingButton";
import { setLoggedOut } from "../../../slices/auth";
import { useLogout } from "../../../mutations/auth";

const AccountPopover = () => {
  // Hooks

  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);

  // Mutations

  const { isPending: isDoingLogout, mutate: mutateLogout } = useLogout();

  // Handle Account Pop-over

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Handle Logout

  const handleLogout = async () => {
    mutateLogout(null, {
      onSettled: () => {
        dispatch(setLoggedOut());
        navigate({to: "/app"});
      },
      onError: () => {
        toast.error("Logout failed");
      },
      onSuccess: () => {
        toast.success(`See you soon, ${currentUser.firstName} !`);
      },
    });
  };

  // Rendering

  return (
    <Fragment>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          src={currentUser.avatar}
          sx={{
            height: 32,
            width: 32,
          }}
        >
          <Typography variant="h6">{currentUser.initials}</Typography>
        </Avatar>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {currentUser.fullName}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            #NYAC
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem component={RouterLink} to="/app/me">
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Settings
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <LoadingButton
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
            isLoading={isDoingLogout}
            loadingText="Logout"
          >
            Logout
          </LoadingButton>
        </Box>
      </Popover>
    </Fragment>
  );
};

export default AccountPopover;
