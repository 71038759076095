import * as api from "../apis/specTemplatesApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getSpecTemplatesQueryOptions = () =>
  queryOptions({
    queryKey: ["specTemplates"],
    queryFn: api.getSpecTemplates,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getSpecTemplateQueryOptions = (id, queryClient) =>
  queryOptions({
    queryKey: ["specTemplate", id],
    queryFn: () => api.getSpecTemplate(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["specTemplates"])
        ?.find((specTemplate) => specTemplate.id === id),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["specTemplates"])?.dataUpdatedAt,
  });

// useQuery

export const useGetSpecTemplates = () => useQuery(getSpecTemplatesQueryOptions());

export const useGetSpecTemplate = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getSpecTemplateQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetSpecTemplates = () =>
  useSuspenseQuery(getSpecTemplatesQueryOptions());

export const useSuspenseGetSpecTemplate = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getSpecTemplateQueryOptions(id, queryClient));
};
