import { getAdOpsObjectiveQueryOptions } from "../../queries/adOpsObjectives";

// adOpsObjective is pre-fetched when a user hover a 'view adOpsObjective' link
export const adOpsObjectiveViewLoader = ({
  params,
  context: { queryClient },
}) => {
  const { adOpsObjectiveId } = params;
  queryClient.ensureQueryData(
    getAdOpsObjectiveQueryOptions(adOpsObjectiveId, queryClient)
  );
};
