import {
  getAdvertiserQueryOptions,
  getAdvertisersQueryOptions,
} from "../../queries/advertisers";

// advertisers are pre-fetched when a user hover a 'advertisers' link
export const advertiserListLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getAdvertisersQueryOptions());
};

// advertiser is pre-fetched when a user hover a 'view advertiser' link
export const advertiserViewLoader = ({ params, context: { queryClient } }) => {
  const { advertiserId } = params;
  queryClient.ensureQueryData(
    getAdvertiserQueryOptions(advertiserId, queryClient)
  );
};
