import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetETLRun } from "../queries/ETLRuns";
import { ENTITY } from "../constants";

export const ETLRunBreadcrumb = ({ match }) => {
  // Entity
  const { ETLRUN } = ENTITY;

    const {
      data: ETLRun,
      isLoading,
      isError,
      error,
    } = useGetETLRun(match.params.ETLRunId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (ETLRun) {
      return <span>{trimString(ETLRun[ETLRUN.nameField], 20)}</span>;
    }
  
    return null;
  };

  