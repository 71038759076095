import fetchServer from "../helpers/api/fetchServer";
import fetchServerUpdateImage from "../helpers/api/fetchServerUpdateImage";

export const createBrand = async ({ name, advertiserId }) => {
  const method = "POST";
  const urlAPI = "/api/brands";
  const body = {
    name,
    advertiserId
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getBrands = async () => {
  const method = "GET";
  const urlAPI = "/api/brands";
  return await fetchServer({ method, urlAPI });
};

export const getBrandsByAdvertiser = async (advertiserId) => {
  const method = "GET";
  const urlAPI = `/api/brands?advertiser=${advertiserId}`;
  return await fetchServer({ method, urlAPI });
};

export const getBrand = async (id) => {
  const method = "GET";
  const urlAPI = `/api/brands/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updateBrand = async ({ id, name, advertiserId }) => {
  const method = "PUT";
  const urlAPI = `/api/brands/${id}`;
  const body = { name, advertiserId };
  return await fetchServer({ method, urlAPI, body });
};

export const updateBrandLogo = async ({ id, formData }) => {
  const urlAPI = `/api/brands/${id}/logo`;
  return await fetchServerUpdateImage({ urlAPI, formData });
};

export const deleteBrandLogo = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/brands/${id}/logo`;
  return await fetchServer({ method, urlAPI });
};

export const deleteBrand = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/brands/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreBrand = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/brands/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteBrands = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/brands/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreBrands = async (arrayIds) => {
  const method = "POST";
  const urlAPI = '/api/brands/restore-many';
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};