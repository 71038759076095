import fetchServer from "../helpers/api/fetchServer";

export const createSpecTemplate = async ({
  name,
  isClickUrlMandatory,
  wordingFields,
  assetsGuideline,
  wordingGuideline,
  wordingIsNeededWhenPost,
  postUrlIsNeededWhenDarkPost,
  adOpsGuideUrl,
}) => {
  const method = "POST";
  const urlAPI = "/api/specTemplates";
  const body = {
    name,
    isClickUrlMandatory,
    wordingFields,
    assetsGuideline,
    wordingGuideline,
    wordingIsNeededWhenPost,
    postUrlIsNeededWhenDarkPost,
    adOpsGuideUrl,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getSpecTemplates = async () => {
  const method = "GET";
  const urlAPI = "/api/specTemplates";
  return await fetchServer({ method, urlAPI });
};

export const getSpecTemplate = async (id) => {
  const method = "GET";
  const urlAPI = `/api/specTemplates/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updateSpecTemplate = async ({
  id,
  name,
  isClickUrlMandatory,
  wordingFields,
  assetsGuideline,
  wordingGuideline,
  wordingIsNeededWhenPost,
  postUrlIsNeededWhenDarkPost,
  adOpsGuideUrl,
}) => {
  const method = "PUT";
  const urlAPI = `/api/specTemplates/${id}`;
  const body = {
    name,
    isClickUrlMandatory,
    wordingFields,
    assetsGuideline,
    wordingGuideline,
    wordingIsNeededWhenPost,
    postUrlIsNeededWhenDarkPost,
    adOpsGuideUrl,
  };
  return await fetchServer({ method, urlAPI, body });
};
export const deleteSpecTemplate = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/specTemplates/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreSpecTemplate = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/specTemplates/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteSpecTemplates = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/specTemplates/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreSpecTemplates = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/specTemplates/restore-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};
