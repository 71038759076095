import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "name-asc",
};

// INITIAL STATE
const initialState = {
  adAccounts: initialFilters,
  adPlatforms: [],
};

const adAccountFiltersSlice = createSlice({
  name: "adAccountFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "adAccounts":
          state.adAccounts.tab = action.payload.tab;
          break;
        case "adPlatforms":
          state.adPlatforms = state.adPlatforms.map((adPlatform) => {
            if (adPlatform.id === action.payload.id) {
              const updatedFilters = {
                ...adPlatform.filters,
                tab: action.payload.tab,
              };
              return { ...adPlatform, filters: updatedFilters };
            } else {
              return adPlatform;
            }
          });
          break;

        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "adAccounts":
          state.adAccounts.text = action.payload.text;
          break;
        case "adPlatforms":
          state.adPlatforms = state.adPlatforms.map((adPlatform) => {
            if (adPlatform.id === action.payload.id) {
              const updatedFilters = {
                ...adPlatform.filters,
                text: action.payload.text,
              };
              return { ...adPlatform, filters: updatedFilters };
            } else {
              return adPlatform;
            }
          });
          break;
       
        default:
          break;
      }
    },
    sortByNameAsc: (state, action) => {
      switch (action.payload.module) {
        case "adAccounts":
          state.adAccounts.sortBy = "name-asc";
          break;
        case "adPlatforms":
          state.adPlatforms = state.adPlatforms.map((adPlatform) => {
            if (adPlatform.id === action.payload.id) {
              const updatedFilters = {
                ...adPlatform.filters,
                sortBy: "name-asc",
              };
              return { ...adPlatform, filters: updatedFilters };
            } else {
              return adPlatform;
            }
          });
          break;
        

        default:
          break;
      }
    },
    sortByNameDesc: (state, action) => {
      switch (action.payload.module) {
        case "adAccounts":
          state.adAccounts.sortBy = "name-desc";
          break;
        case "adPlatforms":
          state.adPlatforms = state.adPlatforms.map((adPlatform) => {
            if (adPlatform.id === action.payload.id) {
              const updatedFilters = {
                ...adPlatform.filters,
                sortBy: "name-desc",
              };
              return { ...adPlatform, filters: updatedFilters };
            } else {
              return adPlatform;
            }
          });
          break;

        default:
          break;
      }
    },
    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "adAccounts":
          state.adAccounts = initialFilters;
          break;
        case "adPlatforms":
          if (
            !state.adPlatforms.find(
              (adPlatform) => adPlatform.id === action.payload.id
            )
          ) {
            state.adPlatforms.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        
        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  sortByNameAsc,
  sortByNameDesc,
  initFilterSet,
} = adAccountFiltersSlice.actions;

export const adAccountFiltersReducer = adAccountFiltersSlice.reducer;
