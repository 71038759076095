import fetchServer from "../helpers/api/fetchServer";
import fetchServerUpdateImage from "../helpers/api/fetchServerUpdateImage";

export const createPlatform = async ({
  name,
  shortName,
  url,
  postIsCalled,
  adPlatformId,
}) => {
  const method = "POST";
  const urlAPI = "/api/platforms";
  const body = {
    name,
    shortName,
    url,
    postIsCalled,
    adPlatformId,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getPlatforms = async () => {
  const method = "GET";
  const urlAPI = "/api/platforms";
  return await fetchServer({ method, urlAPI });
};

export const getPlatform = async (id) => {
  const method = "GET";
  const urlAPI = `/api/platforms/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updatePlatform = async ({
  id,
  name,
  shortName,
  url,
  postIsCalled,
  adPlatformId,
}) => {
  const method = "PUT";
  const urlAPI = `/api/platforms/${id}`;
  const body = { name, shortName, url, postIsCalled, adPlatformId };
  return await fetchServer({ method, urlAPI, body });
};

export const updatePlatformLogo = async ({ id, formData }) => {
  const urlAPI = `/api/platforms/${id}/logo`;
  return await fetchServerUpdateImage({ urlAPI, formData });
};

export const deletePlatformLogo = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/platforms/${id}/logo`;
  return await fetchServer({ method, urlAPI });
};

export const deletePlatform = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/platforms/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restorePlatform = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/platforms/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deletePlatforms = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/platforms/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restorePlatforms = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/platforms/restore-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};
