import fetchServer from "../helpers/api/fetchServer";

export const createMetric = async ({
  name,
  nameFrench,
  field,
  pricingQuanta,
  platformId,
}) => {
  const method = "POST";
  const urlAPI = "/api/metrics";
  const body = {
    name,
    nameFrench,
    field,
    pricingQuanta,
    platformId,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getMetrics = async () => {
  const method = "GET";
  const urlAPI = "/api/metrics";
  return await fetchServer({ method, urlAPI });
};

export const getMetricsByPlatform = async (platformId) => {
  const method = "GET";
  const urlAPI = `/api/metrics?platform=${platformId}`;
  return await fetchServer({ method, urlAPI });
};

export const getMetric = async (id) => {
  const method = "GET";
  const urlAPI = `/api/metrics/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updateMetric = async ({
  id,
  name,
  nameFrench,
  field,
  pricingQuanta,
  platformId,
}) => {
  const method = "PUT";
  const urlAPI = `/api/metrics/${id}`;
  const body = {
    name,
    nameFrench,
    field,
    pricingQuanta,
    platformId,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const deleteMetric = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/metrics/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreMetric = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/metrics/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteMetrics = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/metrics/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreMetrics = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/metrics/restore-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};
