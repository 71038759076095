import * as api from "../apis/adAccountsApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getAdAccountsQueryOptions = () =>
  queryOptions({
    queryKey: ["adAccounts"],
    queryFn: api.getAdAccounts,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getAdAccountsByAccountQueryOptions = (accountId, queryClient) =>
  queryOptions({
    queryKey: ["account.adAccounts", accountId],
    queryFn: () => api.getAdAccountsByAccount(accountId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["adAccounts"])
        ?.filter((adAccount) =>
          adAccount.accounts.find((acc) => acc.id === accountId)
        ),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["adAccounts"])?.dataUpdatedAt,
  });

export const getAdAccountsByAdPlatformQueryOptions = (
  adPlatformId,
  queryClient
) =>
  queryOptions({
    queryKey: ["adPlatform.adAccounts", adPlatformId],
    queryFn: () => api.getAdAccountsByAdPlatform(adPlatformId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["adAccounts"])
        ?.filter((adAccount) => adAccount.adPlatform.id === adPlatformId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["adAccounts"])?.dataUpdatedAt,
  });

export const getAdAccountQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["adAccount", id],
    queryFn: () => api.getAdAccount(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // if "adAccounts" query exist, we use it to init data
      if (queryClient.getQueryState(["adAccounts"])) {
        queryKey = ["adAccounts"];
        return queryClient
          .getQueryData(["adAccounts"])
          ?.find((adAccount) => adAccount.id === id);
      }

      // otherwise, we try to find the adAccount in one of the "account.adAccounts" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["account.adAccounts"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((adAccount) => {
            if (adAccount.id === id) {
              found = adAccount;
              queryKey = query[0];
            }
          });
        });

      // otherwise, we try to find the adAccount in one of the "adPlatform.adAccounts" queries
      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["adPlatform.adAccounts"] })
          .forEach((query) => {
            const data = query[1];
            data.forEach((adAccount) => {
              if (adAccount.id === id) {
                found = adAccount;
                queryKey = query[0];
              }
            });
          });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

// useQuery

export const useGetAdAccountsByAccount = (accountId) => {
  const queryClient = useQueryClient();
  return useQuery(getAdAccountsByAccountQueryOptions(accountId, queryClient));
};

export const useGetAdAccountsByAdPlatform = (adPlatformId) => {
  const queryClient = useQueryClient();
  return useQuery(
    getAdAccountsByAdPlatformQueryOptions(adPlatformId, queryClient)
  );
};

export const useGetAdAccount = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getAdAccountQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetAdAccounts = () =>
  useSuspenseQuery(getAdAccountsQueryOptions());

export const useSuspenseGetAdAccountsByAdPlatform = (adPlatformId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getAdAccountsByAdPlatformQueryOptions(adPlatformId, queryClient)
  );
};

export const useSuspenseGetAdAccount = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getAdAccountQueryOptions(id, queryClient));
};
