import * as api from "../apis/campaignsApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getCampaignsQueryOptions = () =>
  queryOptions({
    queryKey: ["campaigns"],
    queryFn: api.getCampaigns,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getCampaignsByAdvertiserQueryOptions = (
  advertiserId,
  queryClient
) =>
  queryOptions({
    queryKey: ["advertiser.campaigns", advertiserId],
    queryFn: () => api.getCampaignsByAdvertiser(advertiserId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["campaigns"])
        ?.filter((campaign) => campaign.advertiser.id === advertiserId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["campaigns"])?.dataUpdatedAt,
  });

export const getCampaignsByBrandQueryOptions = (brandId, queryClient) =>
  queryOptions({
    queryKey: ["brand.campaigns", brandId],
    queryFn: () => api.getCampaignsByBrand(brandId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["campaigns"])
        ?.filter((campaign) => campaign.brand.id === brandId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["campaigns"])?.dataUpdatedAt,
  });

export const getCampaignQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["campaign", id],
    queryFn: () => api.getCampaign(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // if "campaigns" query exist, we use it to init data
      if (queryClient.getQueryState(["campaigns"])) {
        queryKey = ["campaigns"];
        return queryClient
          .getQueryData(["campaigns"])
          ?.find((campaign) => campaign.id === id);
      }

      // otherwise, we try to find the campaign in one of the "advertiser.campaigns" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["advertiser.campaigns"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((campaign) => {
            if (campaign.id === id) {
              found = campaign;
              queryKey = query[0];
            }
          });
        });

      // otherwise, we try to find the campaign in one of the "brand.campaigns" queries

      queryClient
        .getQueriesData({ queryKey: ["brand.campaigns"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((campaign) => {
            if (campaign.id === id) {
              found = campaign;
              queryKey = query[0];
            }
          });
        });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

// useQuery

export const useGetCampaign = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getCampaignQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetCampaigns = () => {
  return useSuspenseQuery(getCampaignsQueryOptions());
};

export const useSuspenseGetCampaignsByAdvertiser = (advertiserId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getCampaignsByAdvertiserQueryOptions(advertiserId, queryClient)
  );
};

export const useSuspenseGetCampaignsByBrand = (brandId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getCampaignsByBrandQueryOptions(brandId, queryClient)
  );
};

export const useSuspenseGetCampaign = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getCampaignQueryOptions(id, queryClient));
};
