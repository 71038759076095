import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "name-asc",
};

// INITIAL STATE
const initialState = {
  dataOrigins: initialFilters,
  accounts: [],
};

// const mockState = {
//   dataOrigins: initialFilters,
//   accounts: [
//     {
//       id: "123",
//       filters: initialFilters,
//     },
//     {
//       id: "456",
//       filters: initialFilters,
//     },
//   ]
// };

const dataOriginFiltersSlice = createSlice({
  name: "dataOriginFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "dataOrigins":
          state.dataOrigins.tab = action.payload.tab;
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                tab: action.payload.tab,
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;
        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "dataOrigins":
          state.dataOrigins.text = action.payload.text;
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                text: action.payload.text,
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByNameAsc: (state, action) => {
      switch (action.payload.module) {
        case "dataOrigins":
          state.dataOrigins.sortBy = "name-asc";
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                sortBy: "name-asc",
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByNameDesc: (state, action) => {
      switch (action.payload.module) {
        case "dataOrigins":
          state.dataOrigins.sortBy = "name-desc";
          break;
        case "accounts":
          state.accounts = state.accounts.map((account) => {
            if (account.id === action.payload.id) {
              const updatedFilters = {
                ...account.filters,
                sortBy: "name-desc",
              };
              return { ...account, filters: updatedFilters };
            } else {
              return account;
            }
          });
          break;

        default:
          break;
      }
    },

    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "dataOrigins":
          state.dataOrigins = initialFilters;
          break;
        case "accounts":
          if (
            !state.accounts.find((account) => account.id === action.payload.id)
          ) {
            state.accounts.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        
        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  sortByNameAsc,
  sortByNameDesc,
  initFilterSet,
} = dataOriginFiltersSlice.actions;
export const dataOriginFiltersReducer = dataOriginFiltersSlice.reducer;
