import fetchServer from "../helpers/api/fetchServer";

export const createOffer = async ({
  name,
  reportingTemplate,
  platformId,
  adFormatId,
  specTemplateId,
  adOpsObjectiveId,
  countryId,
  primaryGoalMetricId,
  primaryGoalDefaultPrice,
  hasSecondaryGoal,
  secondaryGoalMetricId,
  secondaryGoalDefaultPrice,
  shortName,
  useShortNameForAdOpsCampaignName,
}) => {
  const method = "POST";
  const urlAPI = "/api/offers";
  const body = {
    name,
    reportingTemplate,
    platformId,
    adFormatId,
    specTemplateId,
    adOpsObjectiveId,
    countryId,
    primaryGoalMetricId,
    primaryGoalDefaultPrice,
    hasSecondaryGoal,
    secondaryGoalMetricId,
    secondaryGoalDefaultPrice,
    shortName,
    useShortNameForAdOpsCampaignName,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getOffers = async () => {
  const method = "GET";
  const urlAPI = "/api/offers";
  return await fetchServer({ method, urlAPI });
};

export const getOffersByPlatform = async (platformId) => {
  const method = "GET";
  const urlAPI = `/api/offers?platform=${platformId}`;
  return await fetchServer({ method, urlAPI });
};

export const getOffersByCountry = async (countryId) => {
  const method = "GET";
  const urlAPI = `/api/offers?country=${countryId}`;
  return await fetchServer({ method, urlAPI });
};

export const getOffersByAdFormat = async (adFormatId) => {
  const method = "GET";
  const urlAPI = `/api/offers?adFormat=${adFormatId}`;
  return await fetchServer({ method, urlAPI });
};

export const getOffersBySpecTemplate = async (specTemplateId) => {
  const method = "GET";
  const urlAPI = `/api/offers?specTemplate=${specTemplateId}`;
  return await fetchServer({ method, urlAPI });
};

export const getOffersByAdOpsObjective = async (adOpsObjectiveId) => {
  const method = "GET";
  const urlAPI = `/api/offers?adOpsObjective=${adOpsObjectiveId}`;
  return await fetchServer({ method, urlAPI });
};

export const getOffer = async (id) => {
  const method = "GET";
  const urlAPI = `/api/offers/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const getOfferPriceStats = async (id) => {
  const method = "GET";
  const urlAPI = `/api/offers/${id}/priceStats`;
  return await fetchServer({ method, urlAPI });
};

export const updateOffer = async ({
  id,
  name,
  reportingTemplate,
  platformId,
  adFormatId,
  specTemplateId,
  adOpsObjectiveId,
  countryId,
  primaryGoalMetricId,
  primaryGoalDefaultPrice,
  hasSecondaryGoal,
  secondaryGoalMetricId,
  secondaryGoalDefaultPrice,
  shortName,
  useShortNameForAdOpsCampaignName,
}) => {
  const method = "PUT";
  const urlAPI = `/api/offers/${id}`;
  const body = {
    name,
    reportingTemplate,
    platformId,
    adFormatId,
    specTemplateId,
    adOpsObjectiveId,
    countryId,
    primaryGoalMetricId,
    primaryGoalDefaultPrice,
    hasSecondaryGoal,
    secondaryGoalMetricId,
    secondaryGoalDefaultPrice,
    shortName,
    useShortNameForAdOpsCampaignName,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateOfferGeneral = async ({
  id,
  name,
  reportingTemplate,
  platformId,
  adFormatId,
  specTemplateId,
  adOpsObjectiveId,
  countryId,
  shortName,
  useShortNameForAdOpsCampaignName,
}) => {
  const method = "PUT";
  const urlAPI = `/api/offers/${id}/general`;
  const body = {
    name,
    reportingTemplate,
    platformId,
    adFormatId,
    specTemplateId,
    adOpsObjectiveId,
    countryId,
    shortName,
    useShortNameForAdOpsCampaignName,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateOfferPrimaryGoal = async ({
  id,
  primaryGoalMetricId,
  primaryGoalDefaultPrice,
}) => {
  const method = "PUT";
  const urlAPI = `/api/offers/${id}/primary-goal`;
  const body = {
    primaryGoalMetricId,
    primaryGoalDefaultPrice,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const updateOfferSecondaryGoal = async ({
  id,
  hasSecondaryGoal,
  secondaryGoalMetricId,
  secondaryGoalDefaultPrice,
}) => {
  const method = "PUT";
  const urlAPI = `/api/offers/${id}/secondary-goal`;
  const body = {
    hasSecondaryGoal,
    secondaryGoalMetricId,
    secondaryGoalDefaultPrice,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const deleteOffer = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/offers/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreOffer = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/offers/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteOffers = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/offers/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreOffers = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/offers/restore-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};
