import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetMetric } from "../queries/metrics";
import { ENTITY } from "../constants";

export const MetricBreadcrumb = ({ match }) => {
    // Entity
    const { METRIC } = ENTITY;

    const {
      data: metric,
      isLoading,
      isError,
      error,
    } = useGetMetric(match.params.metricId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (metric) {
      return <span>{trimString(metric[METRIC.nameField], 20)}</span>;
    }
  
    return null;
  };