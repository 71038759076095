import {
  getETLTaskQueryOptions,
  getPaginatedETLTasksByETLRunQueryOptions,
} from "../../queries/ETLTasks";

// ETLTasks are pre-fetched when a user hover a 'ETLTasks' link within a ETLRun
export const ETLTaskListByETLRunLoader = ({
  params,
  context: { queryClient },
}) => {
  const { ETLRunId } = params;

  // REFACTOR
  // These temporary settings are redux slices initial values
  const filterText = "";
  const filterStartDate = null;
  const filterEndDate = null;
  const filterWithErrors = false;
  const sortBy = "startDate-desc";
  const page = 1;
  const nbDocsPerPage = 25;

  queryClient.ensureQueryData(
    getPaginatedETLTasksByETLRunQueryOptions({
      ETLRunId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterWithErrors,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// ETLTask is pre-fetched when a user hover a 'view ETLTask' link
export const ETLTaskViewLoader = ({ params, context: { queryClient } }) => {
  const { ETLTaskId } = params;
  queryClient.ensureQueryData(getETLTaskQueryOptions(ETLTaskId, queryClient));
};
