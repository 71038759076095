import {
  getPostQueryOptions,
  getPaginatedPostsByAccountQueryOptions,
  getPaginatedPostsByBrandQueryOptions,
  getPaginatedPostsByProblemTypeQueryOptions,
} from "../../queries/posts";

// posts are pre-fetched when a user hover a 'posts' link within a account
export const postListByAccountLoader = ({
  params,
  context: { queryClient },
}) => {
  const { accountId } = params;

   // REFACTOR
  // These temporary settings are redux slices initial values
  const filterText = "";
  const filterFormatPost = null;
  const filterDeleted = false;
  const filterStartDate = null;
  const filterEndDate = null;
  const sortBy = "date-desc";
  const page = 1;
  const nbDocsPerPage = 25;

  queryClient.ensureQueryData(
    getPaginatedPostsByAccountQueryOptions({
      accountId,
      filterFormatPost,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// posts are pre-fetched when a user hover a 'posts' link within a brand
export const postListByBrandLoader = ({ params, context: { queryClient } }) => {
  const { brandId } = params;

  // REFACTOR
  // These temporary settings are redux slices initial values
  const filterText = "";
  const filterFormatPost = null;
  const filterDeleted = false;
  const filterStartDate = null;
  const filterEndDate = null;
  const sortBy = "date-desc";
  const page = 1;
  const nbDocsPerPage = 25;

  queryClient.ensureQueryData(
    getPaginatedPostsByBrandQueryOptions({
      brandId,
      filterFormatPost,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// posts are pre-fetched when a user hover a 'posts' link within a problemType
export const postListByProblemTypeLoader = ({
  params,
  context: { queryClient },
}) => {
  const { problemTypeId } = params;

  // REFACTOR
  // These temporary settings are redux slices initial values
  const filterText = "";
  const filterFormatPost = null;
  const filterDeleted = false;
  const filterStartDate = null;
  const filterEndDate = null;
  const sortBy = "date-desc";
  const page = 1;
  const nbDocsPerPage = 25;

  queryClient.ensureQueryData(
    getPaginatedPostsByProblemTypeQueryOptions({
      problemTypeId,
      filterFormatPost,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// post is pre-fetched when a user hover a 'view post' link
export const postViewLoader = ({ params, context: { queryClient } }) => {
  const { postId } = params;
  queryClient.ensureQueryData(getPostQueryOptions(postId, queryClient));
};
