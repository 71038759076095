import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { offerRouteFactory } from "./offer";
import { adOpsObjectiveViewLoader } from "../loaders/adOpsObjective";
import { offerListByAdOpsObjectiveLoader } from "../loaders/offer";
import { AdOpsObjectiveBreadcrumb } from "../../breadcrumbs/adOpsObjective";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { ADOPSOBJECTIVE, OFFER } = ENTITY;

export const adOpsObjectiveRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const adOpsObjectiveRoute = createRoute({
    getParentRoute: () => parent,
    path: ADOPSOBJECTIVE.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ADOPSOBJECTIVE.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ADOPSOBJECTIVE.label.upFirstPlural,
    },
  });

  // List adOpsObjectives

  const adOpsObjectiveListRoute = createRoute({
    getParentRoute: () => adOpsObjectiveRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={ADOPSOBJECTIVE} />,
  }).lazy(() =>
    import("./adOpsObjective.lazy").then((d) => d.AdOpsObjectiveListRoute)
  );

  // Create adOpsObjective

  const adOpsObjectiveCreateRoute = createRoute({
    getParentRoute: () => adOpsObjectiveRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ADOPSOBJECTIVE.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    pendingComponent: () => <EntityCreateLoading entity={ADOPSOBJECTIVE} />,
  }).lazy(() =>
    import("./adOpsObjective.lazy").then((d) => d.AdOpsObjectiveCreateRoute)
  );

  // View adOpsObjective

  const adOpsObjectiveViewRootRoute = createRoute({
    getParentRoute: () => adOpsObjectiveRoute,
    path: "$adOpsObjectiveId",
    staticData: {
      breadcrumb: AdOpsObjectiveBreadcrumb,
    },
    loader: adOpsObjectiveViewLoader,
  });

  // Edit adOpsObjective

  const adOpsObjectiveEditRoute = createRoute({
    getParentRoute: () => adOpsObjectiveViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    pendingComponent: () => <EntityEditLoading entity={ADOPSOBJECTIVE} />,
  }).lazy(() =>
    import("./adOpsObjective.lazy").then((d) => d.AdOpsObjectiveEditRoute)
  );

  // View adOpsObjective Layout

  const adOpsObjectiveViewLayoutRoute = createRoute({
    getParentRoute: () => adOpsObjectiveViewRootRoute,
    id: "adOpsObjective-view-layout",
    pendingComponent: () => <EntityViewLoading entity={ADOPSOBJECTIVE} />,
  }).lazy(() =>
    import("./adOpsObjective.lazy").then((d) => d.AdOpsObjectiveViewLayoutRoute)
  );

  // View adOpsObjective - General Tab

  const adOpsObjectiveViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => adOpsObjectiveViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adOpsObjective.lazy").then(
      (d) => d.AdOpsObjectiveViewLayoutGeneralTabRoute
    )
  );

  // View adOpsObjective - About Tab

  const adOpsObjectiveViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => adOpsObjectiveViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adOpsObjective.lazy").then(
      (d) => d.AdOpsObjectiveViewLayoutAboutTabRoute
    )
  );

  // View adOpsObjective - Offers Tab

  const adOpsObjectiveViewLayoutOfferListTabRoute = createRoute({
    getParentRoute: () => adOpsObjectiveViewLayoutRoute,
    path: OFFER.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: OFFER.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: OFFER.label.upFirstPlural,
    },
    loader: offerListByAdOpsObjectiveLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adOpsObjective.lazy").then(
      (d) => d.AdOpsObjectiveViewLayoutOfferListTabRoute
    )
  );

  // AdOpsObjective Children
  const offerRoute = offerRouteFactory(adOpsObjectiveViewRootRoute);

  // Route tree
  adOpsObjectiveRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [adOpsObjectiveListRoute]),
    adOpsObjectiveCreateRoute,
    adOpsObjectiveViewRootRoute.addChildren([
      adOpsObjectiveEditRoute,
      adOpsObjectiveViewLayoutRoute.addChildren([
        adOpsObjectiveViewLayoutGeneralTabRoute,
        adOpsObjectiveViewLayoutAboutTabRoute,
        adOpsObjectiveViewLayoutOfferListTabRoute,
      ]),
      offerRoute,
    ]),
  ]);

  return adOpsObjectiveRoute;
};

const adOpsObjectiveRoute = adOpsObjectiveRouteFactory();

export default adOpsObjectiveRoute;
