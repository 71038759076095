import * as api from "../apis/postBoostCampaignsApi";
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getPaginatedPostBoostCampaignsQueryOptions = ({
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "postBoostCampaigns",
      filterText,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedPostBoostCampaigns({
        filterText,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getPaginatedPostBoostCampaignsByPostQueryOptions = ({
  postId,
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "post.postBoostCampaigns",
      postId,
      filterText,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedPostBoostCampaignsByPost({
        postId,
        filterText,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getPostBoostCampaignQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["postBoostCampaign", id],
    queryFn: () => api.getPostBoostCampaign(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // we try to find the postBoostCampaign in one of the "postBoostCampaigns" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["postBoostCampaigns"] })
        .forEach((query) => {
          const data = query[1]?.data;
          if (data) {
            data.forEach((postBoostCampaign) => {
              if (postBoostCampaign.id === id) {
                found = postBoostCampaign;
                queryKey = query[0];
              }
            });
          }
        });

      // otherwise, we try to find the postBoostCampaign in one of the "post.postBoostCampaigns" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["post.postBoostCampaigns"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((postBoostCampaign) => {
                if (postBoostCampaign.id === id) {
                  found = postBoostCampaign;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      // otherwise, we try to find the postBoostCampaign in one of the "adAccount.postBoostCampaigns" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["adAccount.postBoostCampaigns"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((postBoostCampaign) => {
                if (postBoostCampaign.id === id) {
                  found = postBoostCampaign;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

const getPostBoostCampaignStatsByDayQueryOptions = (postBoostCampaignId) =>
  queryOptions({
    queryKey: ["postBoostCampaign.statsByDay", postBoostCampaignId],
    queryFn: () => api.getPostBoostCampaignStatsByDay(postBoostCampaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

// useQuery

export const useGetPaginatedPostBoostCampaigns = ({
  isInitFiltersFinished,
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery({
    ...getPaginatedPostBoostCampaignsQueryOptions({
      filterText,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    }),
    enabled: Boolean(isInitFiltersFinished),
  });

export const useGetPaginatedPostBoostCampaignsByPost = ({
  postId,
  isInitFiltersFinished,
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery({
    ...getPaginatedPostBoostCampaignsByPostQueryOptions({
      postId,
      filterText,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    }),
    enabled: Boolean(isInitFiltersFinished),
  });


export const useGetPostBoostCampaign = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getPostBoostCampaignQueryOptions(id, queryClient));
};

export const useGetPostBoostCampaignStatsByDay = (postBoostCampaignId) =>
{
  return useQuery(getPostBoostCampaignStatsByDayQueryOptions(postBoostCampaignId));
}
  

// useSuspenseQuery

export const useSuspenseGetPostBoostCampaign = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getPostBoostCampaignQueryOptions(id, queryClient));
};
