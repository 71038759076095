import fetchServer from "../helpers/api/fetchServer";

export const getPaginatedAdOpsCampaigns = async ({
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = "/api/adOpsCampaigns?";
  // Filters
  if (filterText) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `text=${filterText}`;
  }
  if (filterDeleted) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `deleted=${filterDeleted}`;
  }
  // Sort by
  if (urlAPI.slice(-1) !== "?") {
    urlAPI += "&";
  }

  urlAPI += `sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getPaginatedAdOpsCampaignsByLineItem = async ({
  lineItemId,
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = `/api/adOpsCampaigns?lineItem=${lineItemId}`;
  // Filters
  if (filterText) {
    urlAPI += `&text=${filterText}`;
  }
  if (filterDeleted) {
    urlAPI += `&deleted=${filterDeleted}`;
  }
  // Sort by
  urlAPI += `&sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getAdOpsCampaignsByCampaign = async (campaignId) => {
  const method = "GET";
  const urlAPI = `/api/adOpsCampaigns?campaign=${campaignId}`;
  return await fetchServer({ method, urlAPI });
};

export const getAdOpsCampaign = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adOpsCampaigns/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const getAdOpsCampaignStatsByDay = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adOpsCampaigns/${id}/statsByDay`;
  return await fetchServer({ method, urlAPI });
};

export const getAdOpsCampaignStatsByAdSet = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adOpsCampaigns/${id}/statsByAdSet`;
  return await fetchServer({ method, urlAPI });
};

export const getAdOpsCampaignStatsByAd = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adOpsCampaigns/${id}/statsByAd`;
  return await fetchServer({ method, urlAPI });
};

export const getAdOpsCampaignStatsByDevice = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adOpsCampaigns/${id}/statsByDevice`;
  return await fetchServer({ method, urlAPI });
};

export const getAdOpsCampaignStatsByDevicePlatform = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adOpsCampaigns/${id}/statsByDevicePlatform`;
  return await fetchServer({ method, urlAPI });
};

export const getAdOpsCampaignStatsByPlatform = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adOpsCampaigns/${id}/statsByPlatform`;
  return await fetchServer({ method, urlAPI });
};

export const getAdOpsCampaignStatsByPlacement = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adOpsCampaigns/${id}/statsByPlacement`;
  return await fetchServer({ method, urlAPI });
};

export const getAdOpsCampaignStatsByAge = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adOpsCampaigns/${id}/statsByAge`;
  return await fetchServer({ method, urlAPI });
};

export const getAdOpsCampaignStatsByGender = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adOpsCampaigns/${id}/statsByGender`;
  return await fetchServer({ method, urlAPI });
};

export const getAdOpsCampaignDataCheck = async (id) => {
  const method = "GET";
  const urlAPI = `/api/adOpsCampaigns/${id}/dataCheck`;
  return await fetchServer({ method, urlAPI });
};
