import { createContext, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { THEMES } from '../constants';

const initialSettings = {
  compact: false,
  direction: 'ltr',
  responsiveFontSizes: true,
  roundedCorners: false,
  theme: THEMES.DARK
};

export const restoreLocalStorageSettings = () => {
  let settings = null;

  try {
    const storedData = window.localStorage.getItem('themeSettings');

    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = {
        compact: false,
        direction: 'ltr',
        responsiveFontSizes: true,
        roundedCorners: false,
        theme: THEMES.DARK
        // if we want the theme to adapt according to browser dark or light mode : delete previous and de-comment next 3 lines 
        // theme: window.matchMedia('(prefers-color-scheme: dark)').matches
        //   ? THEMES.DARK
        //   : THEMES.LIGHT
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

const SettingsContext = createContext({
  settings: initialSettings,
});

export const SettingsProvider = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState(initialSettings);
  const { currentUser, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    let restoredSettings;
    // if the user is authenticated, we get the user settings from database
    if(isAuthenticated){
      restoredSettings = currentUser.themeSettings;
    }else{
      restoredSettings = restoreLocalStorageSettings();
    }

    if (restoredSettings) {
      setSettings(restoredSettings);
    }
  }, [currentUser, isAuthenticated]);

  return (
    <SettingsContext.Provider
      value={{
        settings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
