import {
  getAdAccountQueryOptions,
  getAdAccountsByAdPlatformQueryOptions,
} from "../../queries/adAccounts";
import { getAdvertisersQueryOptions } from "../../queries/advertisers";
import { getAccountsQueryOptions } from "../../queries/accounts";

// necessary entities are pre-fetched when a user hover a 'edit adAccount' link
export const adAccountFormLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getAdvertisersQueryOptions());
  queryClient.ensureQueryData(getAccountsQueryOptions());
};

// adAccounts are pre-fetched when a user hover a 'adAccounts' link within a adPlatform
export const adAccountListByAdPlatformLoader = ({
  params,
  context: { queryClient },
}) => {
  const { adPlatformId } = params;
  queryClient.ensureQueryData(
    getAdAccountsByAdPlatformQueryOptions(adPlatformId, queryClient)
  );
};

// adAccount is pre-fetched when a user hover a 'view adAccount' link
export const adAccountViewLoader = ({ params, context: { queryClient } }) => {
  const { adAccountId } = params;
  queryClient.ensureQueryData(
    getAdAccountQueryOptions(adAccountId, queryClient)
  );
};
